import { useState, useEffect } from "react"

function getSorted(list, field, reverse) {
  let sortField = field
  if (!sortField) sortField = Object.keys(list)[0]
  
  return [...list].sort((a, b) => {
    if (sortField === "" || sortField === undefined) return 0
    // If a is strictly false or null, and b is not, sort b as first.
    if ([false, null].includes(a[sortField]) && ![false, null].includes(b[sortField])) return reverse ? -1 : 1
    // If b is strictly false or null, and a is not, sort a as first.
    if ([false, null].includes(b[sortField]) && ![false, null].includes(a[sortField])) return reverse ? -1 : 1
    if (a[sortField] === null || a[sortField] === undefined) return reverse ? -1 : 1
    if (b[sortField] === null || b[sortField] === undefined) return reverse ? -1 : 1
    if (a[sortField] === null && b[sortField] === null) return 0
    return (
      a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
        numeric: true,
      }) * (reverse ? -1 : 1)
    )
  })
}

export const useSortList = list => {
  const [sortedList, setSortedList] = useState([]);

  const onSort = (list, field, reverse) => {
    const sorted = getSorted(list, field, reverse)
    setSortedList(sorted)
  };

  useEffect(() => {
    setSortedList(getSorted(list))
  }, [list])

  return [sortedList, onSort]
}