import React from "react";
import {
  Box,
  Stack,
  Typography
} from "@mui/material";
import {
  LinkedIn,
  Twitter,
  Facebook
} from "@mui/icons-material";
import StandardPage from "../components/StandardPage";

function Contact() {

  return (
    <StandardPage title="Contact Us" maxWidth="md">
      <Box mb={4}>
        <Typography variant="h3" mb={1}>
          About Carfeine
        </Typography>
        <Typography>
        Carfeine helps dealerships and OEMs across the US & Canada with their digital marketing. Find out more at <a href="https://www.carfeine.com">carfeine.com</a>.
        </Typography>
      </Box>
      <Box mb={2}>
        <Typography variant="h3" mb={2}>
          How You Can Reach Us
        </Typography>
        <Stack spacing={2}>
          <Typography>
            <b>Email:</b> <a href="mailto&#58;&#37;7&#51;u%70po%7&#50;t&#64;&#37;63&#97;r%&#54;6ein%6&#53;&#46;&#99;o&#109;">Contact our support team</a>
          </Typography>
          <Typography>
            <b>Phone:</b> (301) 560-2000
          </Typography>
          <Typography>
            <b>Address:</b> 11140 Rockville Pike, Suite 590-D, Rockville, MD 20852
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center" mt={1}>
          <Typography>
            <b>Social Media: </b>
          </Typography>
          <a href="https://www.linkedin.com/company/carfeine" style={{ display: "flex", color: "#0077B5" }}>
            <LinkedIn />
          </a>
          <a href="https://twitter.com/carfeine" style={{ display: "flex", color: "#1DA1F2" }}>
            <Twitter />
          </a>
          <a href="https://www.facebook.com/carfeine" style={{ display: "flex", color: "#4267B2" }}>
            <Facebook />
          </a>
        </Stack>
      </Box>
    </StandardPage>
  );
}

export default Contact;
