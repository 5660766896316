import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Avatar,
  Button,
  Typography
} from "@mui/material";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../theme"

function CustomCard(props) {
  const {
    initials,
    title,
    titleDescription,
    description,
    appLink,
    externalLink,
    permissions
  } = props
  const isMed = useMediaQuery(theme.breakpoints.between("md", "lg"));

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexBasis="33.3%"
      flexGrow={1}
      bgcolor={theme.palette.background.default}
    >
      <Box p={3}>
        <Box
          mb={2}
          display="flex"
          flexDirection={isMed ? "column" : "row"}
          alignItems="center"
        >
          <Avatar
            sx={{
              bgcolor: "#005BEA",
              height: "57px",
              width: "57px",
              marginRight: "16px",
              marginBottom: isMed ? "16px" : 0
            }}
          >
            {initials}
          </Avatar>
          <div style={{ textAlign: isMed ? "center" : "left" }}>
            <Typography variant="subtitle2" sx={{ marginBottom: "4px"}}>
              {titleDescription}
            </Typography>
            <Typography variant={isMed ? "h4" : "h3"}>
              {title}
            </Typography>
          </div>
        </Box>
        <Typography>
          {description}
        </Typography>
      </Box>
      <Box
        display="flex"
        px={isMed ? 1 : 3}
        py={2}
        mt="auto"
        flexDirection={isMed ? "column" : "row"}
        bgcolor={theme.palette.background.highlight}
      >
        {appLink && permissions?.length > 0 && (
          <Button
            component={ Link }
            to={ appLink }
            endIcon={<ArrowCircleRightOutlinedIcon />}
            style={{
              textTransform: "none",
              color: theme.palette.text.bright
            }}
          >
            {title}
          </Button>
        )}
        {externalLink && (
          <Button
            href={ externalLink }
            target="_blank"
            endIcon={<OpenInNewOutlinedIcon />}
            style={{
              textTransform: "none",
              color: theme.palette.text.bright,
              marginLeft: isMed ? 0 : "auto"
            }}
          >
            Learn More
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default CustomCard;
