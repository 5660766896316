import React from "react";
import { useState } from "react"
import {
  useNewPasswordMutation,
} from "../../services/apiSlice"
import { useSearchParams } from "react-router-dom";
import {
  Button,
  TextField,
  Stack,
  Alert,
  Box,
  Typography
} from "@mui/material"
import { validate } from "../../validate";
import { isObjectWithValues, parseErrors } from "../../utils";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get("token")
  const forgotten = searchParams.get("forgotten")
  const membership_id = searchParams.get("membership_id")
  const uid = searchParams.get("uid")

  const [postNewPassword, {
    isFetching,
    isError,
    error
  }] = useNewPasswordMutation()

  const [password, setPassword] = useState("")
  const [errors, setErrors] = useState({})

  const submitNewPassword = async () => {
    const errs = validate([
      ["password", "password", password]
    ])
    setErrors({...errs})
    if (!isObjectWithValues(errs) && !isFetching) {
      try {
        await postNewPassword({
          password,
          forgotten,
          token,
          uid
        }).unwrap()
        navigate(`/auth/login${membership_id ? `?membership_id=${membership_id}` : ""}`)
      } catch(err) {
        console.log(err)
      }
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    submitNewPassword()
  }

  return (
    <>
      <Typography variant="h4" component="h2" textAlign="center" pb={2}>
        Reset Password
      </Typography>
      <Typography variant="subtitle1" component="h3" textAlign="center" pb={5}>
        Enter a new password
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            label="New Password"
            variant="outlined"
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
          />
          <Box pt={4}>
            <Button
              color='primary' 
              type='submit'
              variant='contained'
              fullWidth={true}
              disabled={isFetching}
            >
              {isFetching ? "Saving..." : "Save new password"}
            </Button>
          </Box>
          {isError && (
            <Alert severity='error'>
              {`${error.status}: ${parseErrors(error)}`}
            </Alert>
          )}
        </Stack>
      </form>
    </>
  )
}

export default ResetPassword