import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material"
import { EmojiTransportation } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ImageUploader from "../../components/ImageUploader"
import { timezones } from "../../const";
import theme from "../../theme"
import { validate } from "../../validate";
import { isObjectWithValues, addURLProtocol } from "../../utils"
  
function EditOrganization(props) {
  const {
    user_id,
    organization_id,
    orgData = {},
    postOrgData = {},
    postOrgError = {},
    parentOrg_id,
    thisRooftop,
    postOrg,
    deleteOrg,
    hasChildren,
    onClose,
    pageIsFetching,
    isSaving
  } = props

  const orgVals = Object.values(orgData)
  const thisOrg = orgVals.find(o => o.organization_id === organization_id)
    ?? (isObjectWithValues(postOrgData) ? postOrgData : undefined)
    ?? postOrgError?.partialSuccess
    ?? {}
  const isOwner = thisOrg.owner_id === user_id

  const isXSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [name, setName] = useState(thisOrg.name ?? "")
  const [description, setDescription] = useState(thisOrg.description ?? "")
  const [website, setWebsite] = useState(thisOrg.website ?? "")
  const [createRooftop, setCreateRooftop] = useState(false)
  const [inventory_website, setInventoryWebsite] = useState(thisRooftop?.inventory_website ?? "")
  const [timezone_id, setTimezone_id] = useState(thisOrg.timezone_id ?? 85)
  const [logoURL, setLogoURL] = useState(thisOrg.logo ?? "")
  const [logoFile, setLogoFile] = useState()
  const [logoDeleted, setLogoDeleted] = useState()
  const [allow_child_payment, setAllow_child_payment] = useState(!!thisOrg.allow_child_payment)
  const [showConfirmDelete, setShowConfirmDelete] = useState()
  const [errors, setErrors] = useState({})

  const onClickDelete = async () => {
    deleteOrg({organization_id: thisOrg.organization_id})
  }

  const handleSubmit = e => {
    e.preventDefault()
    //Add a protocol to the website if they skipped it. -JJ
    const websiteWithProtocol = addURLProtocol(website)
    setWebsite(websiteWithProtocol)

    const inventoryWebsiteWithProtocol = addURLProtocol(inventory_website)
    setInventoryWebsite(inventoryWebsiteWithProtocol)

    const errs = validate([
      ["name", "name", name],
      // only validate website if it has a value
      // we use websiteWithProtocol because website's state probably hasn't updated yet
      ...(website ? [["website", "url", websiteWithProtocol]] : []),
      // only validate the inventory website if this is has a rooftop
      ...(createRooftop || thisRooftop ? [["inventory_website", "url", inventoryWebsiteWithProtocol]] : [])
    ])
    setErrors({...errs})

    if (!isObjectWithValues(errs)) {
      const orgFormData = new FormData()
      if (thisOrg.organization_id) {
        orgFormData.set("organization_id", thisOrg.organization_id)
      } else {
      // TODO: eventually allow user to set all types of orgs
        orgFormData.set("org_type", "dealership")
      }
      orgFormData.set("timezone_id", timezone_id)
      orgFormData.set("allow_child_payment", allow_child_payment)

      const orgProfileFormData = new FormData()
      if (thisOrg.organization_profile_id) {
        orgProfileFormData.set("organization_profile_id", thisOrg.organization_profile_id)
      }
      orgProfileFormData.set("name", name)
      orgProfileFormData.set("description", description)
      orgProfileFormData.set("website", websiteWithProtocol ?? "")
      
      if (logoFile) {
        orgProfileFormData.set("logo", logoFile, logoFile.name)
      } else if (logoDeleted) {
        orgProfileFormData.set("logo", "")
      }

      const rooftopFormData = new FormData()
      if (createRooftop || thisRooftop) {
        rooftopFormData.set("name", name)
        rooftopFormData.set("description", description)
        rooftopFormData.set("inventory_website", inventoryWebsiteWithProtocol)
        if (thisRooftop) {
          rooftopFormData.set("isPatch", true)
          rooftopFormData.set("rooftop_id", thisRooftop.rooftop_id)
        }
      }

      postOrg({
        org: orgFormData,
        orgProfile: orgProfileFormData,
        rooftop: (createRooftop || thisRooftop) && rooftopFormData,
        parent_id: parentOrg_id
      })
    }
  } 

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Box
        display="flex"
        flexDirection={isXSmall ? "column" : "row"}
        mt={1} 
        mb={2}
      >
        <Box
          mr={isXSmall? 0 : 3}
          display="flex"
          justifyContent={isXSmall ? "center" : "flex-start"}
        >
          <ImageUploader
            imageURL={logoURL}
            setImageURL={setLogoURL}
            setImageFile={setLogoFile}
            clearImageFile={() => setLogoDeleted(true)}
            altTag={`Logo for ${name}`}
            fallbackIcon={<span style={{ fontSize: "140px" }}><EmojiTransportation fontSize="inherit" /></span>}
          />
        </Box>
        <Stack spacing={5} width="100%">
          <TextField
            width="100%"
            label="Name*"
            value={name}
            onChange={e => setName(e.target.value)}
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            width="100%"
            label="Description"
            value={description}
            multiline
            rows={4}
            onChange={e => setDescription(e.target.value)}
          />
        </Stack>
      </Box>
      <Stack spacing={5}>
        <Box display="flex" flexDirection="column">
          <TextField
            width="100%"
            label="Organization Website"
            value={website}
            onChange={e => setWebsite(e.target.value)}
            error={!!errors.website}
            helperText={errors.website}
          />
        </Box>
        <Box display="flex" flexDirection="column">
          {thisRooftop ? (
            <Box mb={2}>
              <Typography variant="note" component="p">
                Rooftop ID: <i>{thisRooftop.rooftop_id}</i>
              </Typography>
            </Box>
          ) : (
            <Box mt={-2}>
              <FormControl
                component="fieldset"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={createRooftop}
                        onChange={() => setCreateRooftop(!createRooftop)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="This is a dealership, use the website below to confirm it&apos;s inventory when using reVIN."
                  />
                </FormGroup>
              </FormControl>
            </Box>
          )}
          <TextField
            width="100%"
            label="Inventory Website"
            value={inventory_website}
            onChange={e => setInventoryWebsite(e.target.value)}
            error={!!errors.inventory_website}
            helperText={errors.inventory_website}
            disabled={!createRooftop && !thisRooftop}
          />
        </Box>
        <FormControl>
          <InputLabel id="TimezoneSelectLabel">Timezone</InputLabel>
          <Select
            labelId="TimezoneSelectLabel"
            id="TimezoneSelect"
            value={timezone_id}
            label="Timezone"
            onChange={e => setTimezone_id(e.target.value)}
          >
            {timezones.map(t => (
              <MenuItem value={t.id} key={t.id}>{t.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box display="flex" flexDirection="column">
          <Typography variant="note">
            Sub-organizations
          </Typography>
          <FormControl
            component="fieldset"
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={allow_child_payment}
                    onChange={() => setAllow_child_payment(!allow_child_payment)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Subordinate organizations can create and pay for their own subscriptions."
              />
            </FormGroup>
          </FormControl>
        </Box>
        {isOwner && thisOrg.organization_id && (
          <div>
            <Box mb={1}>
              <Button
                variant="outlined"
                disabled={showConfirmDelete || hasChildren}
                onClick={() => setShowConfirmDelete(true)}
              >
                {`Delete ${name}`}
              </Button>
            </Box>
            {showConfirmDelete && (
              <Box mb={1} display="flex">
                <Button
                  variant="outlined"
                  onClick={() => setShowConfirmDelete(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  sx={{ ml: "8px" }}
                  onClick={onClickDelete}
                >
                  Delete
                </Button>
              </Box>
            )}
            <Box>
              {hasChildren ? (
                <Typography variant="note">
                  This organization has children, it can&apos;t be deleted unless they are deleted first.
                </Typography>
              ) : (
                <Typography variant="note" color={theme.palette.warning.main}>
                  Caution! This will remove all memberships and information about this organization, and cannot be undone.
                </Typography>
              )}
            </Box>
          </div>
        )}
        <Box display="flex" justifyContent="flex-end">
          <Button
            variant="outlined"
            onClick={onClose}
          >
              Cancel
          </Button>
          <Button
            color='primary' 
            type='submit'
            variant='contained'
            disabled={pageIsFetching}
            sx={{ marginLeft: "8px" }}
          >
            {isSaving ? "Saving..." : "Save"}
          </Button>
        </Box>
      </Stack>
    </form>
  );
}

export default EditOrganization;
  