import { createSlice } from "@reduxjs/toolkit"
import { apiSlice } from "../services/apiSlice"

const initialState = {
  token: ""
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => initialState
  },
  extraReducers: builder => {
    builder.addMatcher(
      apiSlice.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.token = payload.token
      }
    )
  } 
})

export const { logout } = authSlice.actions

export default authSlice.reducer