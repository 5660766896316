import React from "react"
import Logo from "../assets/Carfeine_logo_mark_color.svg"

function SpinnerContained() {
  return (
    <div style={{
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <img
        style={{
          animation: "pulse 2s linear infinite",
          width: "10%",
          minWidth: "150px"
        }}
        src={Logo}
        alt="The Carfeine Loading Indicator"
      />
    </div>
  )}

export default SpinnerContained