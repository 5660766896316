import React from "react"
import {
  Typography
} from "@mui/material"
import { parseErrors } from "../utils"

function ErrorsContained(props) {
  let { errors = [] } = props
  // errors can be passed in as a single string or error object
  if (!Array.isArray(errors)) errors = [errors]

  errors = errors.map(e => {
    const err = parseErrors(e)
    if (Array.isArray(err)) return err
    return [err]
  })
    .flat()
    .filter(e => e) // RTK Queries might pass in undefined as errors when there are no errors = JJ


  return (
    <div style={{
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      pointerEvents: "none" // Don't block clicks, for example the close X button.
    }}>
      <div style={{ textAlign: "center" }}>
        <Typography color="error" fontWeight={700}>
          We&apos;ve run into a problem. It&apos;s been logged and we&apos;re looking into it.
        </Typography>
        {errors.map((e, i) => (
          /* using an index in the key here is not ideal, but error messages might be identical - JJ */
          <Typography key={`${e}-${i}`} color="error" fontWeight={500}>
            {e}
          </Typography>
        ))}
      </div>
    </div>
  )}

export default ErrorsContained