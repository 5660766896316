import React, {useState, useEffect} from "react";
import { useForm } from "react-hook-form";
import {
  Alert,
  Box,
  Typography,
  Grid,
  Collapse,
  Button,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom"
import {
  useCreateREVINMutation,
  apiSlice
} from "../../services/apiSlice";
import { validate } from "../../validate";
import {
  isObjectWithValues,
  parseErrors,
  toQueryString
} from "../../utils"
import ReactHookSelect from "../../components/ReactHookSelect"
import SpinnerContained from "../../components/SpinnerContained";

function AddVIN(props) {
  const { rooftop_id, rooftop_status } = props
  const navigate = useNavigate()

  const [createREVIN, {
    // Mutations don't have isFetching, only isLoading
    isLoading: isCreateLoading,
    isError: isCreateError,
    error: createError
  }] = useCreateREVINMutation()

  const {
    handleSubmit,
    control,
    register,
    getValues
  } = useForm({
    defaultvalues: {
      vin: "",
      year: null,
      make: null,
      model: null,
      trim: null,
      body_style: null,
      msrp: null,
      mileage: null,
      mileage_unit: null,
      state_of_vehicle: null,
      interior_color: null,
      exterior_color: null,
      doors: null,
      drivetrain: null,
      cylinders: null,
      displacement: null,
      engine: null,
      transmission_type: null,
      fuel_type: null,
      fuel_economy_city: null,
      fuel_economy_highway: null,
      sale_price: null
    }
  })

  const [errors, setErrors] = useState({})
  const [showMore, setShowMore] = useState(false)
  const [pollingInt, setPollingInt] = useState(3000)
  const [awaitingReVIN, setAwaitingReVIN] = useState(false)
  const [submitError, setSubmitError] = useState()

  const [triggerDetails, detailsResults] = apiSlice.endpoints.getReVINDetails.useLazyQuery({
    pollingInterval: pollingInt
  })

  useEffect(() => {
    if(detailsResults.isSuccess){
      const manualData = getValues()
      const params = {
        sale_price: manualData.sale_price,
        state_of_vehicle: manualData.state_of_vehicle,
        r: detailsResults.originalArgs.rooftop_id
      }
      setAwaitingReVIN(false)
      setPollingInt(0)
      navigate(`/revin/${detailsResults.originalArgs.vin}?${toQueryString(params)}`)
    }
  }, [detailsResults, navigate, getValues])

  const onSubmit = async (event) => {
    let data = getValues()
    data = Object.fromEntries(Object.entries(data)
      .map(([key, value]) => [key, value || null]))
    
    data.vin = data.vin?.toUpperCase()

    const errs = validate([
      ["vin", "vin", data.vin]
    ])
    setErrors({...errs})

    if (!isObjectWithValues(errs)) {
      setAwaitingReVIN(true)
      setSubmitError("")
      await createREVIN({rooftop_id, vehicles: [data]}).unwrap()
        // eslint-disable-next-line promise/always-return
        .then(result => {
          const errors = (result?.failed_vins ?? []).map(f => f.error).join(", ")
          if (!errors) {
            const vin = data.vin
            triggerDetails({
              rooftop_id,
              vin
            })
            return result
          } else {
            // We do this because the error in the catch isn't returned to anything, and won't be in createError. -JJ
            setSubmitError(errors)
            throw new Error(`This VIN could not be added. Error: ${errors}`)
          }
        })
        .catch(err => {
          setAwaitingReVIN(false)
        })
    } else {
      // placeholder, need proper scroll to item for error
      document.getElementById("vin").scrollIntoView({ behavior: "smooth"})
    }
  }

  const moreDetailsToggle = () => {
    setShowMore(!showMore)
  }

  return (
    <>
      {isCreateLoading || awaitingReVIN ? (
        <SpinnerContained />
      ) : (
        <>
          <Box display="flex" flex-grow={1} pb={1} mb={2} borderBottom={1} borderBottomColor="grey.300">
            <Typography variant="h4">
              VIN Entry Details
            </Typography>
            {/*
              <Button variant="text" size="small" sx={{ ml:"auto" }}>
                Bulk Upload
              </Button>
            */}
          </Box>
          {rooftop_status !== "active" ? (
            <Box>
              <Typography variant="h5">
                This subscription currently has a status of {rooftop_status}, new VINs can&apos;t be added until it returns to an &quot;active&quot; status.
              </Typography>
            </Box>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container rowSpacing={1} columnSpacing={{ sm:"24px"}} mb={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...register("vin")}
                    required
                    fullWidth
                    label="VIN"
                    id="vin"
                    error={!!errors.vin}
                    helperText={errors.vin}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    {...register("sale_price")}
                    fullWidth
                    label="Sale Price"
                    id="sale_price"
                    type="number"
                  />
                </Grid>
              </Grid>
              <Grid container rowSpacing={1} columnSpacing={{ sm:"24px"}}>
                <Grid item xs={12} sm={6}>
                  <ReactHookSelect
                    id="state_of_vehicle"
                    name="state_of_vehicle"
                    label="State of Vehicle"
                    control={control}
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="new">New</MenuItem>
                    <MenuItem value="used">Used</MenuItem>
                    <MenuItem value="cpo">CPO</MenuItem>
                  </ReactHookSelect>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack direction="row" spacing={1}>
                    <TextField
                      {...register("mileage")}
                      fullWidth
                      label="Mileage"
                      id="mileage"
                      type="number"
                    />
                    <ReactHookSelect
                      id="mileage_unit"
                      name="mileage_unit"
                      label="Unit"
                      control={control}
                      defaultValue="Mi"
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem value="Mi">mi</MenuItem>
                      <MenuItem value="Km">km</MenuItem>
                    </ReactHookSelect>
                  </Stack>
                </Grid>
              </Grid>

              {/* Not used from previous UI: Title, Description, Grouped Exterior Color, Grouped Interior Color
                  Short Description, Short Title */}
              <Collapse in={showMore}>
                <Box pb={1} mb={2} mt={3} borderBottom={1} borderBottomColor="grey.300">
                  <Typography variant="h5">
                    Core Details
                  </Typography>
                </Box>
                <Grid container rowSpacing={2} columnSpacing={{ sm:"24px"}}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...register("year")}
                      fullWidth
                      label="Year"
                      id="year"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...register("make")}
                      fullWidth
                      label="Make"
                      id="make"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...register("model")}
                      fullWidth
                      label="Model"
                      id="model"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...register("trim")}
                      fullWidth
                      label="Trim"
                      id="trim"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...register("body_style")}
                      fullWidth
                      label="Body Style"
                      id="body_style"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...register("msrp")}
                      fullWidth
                      label="MSRP"
                      id="msrp"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...register("interior_color")}
                      fullWidth
                      label="Interior Color"
                      id="interior_color"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...register("exterior_color")}
                      fullWidth
                      label="Exterior Color"
                      id="exterior_color"
                    />
                  </Grid>
                </Grid>

                <Box pb={1} mb={2} mt={3} borderBottom={1} borderBottomColor="grey.300">
                  <Typography variant="h5">
                    Additional Details
                  </Typography>
                </Box>
                <Grid container rowSpacing={2} columnSpacing={{ sm:"24px"}}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...register("doors")}
                      fullWidth
                      label="Doors"
                      id="doors"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...register("drivetrain")}
                      fullWidth
                      label="Drivetrain"
                      id="drivetrain"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...register("cylinders")}
                      fullWidth
                      label="Cylinders"
                      id="cylinders"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...register("displacement")}
                      fullWidth
                      label="Displacement"
                      id="displacement"
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...register("engine")}
                      fullWidth
                      label="Engine"
                      id="engine"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...register("transmission_type")}
                      fullWidth
                      label="Transmission Type"
                      id="transmission_type"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      {...register("fuel_type")}
                      fullWidth
                      label="Fuel Type"
                      id="fuel_type"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        {...register("fuel_economy_city")}
                        fullWidth
                        label="Fuel Economy City"
                        id="fuel_economy_city"
                      />
                      <TextField
                        {...register("fuel_economy_highway")}
                        fullWidth
                        label="Fuel Economy Highway"
                        id="fuel_economy_highway"
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Collapse>
              <Box mt={2} mb={3}>
                <Typography variant="note">
                  *VIN is the only required field, but we strongly recommend filling in sale price, state of vehicle, and mileage, those fields will not be filled by reVIN.
                </Typography>
              </Box>
              {isCreateError && (
                <Alert severity="warning">
                  {parseErrors(createError)}
                </Alert>
              )}
              {submitError && (
                <Alert severity="warning">
                  {parseErrors(submitError)}
                </Alert>
              )}

              <Box display="flex" flex-grow={1} pt={2} mt={2} borderTop={1} borderTopColor="grey.300">
                <Button variant="text" size="small" onClick={moreDetailsToggle}>
                  {showMore ? "Show less details" : "Show more details"}
                </Button>
                <Button
                  disableElevation
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={isCreateLoading}
                  sx={{ ml: "auto" }}
                >
                  {isCreateLoading ? "Submitting..." : "Submit"}
                </Button>
              </Box>
            </form>
          )}
        </>
      )}
    </>
  )
}

export default AddVIN
