import React from "react"
import {
  Avatar,
  Box,
  Grid,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import moment from "moment"
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../theme";

function Member(props) {
  const {
    title,
    image,
    TitleBadge = null,
    descriptionTitle,
    descriptionRole,
    acceptedString,
    descriptionComponent: DescriptionComponent = null,
    created_on,
    updated_on,
    mainOnClick = null,
    onEdit,
    onRemove
  } = props

  const timestamp = updated_on ?? created_on

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        pt={2}
        pb={2}
        style={{ borderTop: `1px solid ${props.index === 0 ? theme.palette.tertiary.main : theme.palette.background.light}` }}
      >
        <Grid item xs="auto">
          <Avatar
            sx={{
              width: "48px",
              height: "48px",
              marginRight: "16px",
              bgcolor: theme.palette.tertiary.main,
              borderColor: theme.palette.tertiary.main,
              borderStyle: "solid",
              borderWidth: "1px",
              ...(mainOnClick ? { cursor: "pointer" } : {})
            }}
            variant="rounded"
            src={image}
            onClick={mainOnClick}
            {...(mainOnClick ? { tabIndex: 0 } : {})}
          />
        </Grid>
        <Grid
          item
          xs
          onClick={mainOnClick}
          {...(mainOnClick ? { tabIndex: 0 } : {})}
          sx={{ ...(mainOnClick ? { cursor: "pointer" } : {}) }}
        >
          <Box display="flex" >
            <Typography variant="h5">
              {title}
            </Typography>
            {TitleBadge && (<TitleBadge />)}
          </Box>
          {DescriptionComponent && (
            <Grid item>
              <DescriptionComponent
                title={descriptionTitle ?? ""}
                role={descriptionRole ?? ""}
                acceptedString={acceptedString}
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={3} pt={isSmall ? 2 : 0}>
          {timestamp && (
            <Typography textAlign="right">
              {moment(timestamp).format("LT L")}
            </Typography>
          )}
          <Box display="flex" justifyContent="flex-end" pt={isSmall ? 1 : 0}>
            <Stack spacing={1} direction="row">
              {onRemove && (
                <Button
                  size="small"
                  sx={{ textTransform: "none" }}
                  color="secondary"
                  onClick={onRemove}
                >
                  Remove
                </Button>
              )}
              {onEdit && (
                <Button
                  size="small"
                  sx={{ textTransform: "none" }}
                  variant="outlined"
                  onClick={onEdit}
                >
                  Edit
                </Button>
              )}
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Member