import { configureStore } from "@reduxjs/toolkit"
import { setupListeners } from "@reduxjs/toolkit/dist/query"
import { apiSlice } from "./services/apiSlice"
import auth from "./slices/authSlice"
import user from "./slices/userSlice"
import uuids from "./slices/uuidsSlice"
import { combineReducers } from "@reduxjs/toolkit"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import storage from "redux-persist/lib/storage";
import * as Sentry from "@sentry/react";

const reducers = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth,
  user,
  uuids
})

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [apiSlice.reducerPath]
}

const persistedReducer = persistReducer(persistConfig, reducers)

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    }
  }).concat(apiSlice.middleware),
  enhancers: [sentryReduxEnhancer]
})

export const persistor = persistStore(store);

setupListeners(store.dispatch)