import React from "react";
import {
  Stack,
  Typography
} from "@mui/material";
import StandardPage from "../components/StandardPage";

function TermsOfService() {

  return (
    <StandardPage title="Terms of Service">
      <Stack spacing={1} mb={4}>
        <Typography variant="h3" mb={1}>
        WEBSITE TERMS OF USE
        </Typography>
        <Typography>
          <strong>Last Updated</strong> <strong>January 5, 2023</strong>
        </Typography>
        <Typography>
          <strong>Welcome to Carfeine. Please read these Terms of Use (&quot;Terms&quot;)
            carefully, as they contain important information about your legal
            rights, remedies, and obligations. By accessing or using the Carfeine
            Website or our CARA or reVIN software applications, you agree to comply
            with and be bound by these Terms.</strong>
        </Typography>
        <Typography>Thank you for visiting the Carfeine Website located at
          https://www.carfeine.com/.</Typography>
        <Typography>These Terms constitute a legally binding agreement
          (<strong>&ldquo;Agreement&rdquo;</strong>) between you and Carfeine,
          Inc. (&quot;<strong>Carfeine</strong>&quot; or &quot;<strong>we</strong>,&quot;
          &quot;<strong>us</strong>,&quot; or &quot;<strong>our</strong>&quot;) governing your access
          to and use of the Carfeine Website, including any Web pages thereof
          (collectively, the &quot;<strong>Site</strong>&quot;), our software and smart
          device applications, and application program interfaces for CARA and/or
          reVIN (collectively, &quot;<strong>App</strong>&quot;) and all associated services
          (collectively, &quot;<strong>Carfeine Services</strong>&quot;). For purposes of
          this Agreement, the term &quot;App&quot; includes our software and any
          enhancements, updates, maintenance releases, modifications, revisions,
          or additions (collectively &quot;Updates&quot;) to the App provided by Carfeine
          and made available to you. Notwithstanding the foregoing, Carfeine shall
          be under no obligation to provide you with any Updates to the App. The
          Site, App and Carfeine Services together are hereinafter collectively
          referred to as the &quot;<strong>Carfeine Platform</strong>.&quot; Our Privacy
          Policy located at https://www.carfeine.com/privacy/ is incorporated into
          these Terms by this reference.</Typography>
        <Typography>YOU AGREE TO READ THESE TERMS CAREFULLY BEFORE EACH USE OF THE
          SITE.</Typography>
        <Typography>The Site is offered and made available only to users 18 years of age
          or older, and if you are not yet 18 years old, you must have a parent or
          guardian&apos;s permission to view the Site. Moreover, if your use of the
          Site is prohibited or restricted in any way by the laws, regulations, or
          other governmental requirements of the jurisdiction in which you live or
          reside, or if, for any reason and at any time, you do not agree with all
          of the terms and conditions contained in these Terms, please discontinue
          using the Site immediately. <strong>BY USING THE SITE: (I) YOU CERTIFY
            THAT YOU ARE AT LEAST 18 YEARS OF AGE OR HAVE A PARENT OR GUARDIAN&apos;S
            PERMISSION FOR YOUR USE OF THE SITE, AND (II) YOU UNDERSTAND,
            ACKNOWLEDGE, AND AGREE TO BE BOUND BY THESE TERMS. YOUR REMEDY FOR
            DISSATISFACTION WITH THE SITE, OR ANY SERVICES, CONTENT, OR OTHER
            INFORMATION AVAILABLE ON OR THROUGH THE SITE, IS TO STOP USING THE SITE
            AND/OR THOSE PARTICULAR SERVICES OR CONTENT. YOUR AGREEMENT WITH US
            REGARDING COMPLIANCE WITH THESE TERMS BECOMES EFFECTIVE IMMEDIATELY UPON
            COMMENCEMENT OF YOUR USE OF THE SITE.</strong></Typography>
        <Typography>When we use the term &quot;Agreement,&quot; we mean and are referring to these
          Terms, as well as any additional terms and conditions that apply to and
          govern your use of the features, functions, and services we make
          available to you from time to time through the Site. These Terms will
          remain in full force and effect as long as you are a user of the Site,
          even if your use of or participation in any particular service, feature,
          or function terminates, expires, ceases, is suspended, or deactivated
          for any reason.</Typography>
        <Typography>The words &quot;use&quot; or &quot;using&quot; in this Agreement means any time an
          individual (a &quot;user&quot;), directly or indirectly, with or without the aid
          of a machine or device, does or attempts to access, interact with, use,
          display, view, print or copy from the Site, receive data from the Site,
          or in any way utilizes, benefits, takes advantage of or interacts with
          any function, service or feature of the Site, for any purpose
          whatsoever. This Agreement does not cover your rights or
          responsibilities with respect to third-party content or any links on the
          Site that may direct your browser or your connection to third-party Web
          sites or Web pages.</Typography>
        <Typography><strong>1. GENERAL USE OF THE SITE.</strong></Typography>
        <Typography>The Site is available to users in the general public without
          registering, and you may visit and browse the Site without charge or
          obligation; <u>provided</u>, however, we reserve the right in our sole
          discretion to make changes, limit or restrict any portion of the Site at
          any time and from time to time, without notice to you. While browsing
          the Site is free, many of the Services available through the Site are
          available only by paying a fee.</Typography>
        <Typography><strong>2. MODIFICATIONS.</strong></Typography>
        <Typography>These Terms are effective as of January 5, 2023. We reserve the
          right, at any time and from time to time, for any reason in our sole
          discretion, to change the terms of this Agreement without any liability
          or obligation to you, with or without notice. We will post or display
          notices of material changes on the Site. Once we post them on the Site,
          these changes become effective immediately, and if you use the Site
          after they become effective, it will signify your agreement to be bound
          by the changes. You acknowledge and agree that it is your responsibility
          to review the Site and these Terms periodically and to be aware of any
          modifications or revisions. You should check back frequently and review
          the terms and conditions of this Agreement regularly, so you are aware
          of the most current rights and obligations that apply to you and the
          terms and conditions of your agreement with us.</Typography>
        <Typography><strong>3. OWNERSHIP AND PROPRIETARY RIGHTS.</strong></Typography>
        <Typography>Copyright  © 2023 Carfeine, Inc. All Rights Reserved.</Typography>
        <Typography>The Site, including all content, media and materials, all software,
          code, design, text, images, artwork, photographs, computer graphics,
          audio and video material, media files, as well as any accompanying
          materials, tangible or intangible, and all copyrightable or otherwise
          legally protectable elements of the Site, including, without limitation,
          the selection, sequence and &quot;look and feel&quot; and arrangement of items,
          and all derivative works, translations, adaptations or variations of
          same, regardless of the medium, broadcast medium, format or form, now
          known or hereinafter developed, alone or in conjunction with other
          works, characters, real or imaginary, in any part of the world, are the
          property of Carfeine and/or its affiliates, and their authorized
          advertisers, licensors, suppliers, service providers, promotional
          partners and/or sponsors (all of the foregoing, individually and/or
          collectively, is referred to herein as &quot;Content&quot;).</Typography>
        <Typography>All Content on the Site is legally protected, without limitation,
          under U.S. Federal and State, as well as applicable foreign laws,
          regulations, and treaties. Carfeine authorizes you to access Content and
          grants you the right to use the Site solely for your non-commercial,
          non-exclusive, non-assignable, non-transferable, and limited personal
          use and for no other purpose whatsoever. You must not alter, delete or
          conceal any copyright or other notices contained on the Site, including
          notices on any Content you display, print, or reproduce from the Site.
          Unless we explicitly and specifically notify you otherwise in writing,
          you shall not, nor will you allow any third party (whether or not for
          your benefit) to reproduce, modify, create derivative works from,
          display, perform, publish, distribute, sell, upload, transmit,
          disseminate, broadcast or circulate to any third party (including,
          without limitation, on or via a third party Web site) or otherwise use,
          any Content without the express prior written consent of Carfeine. Any
          unauthorized or prohibited use of any Content, including use in
          contravention of this Agreement, may subject you to civil liability,
          criminal prosecution, or both.</Typography>
        <Typography>You may not use any computerized or automatic mechanism, including,
          without limitation, any Web scraper, spider, or robot, to access,
          extract or download any Content from the Site unless you are expressly
          authorized to do so by Carfeine. As an express condition of your use of
          the Site, you warrant to Carfeine that you will not use the Site for any
          unlawful purpose or purpose prohibited by this Agreement. If you violate
          any part of this Agreement, your permission to use the Content
          automatically terminates, and you must immediately destroy any copies
          you have made of any of the Content. Carfeine makes no representation
          that any Content is legal or appropriate for use outside of the United
          States of America or that it is authorized for export from the United
          States of America or for import into any foreign country. You are solely
          responsible for complying with all import and export laws and
          regulations and all applicable laws of any jurisdiction inside and
          outside of the United States of America from which you may access the
          Site.</Typography>
        <Typography>We respect the intellectual property of others, and we ask you to do
          the same. Accordingly, Carfeine has adopted the following Intellectual
          Property Compliance Policy. If you or any user of the Site believes its
          copyright, trademark, or other property rights (&quot;IP Rights&quot;) have been
          infringed, the IP Rights owner (&quot;Complaining Party&quot;) should send a
          notification to our Designated Agent (as identified below) immediately.
          To be effective, the notification must include:</Typography>
        <ol type="a">
          <li><Typography>A physical or electronic signature of the Complaining Party or
              such person authorized to act on behalf of the Complaining
              Party;</Typography></li>
          <li><Typography>Identification of the IP Rights claimed to have been
              infringed;</Typography></li>
          <li><Typography>Information reasonably sufficient to permit us to contact the
              Complaining Party or such person authorized to act on behalf of the
              Complaining Party, such as address, telephone number, and, if available,
              an electronic mail address at which the Complaining Party may be
              contacted;</Typography></li>
          <li><Typography>Identification of the material that is claimed to be infringing
              or to be subject to infringing activity on the Complaining Party&apos;s IP
              Rights that is to be removed and information reasonably sufficient to
              permit us to locate such materials;</Typography></li>
          <li><Typography>A statement that the Complaining Party has a good faith belief
              that use of the material in the manner complained of is not authorized
              by the copyright owner, agent, or by law; and</Typography></li>
          <li><Typography>A statement that the information in the notification is accurate
              and, under penalty of perjury, the Complaining Party or such person
              authorized to act on behalf of the Complaining Party is the owner of an
              exclusive IP Right that is allegedly infringed.</Typography></li>
        </ol>
        <Typography>Pursuant to the Digital Millennium Copyright Act, 17 U.S.C.  § 512(c)
          (&quot;DMCA&quot;), our Designated Agent for notice of claims of IP Rights
          infringement can be reached as indicated below.</Typography>
        <Typography>Designated Agent for Claimed Infringement:</Typography>
        
        <Typography>
          <blockquote>
            <strong>DMCA Coordinator</strong><br/> <strong>Carfeine,
                Inc.</strong><br/> <strong>E-mail:</strong>
            <strong>dmca@carfeine.com</strong>
          </blockquote>
        </Typography>
        
        <Typography>On notice, we will act expeditiously to review and, if necessary
          remove content on the Site that infringes the copyright rights of others
          and will disable the access to the Site and its services of anyone who
          uses them to infringe the intellectual property rights of others
          repeatedly. Any such removal will be without liability to you or any
          other party, and that the claims of the Complaining Party will be
          referred to the United States Copyright Office for adjudication as
          provided in the DMCA. We take the protection of copyrights, both our own
          and others, very seriously.</Typography>
        <Typography>The brands, names, logos, trade names, trademarks, service marks, and
          other distinctive identifications (collectively &ldquo;Marks&rdquo;) on or of the
          Site , including, without limitation, &quot;CARFEINE,&quot; &quot;CARA,&quot; &quot;reVIN&quot; and
          our stylized logo 
        <img
          src="https://app.carfeine.com/static/media/Carfeine_logo_color.143fc0aba3ae1575d0da07cf11311e1e.svg"
          alt="Trademark"
          width="150px"
        /> are the intellectual
          property of and proprietary to Carfeine, its advertisers, suppliers and
          others with whom Carfeine do business. You have no right to use any of
          these Marks or any confusingly similar marks for any purpose without the
          express prior written consent of Carfeine.</Typography>
        <Typography><strong>4. TRADEMARK LICENSE FOR REVIN.</strong></Typography>
        <Typography>Subject to the terms of this Agreement, if you subscribe to use
          reVIN, then Carfeine hereby grants to you the non-exclusive,
          non-assignable, non-transferable, non-sublicenseable right and license
          to use the mark reVIN in connection with the registration symbol &quot; ®&quot;, in
          connection with the operation of your automotive services. The foregoing
          license permits you to use the reVIN mark on (i) your website and (ii)
          in other advertising media substantially distributed to advertise your
          services. The foregoing license does not include: (a) the right to use
          the reVIN mark in any manner except as authorized herein or (b) the
          right to register a domain name containing the reVIN mark without
          Carfeine &apos;s express, prior, written consent, which consent will be in
          Carfeine &apos;s sole and absolute discretion. Except as expressly set forth
          herein, Carfeine &apos;s does not grant any other rights in the reVIN mark to
          Licensee, and nothing herein assigns, or will be deemed to assign, any
          of Carfeine &apos;s rights in the reVIN mark to Licensee.</Typography>
        <Typography>You acknowledge Carfeine &apos;s established rights in the reVIN mark and
          the goodwill of the business associated therewith, and you agree that
          nothing in this Agreement shall give you any right, title, or interest
          in the reVIN mark other than the right to use the reVIN mark with the
          registration symbol &quot; ®&quot; in accordance with this Agreement. All goodwill
          in the reVIN mark arising from your use thereof shall inure solely to
          the benefit of Carfeine.</Typography>
        <Typography>To protect the reVIN mark, including the Registration and goodwill of
          the business associated therewith, you agree and covenant that you will
          not, during the Term of this Agreement (as defined below) or any time
          thereafter, in any jurisdiction: (i) directly or indirectly attack the
          title or any rights of Carfeine in or to the reVIN mark or Registration;
          (ii) register or apply to register (or maintain any application or
          registration for) any trademark, service mark or domain name that
          contains or is confusingly similar to the reVIN mark; (iii) knowingly
          use the reVIN mark in any manner or commit any other act likely to
          jeopardize Carfeine &apos;s rights in the reVIN mark or Registration; (iv)
          knowingly do any act that is likely to invalidate, impair or dilute the
          reVIN mark or Registration; or (v) use or permit the use of the reVIN
          mark in any manner that would tend to devalue, injure, tarnish or dilute
          the goodwill or reputation of Carfeine or its business.</Typography>
        <Typography>You certify that your business meets and shall continue to remain in
          compliance with any State or Federal licensing bureau governing your
          business and all applicable government or industry standards,
          regulations, guidelines, rules, laws, and the like covering your
          business, as well as any additional quality control standards as may be
          agreed to in writing by you and Carfeine.</Typography>
        <Typography>You are permitted to use the reVIN mark only in connection with the
          advertising and marketing of your automotive business. You shall not use
          the reVIN mark in connection with any business other than your
          automotive services and shall not use the reVIN mark in combination with
          any other trademarks, service marks or design logos inside or outside
          the Territory without the prior, written consent of Licensor, which
          consent will be in Licensor &apos;s sole and absolute discretion.</Typography>
        <Typography><strong>5. DISCLAIMER AND LIMITATIONS OF LIABILITY.</strong></Typography>
        <Typography>We try to assure the accuracy of all information displayed on the
          Site, although we cannot guarantee that all of the information is 100%
          accurate. AS SUCH, THE SITE AND ALL MATERIALS AND SERVICES THEREON ARE
          MADE AVAILABLE ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY
          REPRESENTATION OR WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, OR ANY
          GUARANTY OR ASSURANCE THE SITE WILL BE AVAILABLE FOR USE, OR THAT ALL
          PRODUCTS, FEATURES, FUNCTIONS OR OPERATIONS WILL BE AVAILABLE OR PERFORM
          AS DESCRIBED. Without limiting the foregoing, we are not responsible or
          liable for any malicious code, delays, inaccuracies, errors, or
          omissions arising out of your use of the Site . You understand,
          acknowledge and agree that you are assuming the entire risk as to the
          quality, accuracy, performance, timeliness, adequacy, completeness,
          correctness, authenticity, security and validity of any and all features
          and functions of the Site .</Typography>
        <Typography>YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PERMISSIBLE BY
          LAW, THE SITE , CARFEINE OR, ANY OF THEIR AFFILIATES, OR THEIR
          RESPECTIVE SUCCESSORS AND ASSIGNS, OR ANY OF THEIR RESPECTIVE OFFICERS,
          DIRECTORS, EMPLOYEES, AGENTS, REPRESENTATIVES, LICENSORS, OPERATIONAL
          SERVICE PROVIDERS, ADVERTISERS, OR SUPPLIERS, SHALL NOT BE LIABLE FOR
          ANY LOSS OR DAMAGE, OF ANY KIND, DIRECT OR INDIRECT, IN CONNECTION WITH
          OR ARISING FROM USE OF THE SITE OR FROM THIS AGREEMENT, INCLUDING, BUT
          NOT LIMITED TO, COMPENSATORY, CONSEQUENTIAL, INCIDENTAL, INDIRECT,
          SPECIAL OR PUNITIVE DAMAGES.</Typography>
        <Typography>NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT, OUR
          MAXIMUM AGGREGATE MONETARY LIABILITY UNDER ANY THEORY OF LAW (INCLUDING
          BREACH OF CONTRACT, TORT, STRICT LIABILITY AND INFRINGEMENT) SHALL NOT
          EXCEED $1,000.00.</Typography>
        <Typography><strong>6. DOWNLOADING APPS FROM THE SITE</strong></Typography>
        <Typography>Carfeine &apos;s Apps are copyrighted and they are licensed under the terms
          and conditions of this Agreement. You acknowledge and agree that: (a)
          the App is licensed, not sold, to you, and you do not and will not have
          or acquire any ownership interest in the App, or in any related
          Intellectual Property Rights; (b) Carfeine is, and will remain, the sole
          and exclusive owner of all right, title and interest in and to the App,
          including all Intellectual Property Rights relating thereto, subject
          only to the limited license granted to you under this Agreement; and (c)
          nothing herein transfers or assigns, or will be deemed to transfer or
          assign, any such Intellectual Property Rights in the App to you. For
          purposes of this Agreement, &quot;Intellectual Property Rights&quot; means any and
          all registered and unregistered rights granted, applied for or otherwise
          now or hereafter in existence under or related to any patent, copyright,
          trademark, trade secret, or other intellectual property rights laws in
          any jurisdiction in the world.</Typography>
        <Typography>Subject to you remaining in full and ongoing compliance with the
          terms and conditions of this Agreement, including payment of any
          applicable license and service fees, Carfeine hereby grant to you, and
          you accept, a personal, limited, nonexclusive, non-transferable,
          non-assignable, revocable license to use the specific App you downloaded
          during the Term in machine-readable, object code form only on one (1)
          device, and only as authorized in this Agreement.</Typography>
        <Typography>The license to use the App is conditioned on the following license
          restrictions, and any use of the App in violation of any of these
          restrictions, or any of the other terms of this Agreement is a breach of
          this Agreement. Unless authorized by Carfeine, <em>installation of the
            App on a network server solely for distribution to other computers is
            strictly prohibited</em>. Except as this Agreement expressly permits,
          you shall not, and shall not permit any other person to: (a) copy the
          App, in whole or in part; (b) modify, correct, adapt, translate, enhance
          or otherwise prepare derivative works or improvements of the App or any
          part thereof; (c) rent, lease, lend, sell, sublicense, assign,
          distribute, publish, transfer or otherwise make the App available to any
          person, including on or in connection with the Internet or any
          time-sharing, service bureau, software as a service, cloud or other
          technology or service; (d) reverse engineer, disassemble, decompile,
          decode or adapt the App, or otherwise attempt to derive or gain access
          to the source code of the App, in whole or in part; (e) bypass or breach
          any security device or protection used for or contained in the App; (f)
          remove, delete, efface, alter, obscure, translate, combine, supplement
          or otherwise change any trademarks, warranties, disclaimers, or
          Intellectual Property Rights (as defined below), proprietary rights or
          other symbols, notices, marks or serial numbers on or relating to any
          copy of the App; (g) use the App in any manner or for any purpose that
          infringes, misappropriates or otherwise violates any Intellectual
          Property Right or other right of any person, or that violates any
          applicable law (including any laws regarding the export of data or
          software to and from the US or other countries); (h) use the App for
          purposes of: (1) benchmarking or competitive analysis of the App; (2)
          developing, using or providing a competing software product or service;
          or (3) any other purpose that is to Our detriment or commercial
          disadvantage; or (i) use the App in any manner or for any purpose or
          application not expressly permitted by this Agreement.</Typography>
        <Typography>The App may contain technological measures designed to prevent
          unauthorized or illegal use. You acknowledge and agree that: (a)
          Carfeine may use these and other lawful measures to verify your
          compliance with the terms of this Agreement and enforce its rights,
          including all Intellectual Property Rights, in and to the App; (b)
          Carfeine may deny any person access to and/or use of the App if, in its
          sole discretion, Carfeine believes that such person&apos;s use of the App
          would violate any provision of this Agreement; and (c) Carfeine and its
          personnel may collect, maintain, process and use diagnostic, technical,
          usage and related information, including information about your
          computers, systems and software, that it may gather periodically to
          improve the performance of the App or develop Updates. Any such
          information will be treated in accordance with Carfeine &apos;s Privacy
          Policy, as amended from time to time.</Typography>
        <Typography>The term of the App will commence on the date you download the App
          (&quot;Effective Date&quot;) and will continue in effect until you terminate your
          account with Carfeine (the &quot;Term&quot;). You may terminate the App by
          uninstalling it. Carfeine may terminate your right to use the App at any
          time if you: (i) violate any provision in this Agreement or (ii) fail to
          pay any fee associated with the App, and do not cure the failure within
          ten (10) days following receipt of Carfeine &apos;s written notice of
          default.</Typography>
        <Typography>Upon the termination of this Agreement: (a) all rights, licenses and
          authorizations granted to you hereunder will immediately terminate and
          you shall: (1) immediately cease all use of and other activities with
          respect to the App; (2) within fifteen (15) days, deliver to Carfeine,
          or at its written request, destroy, and permanently uninstall the App
          from your device; and (3) pay all amounts due and payable by you to
          Carfeine of any kind no later than ten (10) days after the effective
          date of the termination of this Agreement.</Typography>
        <Typography><strong>7. INTERNATIONAL USE.</strong></Typography>
        <Typography>Although the Site may be accessible worldwide, we make no
          representation that materials thereon are lawful, appropriate or
          available for use in locations outside the United States of America, and
          accessing them from territories where its content is illegal is
          prohibited. Those who choose to access the Site from other locations do
          so on their own initiative and are responsible for compliance with all
          local laws. Any offer for any product, service, and/or information made
          in connection with the Site is void where prohibited.</Typography>
        <Typography><strong>8. MISCELLANEOUS TERMS.</strong></Typography>
        <Typography>Without limitation, this Agreement contains the entire understanding
          and agreement between you and Carfeine and supersedes any and all prior,
          inconsistent, or other understandings relating to the Site and your use
          of the Site. This Agreement cannot be modified, changed, or terminated,
          except as specifically described herein. Moreover, you shall not assign
          this Agreement or any part thereof to a third party, and any such
          assignment, or attempted assignment, will be null and void.</Typography>
        <Typography>If any provision of this Agreement is held to be illegal, invalid, or
          unenforceable, this will not affect any other provisions herein, and
          this Agreement will be deemed amended to the extent necessary to make it
          legal, valid, and enforceable. Any provision which must survive in order
          to allow us to enforce its meaning shall survive termination of this
          Agreement; provided, however, no action arising out of this Agreement or
          your use of the Site, regardless of form or the basis of the claim, may
          be brought by you more than one year after the cause of action has
          arisen (or if multiple causes, from the date the first such cause
          arose).</Typography>
        <Typography>Carfeine shall not be in default of any obligation under this
          Agreement if the failure to perform the obligation is due to any event
          beyond its control, including significant failure of a part of the power
          grid, significant failure of the Internet, failure of third party tools
          and services used to provide the App, natural disaster, war, riot,
          insurrection, pandemic, terrorist activity and events related thereto,
          strikes or other organized labor action, or other events of a similar
          magnitude or type.</Typography>
        <Typography>This Agreement and your use of the Site shall be governed by,
          construed, and enforced in accordance with the substantive laws of the
          State of Maryland applicable to contracts made, executed, and wholly
          performed in that State, and, for the purposes of any and all legal or
          equitable actions, you expressly agree and submit to the exclusive
          jurisdiction and venue of the applicable State, and Federal Courts
          situated in the State of Maryland and agree you will not object to such
          jurisdiction or venue on the grounds of lack of personal jurisdiction,
          forum non conveniens or otherwise; provided, however, that
          notwithstanding the foregoing, you agree that Carfeine has the sole
          right and discretion to commence and prosecute an action against you in
          connection with this Agreement, in your home jurisdiction. To the extent
          it may be applicable, you agree to opt-out from and expressly exclude
          any applicability of the Uniform Computer Information Transactions
          Act.</Typography>
        <Typography>IN ANY ACTION OR PROCEEDING COMMENCED TO ENFORCE ANY RIGHT OR
          OBLIGATION OF THE PARTIES UNDER THIS AGREEMENT, YOUR USE OF THE SITE, OR
          WITH RESPECT TO THE SUBJECT MATTER HEREOF, YOU HEREBY WAIVE ANY RIGHT
          YOU MAY NOW HAVE OR HEREAFTER POSSESS TO A TRIAL BY JURY. In addition,
          in disputes concerning this Agreement, Carfeine shall be entitled to the
          costs of collection, enforcement, and injunctive relief, including
          reasonable attorneys &apos; fees and court costs, and all necessary expenses,
          regardless of whether litigation is commenced.</Typography>
        <Typography>A printed version of this Agreement and of any notice given in
          electronic form shall be admissible in judicial or administrative
          proceedings based upon or relating to this Agreement to the same extent
          and subject to the same conditions as other business documents and
          records originally generated and maintained in printed form.</Typography>
      </Stack>
    </StandardPage>
  );
}

export default TermsOfService;
