import React from "react"
import {
  Box,
  Container,
  Stack,
  Typography
} from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../theme"
import {
  useGetOrganizationsQuery,
  useGetFullUserQuery,
  useGetUserMembershipQuery,
  useGetNotificationsQuery,
  useGetNewsQuery
} from "../../services/apiSlice"
import TruncatedItemList from "./TruncatedItemList";
import CardArea from "./AppCards"
import SpinnerContained from "../../components/SpinnerContained"
import ErrorsContained from "../../components/ErrorContained"
import { parseErrors } from "../../utils";

function Home(props) {
  const {
    revinPermissions,
    marketPulsePermissions,
    caraPermissions,
  } = props

  const user = useGetFullUserQuery()

  const first_name = user?.data?.first_name ?? "";

  const organizations = useGetOrganizationsQuery()

  const hasOrganizations = organizations?.data?.filter(o => o.owner_id === user?.data?.id).length > 0

  const memberships = useGetUserMembershipQuery()

  const membershipsResults = memberships?.data?.results ?? []
  const pendingInvitations = membershipsResults.filter(i => i.accepted === null).length
  const hasMemberships = membershipsResults.filter(m => m.active && m.accepted).length > 0

  const notifications = useGetNotificationsQuery()

  const news = useGetNewsQuery()

  const isPageLoading = [
    user,
    organizations,
    memberships
  ].filter(l => l.isLoading).length

  const isPageError = [
    user,
    organizations,
    memberships
  ].filter(e => e.isError).length

  const showOnboarding =
    !isPageLoading
    && !hasOrganizations
    && !hasMemberships

  const pageErrors = [
    user,
    organizations,
    memberships
  ].map(e => e.error)

  let notificationsEmptyText = notifications.isLoading ? "Loading notifications..." : "You're caught up."
  if (notifications.isError) notificationsEmptyText = parseErrors(notifications.error)

  let newsEmptyText = news.isLoading ? "Loading news..." : "Keep an eye on this space for the latest Carfeine news!"
  if (news.isError) notificationsEmptyText = parseErrors(news.error)

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container maxWidth="lg" sx={{ paddingRight: isSmall ? 0 : 2, paddingLeft: isSmall ? 0 : 2 }}>
      {isPageError ? (
        <ErrorsContained errors={pageErrors}/>
      ) : isPageLoading ? (
        <SpinnerContained />
      ) : (
        <Box px={isSmall ? 0 : 3}>
          <Box
            width='100%'
            minHeight="60px"
            paddingTop={isSmall ? 1 : 5}
            paddingBottom={isSmall ? 3 : 7}
          >
            <Typography variant={isSmall ? "h3" : "h1"} component="h1" textAlign="center">
              {first_name ? `Welcome back, ${first_name}` : " "}
            </Typography>
          </Box>
          <Stack
            direction="column"
            justifyContent="space-around"
            alignItems="center"
            spacing={6}
          >
            <CardArea 
              revinPermissions={revinPermissions} 
              marketPulsePermissions={marketPulsePermissions} 
              caraPermissions={caraPermissions}
            />
            {showOnboarding && (
              <Box p={4} sx={{ width: "100%", bgcolor: theme.palette.background.default }}>
                <Typography variant="h2" mb={2}>
                  Get Started With Carfeine
                </Typography>
                {!hasOrganizations && !hasMemberships && (
                  <Typography variant="subtitle1" mb={2}>
                    <span>
                      It looks like you don&apos;t belong to any organizations yet. You can <a href="/organizations">join or create one here.</a>
                    </span>
                  </Typography>
                )}
                {pendingInvitations > 0 && (
                  <Typography variant="subtitle1">
                    <span>
                      You have {pendingInvitations} pending invitations. <a href="/organizations">Accept an invitation</a> to join an organization.
                    </span>
                  </Typography>
                )}
                <Typography>
                  To use reVIN, you need to be the owner or a member of an organization that has a rooftop and an active subscription.
                </Typography>
              </Box>
            )}
            <TruncatedItemList
              title="Notifications"
              numToShow={3}
              list={notifications?.data?.results}
              overLimitURL="/notifications"
              nameOfItem="notification"
              nameOfItemPlural="notifications"
              keyField="title"
              emptySubText = {notificationsEmptyText}
            />
            <TruncatedItemList
              title="News"
              numToShow={3}
              list={news?.data?.results}
              overLimitURL="/news"
              nameOfItem="story"
              nameOfItemPlural="stories"
              keyField="title"
              emptySubText = {newsEmptyText}
            />
          </Stack>
        </Box>
      )}
    </Container>
  );
}

export default Home;
