import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    text: {
      light: "#666666",
      main: "#222222", // Brand guide Black
      bright: "#fcfcfc" // Slightly grey white, for use on dark backgrounds
    },
    primary: {
      main: "#005BEA" // Brand guide Blue
    },
    secondary: {
      main: "#303442" // Brand guide Grey 1
    },
    tertiary: {
      main: "#A8B7D0" // Brand guide Grey 2
    },
    emphasis: {
      main: "#FF9100" // Brand guide Orange
    },
    error: {
      main: "#990000"
    },
    warning: {
      main: "#EA5100"
    },
    info: {
      main: "#7E6BED"
    },
    success: {
      main: "#129900"
    },
    background: {
      default: "#fff", // Brand guide White, use for containers
      lightest: "#EEF1F4", // Brand guide Grey 3, use for page backgrounds
      light: "#C6D7F5", // Light blue background color
      dark: "#303442", // Same as secondary, use for footers and other bold background elements
      highlight: "#79B3D8"
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "sans-serif"
    ].join(),
    h1: {
      fontWeight: 800,
      fontSize: "3.25rem"
    },
    h2: {
      fontWeight: 700,
      fontSize: "2.625rem"
    },
    h3: {
      fontWeight: 700,
      fontSize: "1.875rem"
    },
    h4: {
      fontWeight: 700,
      fontSize: "1.5rem"
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.25rem"
    },
    h6: {
      fontWeight: 500,
      fontSize: "0.875rem"
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "1.375rem",
      lineHeight: 1.2
    },
    subtitle2: {
      fontWeight: 500,
      lineHeight: 1.2
    },
    body1: {
      lineHeight: 1.4,
      weight: 400
    },
    body2: {
      lineHeight: 1.35,
      weight: 400
    },
    note: {
      fontSize: "0.75rem"
    },
    linkSmall: {
      fontWeight: 600,
      fontSize: "0.75rem",
      color: "#005BEA"
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      #root {
        background-color: #EEF1F4
      }
      `
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          borderRadius: "6px"
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
        square: false
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            minHeight: "80%"
          }
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: "1.5rem"
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "0 24px 16px 24px"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            fontWeight: 700
          },
          "&:hover": {
            fontWeight: 700
          },
        },
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "#C6D7F5"
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          "& .MuiAvatar-img": {
            height: "auto"
          }
        }
      }
    }
  }
});

export default theme
