import { Grid, Typography } from "@mui/material"
import React from "react"

function MemberDescription(props) {
  const {
    title = "",
    role = "",
    acceptedString = ""
  } = props

  return(
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      spacing={2}
      alignItems="flex-start"
    >
      {title && (
        <Grid item>
          <Typography>
            <b>Title: </b>{title}
          </Typography>
        </Grid>
      )}
      {role && (
        <Grid item>
          <Typography>
            <b>Role: </b>{role}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Typography>
          <i>{acceptedString}</i>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default MemberDescription