import React from "react";
import { useGetNewsQuery } from "../../services/apiSlice";
import StandardPage from "../../components/StandardPage";
import SortableList from "../../components/SortableList";
import SortableListItem from "../../components/SortableListItem";

function News() {  
  const {
    data: newsData = {}
  } = useGetNewsQuery()
  const keyedNews = newsData?.results?.map(n => ({
    ...n,
    key: `${n.title}-${n.created_on}`}
  ))

  const sortNews = [
    {
      text: "Date",
      field: "created_on"
    }
  ]

  return (
    <StandardPage title="News">
      <SortableList
        sortOn={sortNews}
        list={keyedNews}
        component={SortableListItem}
      />
    </StandardPage>
  );
}

export default News;
