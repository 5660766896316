import React, { useState } from "react";
import {
  Alert,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  TextField,
  Typography
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useGetUserQuery, useUpdateUserMutation } from "../services/apiSlice";
import { parseErrors } from "../utils"
import SpinnerContained from "../components/SpinnerContained";
import { useNavigate } from "react-router-dom";
  
function UpdateEmail() {
  const navigate = useNavigate()

  const { data = {} } = useGetUserQuery()
  const { email } = data

  const [updateUser, {
    isFetching: isLoading,
    isSuccess,
    isError,
    error
  }] = useUpdateUserMutation()

  const onSave = () => {
    updateUser({ email: newEmail })
  }

  const onClose = () => {
    navigate(-1)
  }

  const [newEmail, setNewEmail] = useState("")

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open
      onClose={onClose}
      PaperProps={{ sx: { padding: "8px 0" } }}
    >
      <DialogTitle>
        <Typography variant="h4" component="span">
          Update Email Address
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: "16px",
            top: "16px"
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {(isLoading) ? (
          <SpinnerContained />
        ) : (
          <>
            <Typography mb={2}>
            Enter a new email address below. Make sure the new address is correct, the next time you log in you will need to use this address.
            </Typography>
            <Typography mb={2}>
              <b>Current email address: </b>{email}
            </Typography>
            <Box>
              <TextField
                label="New Email Address"
                value={newEmail}
                onChange={e => setNewEmail(e.target.value)}
                sx={{ width: "100%" }}
              />
            </Box>
          </>
        )}
      </DialogContent>
      {isError && (
        <Alert severity="error">
          {parseErrors(error)}
        </Alert>
      )}
      {isSuccess && (
        <Alert severity="success">
          Saved
        </Alert>
      )}
      <DialogActions sx={{ padding: "16px 24px" }}>
        <Button
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={onSave}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateEmail;
  