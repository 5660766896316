import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux"
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useGetVehicleCertificationQuery } from "../../services/apiSlice";
import StandardPage from "../../components/StandardPage"
import SpinnerContained from "../../components/SpinnerContained";
import ErrorsContained from "../../components/ErrorContained";
import { joinIfExists } from "../../utils"

function Certification() {
  const { vehicle: vehicle_id } = useParams()

  const token = useSelector(state => state.auth.token)

  const {
    data: certData = {},
    isFetching: isCertFetching,
    error: certError,
    isError: isCertError
  } = useGetVehicleCertificationQuery(vehicle_id, { skip: !vehicle_id })

  const {
    vin,
    rooftop,
    website,
    badge_img,
    year,
    make,
    model,
    trim,
    color,
    certification_level 
  } = certData

  const isCertified = badge_img && certification_level

  const rowData = {
    vin,
    year,
    make,
    model,
    trim,
    color,
  }

  console.log(certError)

  return (
    <StandardPage noTitleSpacer={!token}>
      {isCertFetching ? (
        <SpinnerContained />
      ) : isCertError && [404, 500].includes(certError.originalStatus) ? (
        <ErrorsContained errors={"It doesn't look like this vehicle has been certified by reVIN."} />
      ) : isCertError ? (
        <ErrorsContained errors={certError} />
      ) : !vehicle_id ? (
        <ErrorsContained errors="Whoops. There is no vehicle ID in the URL for this page. Maybe you clicked on a bad link?" />
      ) : (
        <>
          <Stack
            spacing={3}
            alignItems="center"
            flexGrow={1}
          >
            <Box mb={2} textAlign="center">
              <Typography variant="h2" component="h1">
                {`This ${joinIfExists([color, year, make, model, trim], " ")}`}
              </Typography>
              <Typography variant="h3" component="h2">
                {isCertified
                  ? (<span>is certified by reVIN at level <span className="noPhraseBreak">{certification_level}</span></span>)
                  : "is not currently certified."
                }
              </Typography>
            </Box>
            <TableContainer
              component={Paper}
              elevation={1}
              sx={{
                maxWidth: "400px",
                margin: "0 auto"
              }}
            >
              <Table
                aria-label="Table of Vehicle Details"
              >
                <TableBody>
                  {Object.keys(rowData).map(r => (
                    <TableRow key={r}>
                      <TableCell>
                        <b>{r.toUpperCase()}</b>
                      </TableCell>
                      <TableCell>
                        {rowData[r]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <a href={website} rel="noreferrer">
              <Typography variant="h5">
                {`Sold by ${rooftop}`}
              </Typography>
            </a>
            {isCertified && (
              <Box pt={1} pb={4}>
                <img
                  src={badge_img}
                  alt="Certified by reVIN Badge"
                />
              </Box>
            )}
          </Stack>
          <Box sx={{ marginTop: "auto" }} textAlign="center">
            <a href="https://www.carfeine.com/revin/" className="noUnderline">
              <Typography variant="h4" component="p">
                Learn more about reVIN by Carfeine
              </Typography>
            </a>
          </Box>
        </>
      )}
    </StandardPage>
  );
}

export default Certification;