import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  Stack,
  Typography
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../../theme";
import {
  useGetFullOrganizationsQuery,
  useGetOrganizationRelationshipsByOrgQuery,
  useGetRooftopsQuery,
  useGetSubscriptionsQuery
} from "../../services/apiSlice";
import SortableList from "../../components/SortableList";
import SortableListItem from "../../components/SortableListItem";
import { timezones } from "../../const";
import SpinnerContained from "../../components/SpinnerContained";
import ErrorsContained from "../../components/ErrorContained";
import { countString, getQueryState } from "../../utils";

function Details(props) {
  const {
    thisMembership,
    thisOrg,
    organization_id,
    isOwner,
    thisOwner
  } = props

  const {
    role,
    admin,
    //permissions = [],
    title
  } = thisMembership

  const {
    description,
    org_type,
    website,
    logo,
    parent_id,
    allow_child_payment,
    timezone_id
  } = thisOrg

  const getOrgRelationships = useGetOrganizationRelationshipsByOrgQuery(organization_id)
  const { data: orgRelData } = getOrgRelationships

  // Get the children org IDs if any.
  const childOrgIds = orgRelData
    ?.filter(o => o.active && o.parent_id === organization_id)
    ?.map(o => o.child_id)
    ?? []

  const orgIDsToFetch = [...childOrgIds]
  if (parent_id) orgIDsToFetch.push(parent_id)

  // IMPORTANT: Since we don't refetch this when there are no children, it may have old data from an old fetch. -JJ 
  const getOrgs = useGetFullOrganizationsQuery({ org_ids: orgIDsToFetch}, {   
    skip: !orgIDsToFetch.length 
  })
  const { data: orgData = [] } = getOrgs

  const parentOrgData = orgData[parent_id] ?? {}

  const getSubscriptions = useGetSubscriptionsQuery()
  const { data: subscriptionData = [] } = getSubscriptions

  const getRooftops = useGetRooftopsQuery({ organization_id })
  const { data: rooftopsData = [] } = getRooftops

  const thisRooftop = rooftopsData[0]

  const { isFetching, isError, errors } = getQueryState([
    getOrgRelationships,
    getOrgs,
    getSubscriptions,
    getRooftops
  ]) 

  const navigate = useNavigate()
  const location = useLocation()

  const onOrgClick = organization_id => {
    navigate(`/organizations/${organization_id}`)
  }

  const keyedChildOrganizations = childOrgIds
  // remove any ids that don't have data, they have probably been deleted. - JJ
    ?.filter(id => orgData[id])
    ?.map(id => {
      // We use the childOrgIds to avoid issues with cached orgData
      const co = orgData[id]
      // We have to destructure here, because co.members won't allow the push below.
      const members = [...co?.members ?? []]
      members.push("") // add 1 for the owner
      const subscriptions = subscriptionData?.filter(s => s.organization === id) ?? []
      return {
        title: co.name ?? id,
        description: co.description,
        image: co.logo,
        actionState: "",
        actionStateColor: "",
        informationMiddle: countString(members, "member"),
        informationRight: countString(subscriptions, "subscription"),
        mainOnClick: () => onOrgClick(id),
        key: id
      }
    })

  const createChildOrg = () => {
    navigate(`/editorganization?parent=${organization_id}`, {
      state: { backgroundLocation: location }
    })
  }

  /*
  const claimRoofTop = () => {
    console.log("claim rooftop")
  }
  */

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {isFetching ? (
        <SpinnerContained />
      ) : (
        <>
          <Box display="flex" flexGrow={1} flexDirection={isSmall ? "column" : "row"}>
            <Box mr={isSmall ? 0 : 4} width={isSmall ? "100%" : "auto"}>
              <Avatar
                sx={{
                  width: isSmall ? "100%" : "180px",
                  height: isSmall ? "auto" : "180px",
                  bgcolor: theme.palette.tertiary.main,
                  borderColor: theme.palette.tertiary.main,
                  borderStyle: "solid",
                  borderWidth: "1px",
                  marginBottom: "24px"
                }}
                variant="rounded"
                src={logo}
              />
              <Box display="flex">
                <Avatar
                  sx={{
                    width: "48px",
                    height: "48px",
                    marginRight: "16px",
                    bgcolor: theme.palette.tertiary.main,
                    borderColor: theme.palette.tertiary.main,
                    borderStyle: "solid",
                    borderWidth: "1px"
                  }}
                  variant="rounded"
                  src={thisOwner?.avatar}
                />
                <div>
                  <Typography fontWeight={700}>
                  Owner
                  </Typography>
                  <Typography>
                    {thisOwner.first_name}
                  </Typography>
                </div>
              </Box>
            </Box>
            <Stack spacing={1}>
              <Typography>
                <b>Description: </b>{description}
              </Typography>
              <Typography>
                <b>Type: </b>
                {org_type}
              </Typography>
              <Typography>
                <b>Website: </b><a href={website}>{website}</a>
              </Typography>
              {thisRooftop && (
                <>
                  <Typography>
                    <b>Inventory Website: </b><a href={thisRooftop?.inventory_website}>{thisRooftop?.inventory_website}</a>
                  </Typography>
                  <Typography>
                    <b>Rooftop ID: </b>{thisRooftop?.rooftop_id}
                  </Typography>
                </>
              )}
              <Typography>
                <b>Timezone: </b>
                {timezones.find(t => t.id === timezone_id)?.label}
              </Typography>
              {isOwner && (
                <Typography>
                  <b>Allow Sub-Organization Payments: </b>
                  {String(allow_child_payment)}
                </Typography>
              )}
              {parent_id && parentOrgData && (
                <Typography>
                  <b>This organization is a part of: </b>
                  <Button
                    className="textButton"
                    onClick={() => onOrgClick(parentOrgData.organization_id)}
                  >
                    {parentOrgData.name}
                  </Button>
                </Typography>
              )}
              <Typography>
                <b>Your Title: </b>{title}
              </Typography>
              <Typography>
                <b>Your Role: </b>{role}
              </Typography>
            </Stack>
          </Box>
          <Box display="flex">
            {(isOwner || admin) && (
              <Box ml="auto">
                <Button
                  sx={{ marginRight: 2 }}
                  onClick={createChildOrg}
                >
              Add Child Organization
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(`/editorganization/${organization_id}${parent_id ? `?parent=${parent_id}` : ""}`, {
                      state: { backgroundLocation: location }
                    })
                  }}
                >
                Edit
                </Button>
              </Box>
            )}
          </Box>
          {keyedChildOrganizations.length > 0 && 
          (
            <Box mt={2}>
              <SortableList
                listTitle="Child Organizations"
                //action={claimRoofTop}
                //actionText="+ Claim Rooftop"
                list={keyedChildOrganizations}
                component={SortableListItem}
                emptyText={"No child organizations to display."}
              />
            </Box>
          )}
          {isError && (
            <ErrorsContained errors={errors} />
          )}
        </>
      )}  
    </>  
  );
}

export default Details;
