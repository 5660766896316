import React from "react";
import {
  Box,
  Container,
  Paper,
} from "@mui/material"
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import theme from "../theme";
import NavCrumbs from "./NavCrumbs"

function StandardPage(props) {
  const {
    maxWidth = "lg",
    title,
    subtitle,
    noTitleSpacer,
    children
  } = props; 
  
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <NavCrumbs />
      <Container
        maxWidth={maxWidth}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingLeft: isSmall ? 0 : 2,
          paddingRight: isSmall ? 0 : 2
        }}
      >
        <Box
          pt={isSmall ? 1 : 4}
          pb={isSmall ? 2 : 5}
          width="100%"
        >
          {!noTitleSpacer && (
            <Box mb={isSmall ? 2 : 6} textAlign="center">
              {title && (
                <Typography variant={isSmall ? "h2" : "h1"} component="h1">
                  {title}
                </Typography>
              )}
              {title && subtitle && (
                <Typography variant="subtitle1" component="h2">
                  {subtitle}
                </Typography>
              )}
            </Box>
          )}
          <Paper sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            padding: isSmall ? 2 : 4,
            minHeight: "500px"
          }}>
            {children}
          </Paper>
        </Box>
      </Container>
    </>
  );
}

export default StandardPage;
