import React from "react";
import { useState } from "react"
import { Link } from "react-router-dom"
import {
  useSignupMutation
} from "../../services/apiSlice"
import {
  Button,
  Box,
  TextField,
  Stack,
  Alert,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormHelperText
} from "@mui/material"
import { Person as PersonIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { validate } from "../../validate";
import { isObjectWithValues } from "../../utils";

const Signup = () => {
  const navigate = useNavigate()
  const [signup, signupStatus] = useSignupMutation()
  const isLoading = signupStatus.isFetching

  const [email, setEmail] = useState("")
  const [first_name, setFirstName] = useState("")
  const [last_name, setLastName] = useState("")
  const [agree, setAgree] = useState(false) // -- Restore when we have a terms of service -JJ

  const [errors, setErrors] = useState({})

  const signupUser = async () => {
    const errs = validate([
      ["email", "email", email],
      ["first_name", "name", first_name],
      ["last_name", "name", last_name],
      ["agree", "bool", agree] // -- Restore when we have a terms of service -JJ
    ])
    setErrors({...errs})
    
    // Only submit if there are no errors and the API is not loading. -JJ
    if (!isObjectWithValues(errs) && !isLoading) {
      try {
        await signup({
          email,
          first_name,
          last_name,
          agree
        })
        // After a successful submit, show the created sub-page. -JJ
        navigate("/auth/created")
      } catch(err) {
        console.log(err)
      }
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    signupUser()
  }

  return (
    <>
      <Typography variant="h4" component="h2" textAlign="center" pb={2}>
        Let&apos;s Get Started
      </Typography>
      <Typography variant="subtitle1" component="h3" textAlign="center" pb={5}>
        Create your Carfeine Account
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            label="email"
            variant="outlined"
            type="text"
            id="email"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            label="First Name"
            variant="outlined"
            type="text"
            id="first_name"
            name="first_name"
            value={first_name} 
            onChange={e => setFirstName(e.target.value)} 
            error={!!errors.first_name}
            helperText={errors.first_name}
          />
          <TextField
            label="Last Name"
            variant="outlined"
            type="text"
            id="last_name"
            name="last_name"
            value={last_name} 
            onChange={e => setLastName(e.target.value)}
            error={!!errors.last_name}
            helperText={errors.last_name}
          />
          <FormControl
            required
            component="fieldset"
            error={!!errors.agree}
          >
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agree}
                    onChange={() => setAgree(!agree)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={<Typography>I have read and agree to the <Link to="/terms-of-service" target="_blank">terms of service</Link></Typography>}
              />
            </FormGroup>
            {errors.agree && (
              <FormHelperText>{errors.agree}</FormHelperText>
            )}
          </FormControl>
          <Box pt={4}>
            <Button
              color='primary' 
              type='submit'
              variant='contained'
              fullWidth={true}
              startIcon={<PersonIcon />}
              disabled={isLoading}
            >
              {isLoading ? "Signing up..." : "Sign Up"}
            </Button>
          </Box>
          {signupStatus.error && (
            <Box pt={4}>
              <Alert severity='error'>
                {`${signupStatus.error.status}: ${JSON.stringify(signupStatus.error.error)}`}
              </Alert>
            </Box>
          )}
        </Stack>
      </form>
    </>
  )
}

export default Signup