import React, { useState } from "react";
import {
  Button,
} from "@mui/material";
import { SwapVert } from "@mui/icons-material";

function SortButton(props) {
  const {
    text,
    field,
    active,
    onSort // this must be a sort action that received a boolean for the direction
  } = props

  const [reversed, setReversed] = useState(false)

  const onClick = () => {
    onSort(field, !reversed)
    setReversed(!reversed)
  }

  return (
    <div>
      <Button
        endIcon={<SwapVert />}
        onClick={onClick}
        sx={{ fontWeight: active ? 800 : 400 }}
      >
        {text}
      </Button>
    </div>
  )
}

export default SortButton