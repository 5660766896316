import React, {
  useRef,
  useEffect,
} from "react";
import {
  Alert,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useSelector  } from "react-redux"
import {
  useParams,
  useNavigate,
  useSearchParams
} from "react-router-dom"
import {
  useGetUserMembershipQuery,
  useGetFullOrganizationsQuery,
  useSetFullOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetRooftopsQuery,
  useGetOrganizationRelationshipsByOrgQuery
} from "../../services/apiSlice"
import SpinnerContained from "../../components/SpinnerContained"
import ErrorsContained from "../../components/ErrorContained"
import EditOrganization from "./EditOrganization";
import { parseErrors } from "../../utils";
  
function EditOrganizationWrapper() {
  const alertRef = useRef(null)
  const navigate = useNavigate()

  // Use let so we can change the ID if a user is creating a new org. -JJ
  let { organization_id } = useParams()

  const [searchParams] = useSearchParams()
  const parentOrg_id = searchParams.get("parent")

  const user = useSelector(state => state.user)
  const user_id = user.id

  const {
    data: orgRelData,
    isFetching: isOrgRelFetching,
    error: orgRelError,
    isError: isOrgRelError
  } = useGetOrganizationRelationshipsByOrgQuery(organization_id)

  const childOrgIds = orgRelData
    ?.filter(o => o.active && o.parent_id === organization_id)
    ?.map(o => o.child_id)
    ?? []

  const [deleteOrg, {
    isFetching: isDeleteFetching,
    isError: isDeleteError,
    error: deleteError
  }] = useDeleteOrganizationMutation()

  const [postOrg, {
    data: postOrgData,
    isFetching: isPostOrgFetching,
    isSuccess: isPostOrgSuccess,
    isError: isPostOrgError,
    error: postOrgError
  }] = useSetFullOrganizationMutation()

  // Get the organization_id from the posted data, to use if the user wants to make more changes. -JJ
  organization_id = postOrgData?.organization_id
    || postOrgError?.partialSuccess?.orgResult?.organization_id
    || organization_id

  const {
    data: orgData = {},
    error: orgError,
    isError: isOrgError,
    isFetching: isOrgFetching,
  } = useGetFullOrganizationsQuery({
    org_ids: [
      organization_id,
      ...(parentOrg_id ? [parentOrg_id] : []),
      childOrgIds
    ]
  })

  const orgVals = Object.values(orgData)
  const thisOrg = orgVals.find(o => o.organization_id === organization_id) ?? {}
  const parentOrg = orgVals.find(p => p.organization_id === parentOrg_id) ?? {}
  const isOwner = !organization_id || thisOrg.owner_id === user_id
  // A relationship can be returned that is still active but has a deleted org. Only count ones with org data as children. -JJ
  const hasChildren = !!childOrgIds.find(id => orgData[id])

  const {
    data: membData,
    error: membError,
    isError: isMembError,
    isFetching: isMembFetching
  } = useGetUserMembershipQuery()

  const thisMembership = membData?.results.find(m => m.organization_id === organization_id) ?? {}
  const isAdmin = thisMembership.admin

  const {
    data: rooftopsData = [],
    isFetching: isRooftopsFetching,
    error: rooftopsError,
    isError: isRooftopsError
  } = useGetRooftopsQuery({ organization_id })

  // We have to be careful here, if no organization_id is passed in, all rooftops this user has access to are returned. - JJ
  const thisRooftop = organization_id && rooftopsData[0]

  const pageIsFetching =
    isMembFetching
    || isOrgFetching
    || isRooftopsFetching
    || isOrgRelFetching

  const sendIsFetching = 
    isPostOrgFetching
    || isDeleteFetching

  const isPageErrors = isMembError
    || isOrgError
    || isRooftopsError
    || isOrgRelError

  const pageErrors = [
    membError,
    orgError,
    rooftopsError,
    orgRelError
  ].filter(e => e)

  const isPostErrors = isPostOrgError
  || isDeleteError

  const postErrors = [
    postOrgError,
    deleteError
  ].filter(e => e)

  if (!pageIsFetching
    && !isOwner
    && !isAdmin
  ) pageErrors.push("You do not have permission to edit this organization.")

  const onPostOrg = async args => {
    await postOrg(args).unwrap()
      .then(result => {
        navigate(-1)
        // This does nothing, it's just to keep eslint happy. -JJ
        return result
      })
      .catch(err => {
        // Preventing web console from throwing unhandled promise errors here. -JJ
        console.log(err)
      })
  }

  const onDeleteOrg = async args => {
    await deleteOrg(args).unwrap()
      .then(result => {
        navigate(`/organizations${parentOrg_id ? `/${parentOrg_id}` : ""}`)
        // This does nothing, it's just to keep eslint happy. -JJ
        return result
      })
      .catch(err => {
        // Preventing web console from throwing unhandled promise errors here. -JJ
        console.log(err)
      })
  }

  const onClose = () => {
    navigate(-1)
  }

  useEffect(() => {
    if (isPostErrors || isPostOrgSuccess) {
      alertRef.current?.scrollIntoView({ behavior: "smooth" })
    }
    // We need to make postErrors a dependency, so the scroll happens after the error has been parsed and the component re-renders. -JJ
  }, [isPostErrors, postErrors, isPostOrgSuccess])

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open
      PaperProps={{ sx: { padding: "8px 0", minHeight: "300px" } }}
    >
      <DialogTitle>
        <div>
          {`${organization_id ? "Edit" : "Create New"} Organization`}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: "16px",
              top: "16px"
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {parentOrg_id && (
          <div>
            <Typography>
              Parent Organization: {parentOrg.name}
            </Typography>
          </div>
        )}
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        {pageIsFetching ? (
          <SpinnerContained />
        ) : isPageErrors ? (
          <ErrorsContained errors={pageErrors} />
        ) : (
          <>
            <EditOrganization
              user_id={user_id}
              organization_id={organization_id}
              orgData={orgData}
              parentOrg_id={parentOrg_id}
              postOrgData={postOrgData}
              postOrgError={postOrgError}
              thisRooftop={thisRooftop}
              postOrg={onPostOrg}
              deleteOrg={onDeleteOrg}
              hasChildren={hasChildren}
              onClose={onClose}
              pageIsFetching={pageIsFetching}
              isSaving={isPostOrgFetching}
            />
            <div ref={alertRef}>
              {sendIsFetching ? (
                <Box mt={1}>
                  <Alert severity="info">
                      Saving...
                  </Alert>
                </Box>
              ) : isPostOrgSuccess ? (
                <Box mt={1}>
                  <Alert severity="success">
                      Saved
                  </Alert>
                </Box>
              ) : isPostErrors ? (
                <Box mt={1}>
                  <Alert severity="warning">
                    {parseErrors(postErrors)}
                  </Alert>
                </Box>
              ) : (
                null
              )}
            </div>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default EditOrganizationWrapper;
  