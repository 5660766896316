import React, { useState } from "react"
import {
  Button,
  Box,
  Stack,
  Typography
} from "@mui/material"
import SortButton from "./SortButton"
import { useSortList } from "../hooks/useSortList"
import SortableListEmptyPlaceholder from "./SortableListEmptyPlaceholder"

function SortableList(props) {
  const {
    sortOn = [],
    actionText = "",
    action = null,
    actionText2 = "",
    action2 = null,
    list = [],
    component: Component = null,
    descriptionComponent: DescriptionComponent = null,
    emptyText = "",
    emptySubText = "",
    listTitle = "",
    actionButtons = []
  } = props

  const [sortedList, onSort] = useSortList(list)
  const [sortField, setSortField] = useState()

  const sortList = (field, reverse) => {
    setSortField(field)
    onSort(list, field, reverse)
  }

  return (
    <>
      <Box display="flex">
        {listTitle && <Typography variant="h3" mb={1}>{listTitle}</Typography>}
        {sortOn.map(s => (
          <SortButton
            key={s.text}
            text={s.text}
            field={s.field}
            active={s.field === sortField}
            onSort={sortList}
          />
        ))}
        {actionText2 && action2 && (
          <Box ml="auto" mr={5}>
            <Button
              onClick={action2}
              sx={{ textTransform: "none" }}
            >
              {actionText2}
            </Button>
          </Box>
        )}
        {actionText && action && (
          <Box ml="auto">
            <Button
              onClick={action}
              sx={{ textTransform: "none" }}
            >
              {actionText}
            </Button>
          </Box>
        )}
      </Box>
      {list && Component && (
        <Stack>
          {sortedList.map((l, i) => <Component key={l.key} index={i} {...l} descriptionComponent={DescriptionComponent} actionButtons={actionButtons.length ? actionButtons : l.actionButtons}/>)}
        </Stack>
      )}
      {sortedList.length === 0 && (
        <SortableListEmptyPlaceholder text={emptyText} subText={emptySubText} />
      )}
    </>
  )
}

export default SortableList