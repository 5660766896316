import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { 
  Routes,
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from "react-router-dom";
import "./index.css";
import App from "./App";
import { store, persistor } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import CssBaseLine from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme"
import "@fontsource/montserrat/300.css"
import "@fontsource/montserrat/400.css"
import "@fontsource/montserrat/500.css"
import "@fontsource/montserrat/700.css"
import "@fontsource/montserrat/variable.css"

const root = ReactDOM.createRoot(document.getElementById("root"));
const environment = process.env.NODE_ENV;
const dsn = process.env.REACT_APP_SENTRY_DNS

Sentry.init({
  dsn,
  normalizeDepth: 10,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  // TODO: set this based on Dev or Prod
  tracesSampleRate: environment === "dev" ? 0.1 : 1,
  environment,
  autoSessionTracking: true
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

root.render(
  <Provider store={store}>
    <PersistGate loading="loading..." persistor={persistor}>
      <React.StrictMode>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseLine enableColorScheme />
            <App SentryRoutes={SentryRoutes} />
          </ThemeProvider>
        </BrowserRouter>
      </React.StrictMode>
    </PersistGate>
  </Provider>
);