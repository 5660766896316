export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// 8 to 15 characters long, one digit, one uppercase, one lowercase, one special character
export const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{7,30}$/;

export const vinValidate = (vin) => {
  vin = vin ? vin.toLowerCase() : ""
  if (!vin.match(/^[^\Wioq]{17}$/)){
    if (vin.length !== 17){
      return {status: false, msg: "Invalid VIN: Must have 17 characters"}
    } else {
      return {status: false, msg: "Invalid VIN: Letters I, O, or Q not allowed"}
    }
  }

  const weights = [8, 7, 6, 5, 4, 3, 2, 10, 0, 9, 8, 7, 6, 5, 4, 3, 2]
  const transliterations = {
    "0": 0, "1": 1, "2": 2, "3": 3, "4": 4, "5": 5, "6": 6,
    "7": 7, "8": 8, "9": 9, "a": 1, "b": 2, "c": 3, "d": 4,
    "e": 5, "f": 6, "g": 7, "h": 8, "j": 1, "k": 2, "l": 3,
    "m": 4, "n": 5, "p": 7, "r": 9, "s": 2, "t": 3, "u": 4,
    "v": 5, "w": 6, "x": 7, "y": 8, "z": 9
  }

  let sum = 0
  for(var i=0; i<vin.length; i++) {
    sum += transliterations[vin.charAt(i)] * weights[i]
  }

  let checkdigit = sum % 11

  // check digit of 10 represented by X
  if (checkdigit == 10) {
    checkdigit = "x"
  }

  const vincheck = checkdigit == vin.charAt(8)
  return {status: vincheck, msg: !vincheck ? "Invalid VIN: Mismatched value at character 9" : ""}
}

const urlValidate = url => {
  let testURL
  try {
    testURL = new URL(url)
  } catch(error) {
    return {
      valid: false,
      error
    }
  }
  return {
    valid: ["http:", "https:"].includes(testURL.protocol),
    error: "Your URL must start with http or https" // Only used if valid is false above
  }
}

const validators = {
  email: (value, msg) => {
    const error = msg ?? "You must enter a valid email address."
    return !value.match(emailRegex) ? error : ""
  },
  password: (value, msg) => {
    const error = msg ?? "Your password must be 7-30 characters, have lower and uppercase characters, at least 1 number, and at least one special character."
    return !value.match(passwordRegex) ? error : ""
  },
  name: (value, msg) => {
    const error = msg ?? "Must be between 2 and 255 characters."
    return (value.length < 2 || value.length > 255) ? error : ""
  },
  bool: (value, msg) => {
    const error = msg ?? "Required"
    return !value ? error : ""
  },
  vin: (value, msg) => {
    const error = msg ?? "You must enter a valid VIN"
    const vinvalid = vinValidate(value)
    return (!value || !vinvalid.status) ? (vinvalid.msg ? vinvalid.msg : error) : ""
  },
  url: (value, msg) => {
    const error = msg ?? "You must enter a valid website URL. Ex: http://yoursite.com. Error:"
    const urlValid = urlValidate(value)
    return (!value || !urlValid.valid) ? `${error} ${urlValid.error}` : ""
  }
}

/**
 *
 * @param {Object[]} fields - An array of field objects with a field name, value, and optional error string.
 * @param {[string, string, string|number|boolean, string]} - The name of the field, the name of the test, the value, and an optional error string.
 * @returns
 */
export const validate = (fields=[]) => {
  const valErrors = {}
  fields.forEach(f => {
    const val = validators[f[1]](f[2], f[3])
    if (val) valErrors[f[0]] = val
  })
  return valErrors
}