import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux"

/**
 * Creates a protected route that will redirect to the login page if the token is missing. -JJ
 * @param {*} - The route, along with any sub-routes. 
 * @returns - A Navigate that redirects to login or the passed in children.
 */

const ProtectedRoute = () => {
  const token = useSelector(state => state.auth.token)

  // If the token is missing redirect to login.
  if (!token) {
    return <Navigate to="/auth/login" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute