import React, { useState } from "react"
import { 
  useGetOrganizationsQuery,
  useGetOrganizationProfilesQuery, 
  useGetFullOrgSubscriptionsQuery,
  useCreateSubscriptionMutation, 
  useGetProductQuery, 
  useGetPriceQuery 
} from "../../services/apiSlice"
import TitledList from "../../components/TitledList"
import Subscription from "./Subscription"
import { 
  Modal, 
} from "@mui/material"
import AddEditSubscriptionPageOne from "./AddEditSubscriptionPageOne"
import AddEditSubscriptionPageTwo from "./AddEditSubscriptionPageTwo"
import SpinnerContained from "../../components/SpinnerContained"
import ErrorsContained from "../../components/ErrorContained"
import { getQueryState } from "../../utils"

const product_id = process.env.REACT_APP_PRODUCT_ID
const price_id = process.env.REACT_APP_PRICE_ID
const application = process.env.REACT_APP_APPLICATION

function Subscriptions(props) {
  const { thisOrg={} } = props
  const {
    organization_id: org_id,
    parent_id
  } = thisOrg

  const [isFirstPage, setIsFirstPage] = useState(true)
  const [organization_id, setOrganizationId] = useState(org_id ?? "")
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const [subscriptionMsg, setSubscriptionMsg] = useState()
  const [client_secret, setClientSecret] = useState()

  const getProduct = useGetProductQuery(product_id)
  const { data: productData = {} } = getProduct

  const getPrice = useGetPriceQuery(price_id)
  const { data: priceData = {} } = getPrice

  const getParent = useGetOrganizationsQuery([parent_id], { skip: !parent_id })
  const { data: parentData = [] } = getParent
  const { allow_child_payment = true } = parentData[0] ?? {}

  const getFullOrgSubscriptions = useGetFullOrgSubscriptionsQuery()
  const {
    data: subscriptionsData = [],
    refetch: subRefetch
  } = getFullOrgSubscriptions

  const subscriptionsForThisOrg = org_id ? subscriptionsData.filter(s => s.organization === org_id) : []

  const getOrgProfiles = useGetOrganizationProfilesQuery()
  const {
    data: organizationProfilesData = [],
    refetch: orgRefetch
  } = getOrgProfiles

  const organizationProfilesResults = organizationProfilesData?.results ?? []
  const orgsWithSubscription = subscriptionsData.length > 0 ? subscriptionsData.map(s => s.organization) : []
  const orgsWithoutSubscription = organizationProfilesResults.filter(o => !orgsWithSubscription.includes(o.organization_id))

  const [createSubscription, createSubscriptionResult] = useCreateSubscriptionMutation()

  const { isFetching, isError, errors } = getQueryState([
    getProduct,
    getPrice,
    getFullOrgSubscriptions,
    getOrgProfiles,
    createSubscriptionResult
  ])

  const handleClose = () => {
    setOpen(false)
    setIsFirstPage(true)
    subRefetch()
    orgRefetch()
  }

  let keyedSubscriptions = Object.values(subscriptionsData)
  if (org_id) {
    keyedSubscriptions = keyedSubscriptions.filter(s => s.organization === org_id)
  }

  keyedSubscriptions = keyedSubscriptions.map(s => {
    const organization = organizationProfilesResults?.find(o => o.organization_id === s.organization) ?? {}    
    return {
      ...s,
      key: `${s.organization}-${s.stripe_subscription_id}`,
      // Don't show the organization name if all the subscriptions are for the same org.
      title: org_id ? "" : organization?.name,
      product: s.product,
      price: s.price
    }
  })

  const onSelectOrganization = (event) => {
    setOrganizationId(event.target.value)
  }

  const onSubmitSubscription = async () => {
    setSubscriptionMsg("")
    try {
      const clientSecret = await createSubscription({
        product_id,
        price_id,
        organization_id,
        application
      }).unwrap()
      setClientSecret(clientSecret.client_secret)
      setSubscriptionMsg(clientSecret.detail)
      setIsFirstPage(false) 
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = () => {
    subRefetch()
    orgRefetch()
  }

  return (
    <>
      {(isFetching) ? (
        <SpinnerContained />
      ) : isError ? (
        <ErrorsContained errors={errors} />
      ) : (
        <TitledList
          tableTitle="reVIN"
          actionText={
            organizationProfilesResults?.length > 0
            && subscriptionsForThisOrg.length === 0
            && orgsWithoutSubscription.length > 0 
            && allow_child_payment
              ? "+ Add New Subscription" : ""
          }
          action={handleOpen}
          list={keyedSubscriptions}
          component={Subscription}
          emptyText={allow_child_payment ? "You don't have any subscriptions. A subscription can be added to any organization with a rooftop." : "Only your parent organization can add subscriptions."}
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
      >
        {isFirstPage ?
          <>
            <AddEditSubscriptionPageOne
              onSelectOrganization={onSelectOrganization}
              handleClose={handleClose}
              onSubmitSubscription={onSubmitSubscription}
              organizations={orgsWithoutSubscription}
              organization_id={organization_id}
              productData={productData}
              priceData={priceData}
              isCreateSubscriptionLoading={createSubscriptionResult.isFetching}
              isCreateSubscriptionError={createSubscriptionResult.isError}
              createSubscriptionError={createSubscriptionResult.error}
              organization={org_id ? organizationProfilesResults.find(o => o.organization_id === org_id) : null}
            />
          </>

          :
          <>
            <AddEditSubscriptionPageTwo
              handleClose={handleClose}
              productData={productData}
              priceData={priceData}
              subscriptionMsg={subscriptionMsg}
              client_secret={client_secret}
              onSubmit={onSubmit}
            />
          </>
        }
      </Modal>
    </>
  );
}

export default Subscriptions
