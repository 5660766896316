import React from "react"
import {
  Alert,
  Box,
  Button,
  Grid,
  Typography,
  Select,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
  MenuItem,
  Tooltip,
  FormControl,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { parseErrors } from "../../utils"

function AddEditSubscriptionPageOne(props) {
  const {
    onSelectOrganization = null,
    handleClose = null,
    onSubmitSubscription = null,
    organizations = [],
    organization_id,
    priceData = null,
    productData = null,
    isCreateSubscriptionLoading,
    isCreateSubscriptionError,
    createSubscriptionError,
    organization
  } = props

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "2.5%",
        marginBottom: "0",
        width: "40%",
        bgcolor: "background.paper",
        padding: "1em"
      }}
    >
      <Grid container spacing={1}>
        <Grid item sm={11}>
          <Typography variant="h4" component="h1"> New reVIN Subscription</Typography>
        </Grid>
        <Grid item sm={1}>
          <Button
            onClick={handleClose}
            color="inherit"
            startIcon={<CloseIcon />}
            size="large"
            sx={{ padding: "0px" }}
          >
          </Button>
        </Grid>
        <Grid item sm={12}>
          <Typography variant="subtitle2">Upgrade your vehicle descriptions automatically with reVIN!</Typography>
        </Grid>
        <Grid item sm={12}>
          <Link
            href="https://www.carfeine.com/revin/"
            target="_blank"
            rel="noreferrer"
            underline="none"
            sx={{ float: "right", fontWeight: "bold", fontSize: "0.8rem" }}
          >
            Learn more about reVIN
          </Link>
        </Grid>
        <Grid item sm={12}>
          {/* <Typography variant="h5" sx={{fontWeight: "semibold"}}>Subscription for</Typography> */}
          <InputLabel sx={{ fontWeight: "bold" }}>Subscription for</InputLabel>
        </Grid>
        <Grid item sm={12}>
          {organization ? 
            (<InputLabel>{organization.name}</InputLabel>) 
            : (<FormControl fullWidth>
              <InputLabel id="organization-label">Organization</InputLabel>
              <Select
                label="Organization"
                labelId="organization-label"
                onChange={onSelectOrganization}
                sx={{ width: "90%" }}
                value={organization_id}
              >
                {organizations.map(o =>
                  <MenuItem value={o.organization_id} key={o.organization_id}>{o.name}</MenuItem>)
                }
              </Select>
            </FormControl>)
          }
        </Grid>
        <Grid item sm={12}>
          <InputLabel sx={{ fontWeight: "bold" }}>Type of Subscription</InputLabel>
        </Grid>
        <Grid item sm={12}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="1"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="1"
                control={<Radio color="default" />}
                label={`${productData?.name ?? ""} ${priceData?.unit_amount ? `$${priceData?.unit_amount / 100 }/VIN` : ""}`}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item sm={12}>
          <Typography>
            For our reVIN Enterprise subscription at $15/VIN, with automatic website inventory updating, <a href="mailto&#58;&#37;7&#51;u%70po%7&#50;t&#64;&#37;63&#97;r%&#54;6ein%6&#53;&#46;&#99;o&#109;">contact our support team.</a>
          </Typography>
        </Grid>
        <Grid item sm={12}>
          {isCreateSubscriptionLoading ? (
            <Alert severity="info">
              Saving Subscription...
            </Alert>
          ) : isCreateSubscriptionError ? (
            <Alert severity="warning">
              {parseErrors(createSubscriptionError)}
            </Alert>
          ) : (
            null
          )}
        </Grid>
        <Grid item sm={12}>
          {organization_id ?
            <Button
              sx={{ float: "right" }}
              onClick={onSubmitSubscription}
              variant="contained"
            >
              Next
            </Button>
            :
            <Tooltip title="Please fill all required fields." placement="bottom">
              <Button
                sx={{ float: "right" }}
                variant="contained"
              >
                Next
              </Button>
            </Tooltip>
          }

          <Button
            sx={{ float: "right", marginRight: "15px" }}
            onClick={handleClose}
            variant="outlined"
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddEditSubscriptionPageOne
