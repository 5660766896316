import React from "react";
import { useState } from "react"
import {
  useForgotMutation,
} from "../../services/apiSlice"
import {
  Alert,
  Box,
  Button,
  TextField,
  Stack,
  Typography
} from "@mui/material"
import { Email as EmailIcon } from "@mui/icons-material";
import { validate } from "../../validate";
import { isObjectWithValues } from "../../utils";

const ForgotPassword = (props) => {
  const { email = "" } = props

  const [postReset, {
    isFetching,
    isSuccess,
    isError,
    error
  }] = useForgotMutation()

  const [newEmail, setNewEmail] = useState(email)
  const [errors, setErrors] = useState({})

  const resetPassword = async () => {
    const errs = validate([
      ["email", "email", newEmail],
    ])
    setErrors({...errs})

    // Only submit if there are no errors and the API is not loading. -JJ
    if (!isObjectWithValues(errs) && !isFetching) {
      try {
        await postReset({email: newEmail}).unwrap()
      } catch(err) {
        console.log(err)
      }
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    resetPassword()
  }

  return (
    <>
      <Typography variant="h4" component="h2" textAlign="center" pb={2}>
        Reset Password
      </Typography>
      <Typography variant="subtitle1" component="h3" textAlign="center" pb={5}>
        We&apos;ll send a reset link to your email address
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            label="email"
            variant="outlined"
            type="text"
            id="email"
            name="email"
            value={newEmail}
            onChange={e => setNewEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <Box pt={4}>
            <Button
              color='primary' 
              type='submit'
              variant='contained'
              fullWidth={true}
              startIcon={<EmailIcon />}
              disabled={isFetching || isSuccess}
            >
              {isFetching ? "Sending..." : "Send reset email"}
            </Button>
          </Box>
          {isError ? (
            <Alert severity='error'>
              {`${error.status}: ${JSON.stringify(error.error)}`}
            </Alert>
          ) : isSuccess ? (
            <Alert severity="success">
              An email with a reset code has been sent.
            </Alert>
          ) : null
          }
        </Stack>
      </form>
    </>
  )
}

export default ForgotPassword