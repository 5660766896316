import React from "react";
import {
  Box,
  Typography,
  Grid,
  Divider
} from "@mui/material";
import { usdFormat, sortObjAlpha, groupToKey } from "../../utils";

function VinEquipment(props) {
  const { equipment } = props

  const hasEquipment = Array.isArray(equipment) && equipment?.length
  const groupedEquipment = hasEquipment ? sortObjAlpha(groupToKey(equipment, "option_group")) : {}

  return (
    <>
      {hasEquipment ? (
        <>
          {Object.keys(groupedEquipment).map((groupName, i) => (
            <Box key={groupName} mb={3} sx={{ "&:last-child": {marginBottom: "0px" }}}>
              <Typography variant="h4" pb={1}>
                {groupName}
              </Typography>
              <Divider />

              <Grid container rowSpacing={2} columnSpacing={3} mt={0}>
                {groupedEquipment[groupName].map((item)=>(
                  <Grid item key={item.option_name + item.option_description} xs={12} sm={6}>
                    <Typography variant="body2" component={"div"}>
                      <b>{item.option_name}</b>
                      {item.option_description &&
                        <Typography variant="inherit" display="inline" component={"span"}>
                          {" "}
                          { item.option_description }
                        </Typography>
                      }
                      {(item.msrp_price && parseInt(item.msrp_price) !== 0) ? (
                        <Typography variant="inherit" color="primary.main" display="inline" component={"span"}>
                          {" "}
                          { usdFormat(item.msrp_price) }
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </>
      ) : (
        <Box py={"40px"} mx={"auto"}>
          No Data
        </Box>
      )}
    </>
  )
}
export default VinEquipment;