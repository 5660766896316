import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useNavigate } from "react-router-dom";
import { useGetUserQuery } from "../services/apiSlice";
import ForgotPassword from "../pages/auth/ForgotPassword";
  
function UpdatePassword() {
  const navigate = useNavigate()

  const { data = {} } = useGetUserQuery()
  const { email } = data

  const onClose = () => {
    navigate(-1)
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      open
      onClose={onClose}
      PaperProps={{ sx: { padding: "8px 0" } }}
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: "16px",
            top: "16px"
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* The key here is a hack to force the ForgotPassword component to re-render when the email is retrieved. -JJ */}
        <ForgotPassword email={email} key={email} />
      </DialogContent>
    </Dialog>
  );
}

export default UpdatePassword;
  