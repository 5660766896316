import React, { useState } from "react";
import {
  useSearchParams,
  useNavigate
} from "react-router-dom";
import {
  Box,
  Typography,
  Alert,
  Button,
  Stack,
  Tabs,
  Tab,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  useGetReVINCompareQuery,
  useGetReVINDetailsQuery,
  useGetRooftopsQuery
} from "../../services/apiSlice";
import {
  usdFormat,
  outputDateFormat,
  prettifyDataString
} from "../../utils";
import theme from "../../theme"
import SpinnerContained from "../../components/SpinnerContained";
import ErrorsContained from "../../components/ErrorContained";
import VinPackages from "./VinPackages"
import VinEquipment from "./VinEquipment"

const sticky = {
  position: "sticky",
  left: 0,
  backgroundColor: theme.palette.background.default
}

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`VIN-TabPanel-${index}`}
      aria-labelledby={`VIN-Tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `VIN-Tab-${index}`,
    "aria-controls": `VIN-TabPanel-${index}`,
  };
}

function SingleVin(props) {
  const { vehicle_vin } = props
  const [searchParams] = useSearchParams()
  const rooftop_id = searchParams.get("r")
  const sale_price = searchParams.get("sale_price")
  const state_of_vehicle = searchParams.get("state_of_vehicle")

  const navigate = useNavigate()

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const {
    data: rooftopsData = [],
    isFetching: isRooftopsLoading,
    error: rooftopsError,
    isError: isRooftopsError
  } = useGetRooftopsQuery()

  const default_rooftop_id = rooftopsData[0]?.rooftop_id

  const active_rooftop_id = rooftop_id ?? default_rooftop_id

  const {
    data: vehicleCompareData,
    isFetching: isVinCompareLoading,
    isError: isVinCompareError,
    error: vinCompareError
  } = useGetReVINCompareQuery({
    rooftop_id: active_rooftop_id,
    vin: vehicle_vin
  })

  const {
    data: vehicleDetails = [],
    // use isLoading so the page doesn't refresh when the polling triggers -JJ
    isLoading: isVinDetailsLoading,
    isError: isVinDetailsError,
    error: vinDetailsError
  } = useGetReVINDetailsQuery({
    rooftop_id: active_rooftop_id,
    vin: vehicle_vin
  })

  const [currentTab, setCurrentTab] = useState(0);

  const onTabClick = (e, val) => {
    setCurrentTab(val);
  };

  const onToDashboardClick = () => {
    navigate(`/revin?r=${active_rooftop_id}`)
  }

  const onToAddClick = () => {
    navigate(`/revin/add?r=${active_rooftop_id}`)
  }

  const isVinLoading = isVinCompareLoading
    || isVinDetailsLoading
    || isRooftopsLoading

  const isVinErrors = isVinCompareError
    || isVinDetailsError
    || isRooftopsError

  const vinErrors = [
    vinCompareError,
    vinDetailsError,
    rooftopsError
  ].filter(e => e)

  const hasNoCoverage = vehicleDetails?.revin_status === "No Coverage"
  const hasPackages = Array.isArray(vehicleDetails?.vehicle_info?.packages) && vehicleDetails?.vehicle_info?.packages?.length
  const hasStandardEquipment = Array.isArray(vehicleDetails?.vehicle_info?.standard_equipment) && vehicleDetails?.vehicle_info?.standard_equipment?.length
  const hasOptionalEquipment = Array.isArray(vehicleDetails?.vehicle_info?.optional_equipment) && vehicleDetails?.vehicle_info?.optional_equipment?.length

  const compareVINRow = vehicleCompareData?.find(v => v.name === "VIN") ?? []

  const detailsSalePrice = vehicleDetails?.vehicle_info?.core_attributes?.sale_price
  const compareSalePrice = vehicleCompareData?.find(v => v.attribute === "sale_price")?.ReVIN

  const salePriceRow = {
    attribute: "sale_price",
    name: "Sale Price",
    scraper: sale_price,
    ReVIN: compareSalePrice ?? detailsSalePrice
  }
  const detailsStateOfVehicle = vehicleDetails?.vehicle_info?.core_attributes?.state_of_vehicle
  const compareStateOfVehicle = vehicleCompareData?.find(v => v.attribute === "state_of_vehicle")?.ReVIN

  const stateOfVehicleRow = {
    attribute: "state_of_vehicle",
    name: "State of Vehicle",
    scraper: state_of_vehicle,
    ReVIN: compareStateOfVehicle ?? detailsStateOfVehicle
  }

  const excludedRowAttributes = ["vin", "sale_price", "state_of_vehicle"]
  let parsedCompareData = vehicleCompareData?.filter(v => !excludedRowAttributes.includes(v.attribute)) ?? []
  
  // Add price and state of vehicle to the displayed data. We use destructuring here instead of splice to guarantee VIN is first. -JJ
  parsedCompareData = [
    compareVINRow,
    salePriceRow,
    stateOfVehicleRow,
    ...parsedCompareData
  ]

  return (
    <>
      {isVinLoading ? (
        <SpinnerContained />
      ) : isVinErrors ? (
        <ErrorsContained errors={vinErrors} />
      ) : (
        <>
          <Box mt={-2}>
            <Box display="flex" alignItems="center" flexDirection={isSmall ? "column" : "row"}>
              <Typography variant="h3" order={isSmall ? 2 : 1}>
                {vehicleCompareData.find(v => v.attribute === "short_title")?.ReVIN ?? vehicle_vin}
              </Typography>
              <Stack
                marginLeft="auto"
                order={isSmall ? 1 : 2}
                spacing={2}
                direction="row"
              >
                <Button
                  size={isSmall ? "small" : "medium"}
                  onClick={onToAddClick}
                >
                  Run Another VIN
                </Button>
                <Button
                  size={isSmall ? "small" : "medium"}
                  onClick={onToDashboardClick}
                >
                  Dashboard
                </Button>
              </Stack>
            </Box>
            <Typography
              variant="caption"
              mt={1} mb={-1}
              textAlign={isSmall ? "center" : "left"}
              display="block"
            >
              <b>Described at: </b>
              {new Date(vehicleDetails.timestamp).toLocaleString("en-US")}
            </Typography>

            {hasNoCoverage &&
              <Alert severity="warning" sx={{ mt:2 }}>
                reVIN has no coverage for <b>as-built</b> data for {[
                  vehicleCompareData.find(v => v.attribute === "year")?.ReVIN,
                  vehicleCompareData.find(v => v.attribute === "make")?.ReVIN
                ].filter(Boolean).join(" ")}
              </Alert>
            }
          </Box>

          <Box mt={2} mb={2} borderBottom={1} borderBottomColor={theme.palette.tertiary.main}>
            <Tabs
              value={currentTab}
              onChange={onTabClick}
              variant="scrollable"
              scrollButtons={false}
              aria-label="VIN Navigation"
            >
              <Tab
                label={isSmall ? "Data" : "Vehicle Data"}
                {...a11yProps(0)}
              />
              <Tab
                label={isSmall ? "Pckgs" : "Vehicle Packages"}
                disabled={hasNoCoverage || !hasPackages}
                {...a11yProps(1)}
              />
              <Tab
                label={isSmall ? "Equip" : "Standard Equipment"}
                disabled={hasNoCoverage || !hasStandardEquipment}
                {...a11yProps(2)}
              />
              <Tab
                label={isSmall ? "Opt" : "Optional Equipment"}
                disabled={hasNoCoverage || !hasOptionalEquipment}
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>

          <TabPanel value={currentTab} index={0} className="fullHeightTabPanel">
            <TableContainer>
              <Table sx={{ mb:2 }}>
                <colgroup>
                  <col style={{ width:"24%" }}></col>
                  <col style={{ width:"38%" }}></col>
                  <col style={{ width:"38%" }}></col>
                </colgroup>
                <TableHead>
                  <TableRow>
                    <TableCell size="small" style={sticky}>Attribute</TableCell>
                    <TableCell size="small">Provided Value</TableCell>
                    <TableCell size="small">reVIN</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parsedCompareData.map((row) => (
                    <TableRow hover key={row.attribute} sx={{ verticalAlign:"top" }}>
                      <TableCell
                        style={sticky}
                      >{prettifyDataString({ str: row.name })}</TableCell>
                      {row.name === "MSRP" ? (
                        <>
                          <TableCell>
                            { row.scraper != null ? usdFormat(row.scraper) : "" }
                          </TableCell>
                          <TableCell>
                            { row.ReVIN != null ? usdFormat(row.ReVIN) : "" }
                          </TableCell>
                        </>
                      ) : row.name === "Manufactured Date" ? (
                        <>
                          <TableCell>{outputDateFormat(row.scraper, "MM/DD/YYYY")}</TableCell>
                          <TableCell>{outputDateFormat(row.ReVIN, "MM/DD/YYYY")}</TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell>{row.scraper}</TableCell>
                          <TableCell>{row.ReVIN}</TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Typography variant="body2" sx={{ mt:3, mb:0 }}><i>Disclaimer: {vehicleDetails.disclaimer}</i></Typography>
            </TableContainer>
          </TabPanel>

          <TabPanel value={currentTab} index={1} className="fullHeightTabPanel">
            <VinPackages packages={vehicleDetails.vehicle_info.packages} />
          </TabPanel>
          <TabPanel value={currentTab} index={2} className="fullHeightTabPanel">
            <VinEquipment equipment={vehicleDetails.vehicle_info.standard_equipment || []} />
          </TabPanel>
          <TabPanel value={currentTab} index={3} className="fullHeightTabPanel">
            <VinEquipment equipment={vehicleDetails.vehicle_info.optional_equipment || []} />
          </TabPanel>

          {vehicleDetails.certification_level && vehicleDetails.badge_html && (
            <Box mt={3} pb={2} display="flex" justifyContent="center">
              <div dangerouslySetInnerHTML={{ __html: vehicleDetails.badge_html }} />
            </Box>
          )}
        </>
      )}
    </>
  );
}

export default SingleVin;