import React from "react";
import { useState } from "react"
import { useSearchParams } from "react-router-dom";
import {
  useLoginMutation,
  useUpdateAcceptMembershipQuery
} from "../../services/apiSlice"
import {
  Button,
  TextField,
  Stack,
  Alert,
  Box,
  Typography
} from "@mui/material"
import { Login as LoginIcon } from "@mui/icons-material"
import { validate } from "../../validate";
import { isObjectWithValues, parseErrors } from "../../utils";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const membership_id = searchParams.get("membership_id")

  const [login, {
    isFetching: isLoading,
    isError,
    error
  }] = useLoginMutation()

  const {
    refetch: refetchMemb
  } = useUpdateAcceptMembershipQuery(membership_id, { skip: !membership_id })

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errors, setErrors] = useState({})

  const loginUser = async () => {
    const errs = validate([
      ["email", "email", email],
    ])
    setErrors({ ...errs })

    // Only submit if there are no errors and the API is not loading. -JJ
    if (!isObjectWithValues(errs) && !isLoading) {
      try {
        const result = await login({ email, password }).unwrap()
        if (result.token) {
          if (membership_id) refetchMemb()
          navigate("/")
        }
      } catch(err) {
        console.log(err)
      }
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    loginUser()
  }

  return (
    <>
      <Typography variant="h4" component="h2" textAlign="center" pb={2}>
        Welcome Back
      </Typography>
      <Typography variant="subtitle1" component="h3" textAlign="center" pb={5}>
        Sign in to your account
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            label="email"
            variant="outlined"
            type="text"
            id="email"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            label="password"
            variant="outlined"
            type="password"
            id="password"
            name="password"
            value={password} 
            onChange={e => setPassword(e.target.value)} 
            error={!!errors.password}
            helperText={errors.password}
          />
          <Box pt={4}>
            <Button
              color='primary' 
              type='submit'
              variant='contained'
              fullWidth={true}
              startIcon={<LoginIcon />}
              disabled={isLoading}
            >
              {isLoading ? "Logging in..." : "Login"}
            </Button>
          </Box>
          {isError && (
            <Alert severity="error">
              {parseErrors(error)}
            </Alert>
          )}
        </Stack>
      </form>
    </>
  )
}

export default Login