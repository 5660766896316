export const ownerMembership = {
  admin: true,
  permissions: [],
  title: "Owner",
  accepted: true,
  active: true
}

export const timezones = [
  {
    id: 54,
    databaseName: "America/Adak",
    label: "Adak"
  }, {
    id: 55,
    databaseName: "America/Anchorage",
    label: "Anchorage"
  }, {
    id: 81,
    databaseName: "America/Cancun",
    label: "Cancun"
  }, {
    id: 85,
    databaseName: "America/Chicago",
    label: "Chicago"
  }, {
    id: 86,
    databaseName: "	America/Chihuahua",
    label: "Chihuahua"
  }, {
    id: 94,
    databaseName: "	America/Denver",
    label: "Denver"
  }, {
    id: 97,
    databaseName: "America/Edmonton",
    label: "Edmonton"
  }, {
    id: 110,
    databaseName: "America/Halifax",
    label: "Halifax"
  }, {
    id: 126,
    databaseName: "America/Los_Angeles",
    label: "Los Angeles"
  }, {
    id: 132,
    databaseName: "America/Mazatlan",
    label: "Mazatlan"
  }, {
    id: 134,
    databaseName: "America/Merida",
    label: "Merida"
  }, {
    id: 135,
    databaseName: "America/Mexico_City",
    label: "Mexico City"
  }, {
    id: 142,
    databaseName: "America/New_York",
    label: "New York"
  }, {
    id: 150,
    databaseName: "America/Phoenix",
    label: "Phoenix"
  }, {
    id: 158,
    databaseName: "America/Regina",
    label: "Regina"
  }, {
    id: 165,
    databaseName: "America/St_Johns",
    label: "St. Johns"
  }, {
    id: 174,
    databaseName: "America/Tijuana",
    label: "Tijuana"
  }, {
    id: 175,
    databaseName: "America/Toronto",
    label: "Toronto"
  }, {
    id: 177,
    databaseName: "America/Vancouver",
    label: "Vancouver"
  }, {
    id: 179,
    databaseName: "America/Winnipeg",
    label: "Winnipeg"
  }, {
    id: 369,
    databaseName: "Pacific/Honolulu",
    label: "Honolulu"
  }
]
