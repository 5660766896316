import React from "react"
import {
  Box,
  Grid,
  Stack,
} from "@mui/material"
import { useSelector } from "react-redux"
import logo from "../assets/Carfeine_logo_color.svg"
import CarfeineButton from "./carfeineButton"
import SiteNav from "../pages/SiteNav"

function Header(props) {
  const {
    revinPermissions,
    marketPulsePermissions,
    caraPermissions,
    subscriptionsOverLimit
  } = props

  const token = useSelector(state => state.auth.token)

  return (
    <Box height={75} bgcolor="#ffffff">
      <Grid
        container
        direction="row"
        height="75px"
      >
        <Grid item xs>
          <a href="/">
            <img
              alt="Carfeine Logo"
              src={logo}
              style={{ marginLeft: "23px", height: "75px", width: "234px" }}
            />
          </a>
        </Grid>
        <Grid item xs>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            height="100%"
          >
            {token && (
              <CarfeineButton
                revinPermissions={revinPermissions}
                marketPulsePermissions={marketPulsePermissions}
                caraPermissions={caraPermissions}
              />
            )}
            <SiteNav subscriptionsOverLimit={subscriptionsOverLimit}/>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Header;
