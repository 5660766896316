import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Badge
} from "@mui/material";
import { useSelector } from "react-redux"
import StandardPage from "../../components/StandardPage";
import {
  useGetUserMembershipQuery,
  useGetOrganizationMembersQuery,
  useGetFullOrganizationsQuery,
  useGetUserProfilesQuery,
  useGetOrganizationRolesQuery
} from "../../services/apiSlice"
import SpinnerContained from "../../components/SpinnerContained";
import ErrorsContained from "../../components/ErrorContained";
import Details from "./Details";
import theme from "../../theme"
import Members from "./Members";
import { ownerMembership } from "../../const";
import { joinIfExists } from "../../utils";
import "../../css/Base.scss"

import Subscriptions from "../subscriptions/Subscriptions";

function LinkTab(props) {
  return (
    <Tab
      component="a"
      {...props}
    />
  )
}

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`Organization-TabPanel-${index}`}
      aria-labelledby={`Organization-Tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `Organization-Tab-${index}`,
    "aria-controls": `Organization-TabPanel-${index}`,
  };
}

const indexedTabs = ["details", "members", "subscriptions"]

function Organization(props) {  
  const {
    subscriptionsOverLimit
  } = props

  const { organization_id, tab = "details" } = useParams()
  let tabFromPath = indexedTabs.indexOf(tab)
  tabFromPath = tabFromPath !== -1 ? tabFromPath : 0
  const user = useSelector(state => state.user)
  const user_id = user.id
  const subscriptionsOverLimitForOrg = subscriptionsOverLimit ? subscriptionsOverLimit.filter(s => s.organization === organization_id) : 0

  const {
    data: orgData = [],
    error: orgError,
    isLoading: isOrgLoading,
    isSuccess: isOrgSuccess
  } = useGetFullOrganizationsQuery({ org_ids: [organization_id] })

  const orgVals = Object.values(orgData)
  const thisOrg = orgVals[0] ?? {}
  const isOwner = thisOrg.owner_id === user_id
  // orgVals will be [] if the org was deleted.
  const isDeleted = isOrgSuccess && !orgVals.length

  const {
    data: ownerData = {},
    error: ownerError,
    isLoading: isOwnerLoading
  } = useGetUserProfilesQuery([thisOrg.owner_id])
  const thisOwner = ownerData?.results?.[0] ?? {}

  const {
    data: membData,
    error: membError,
    isLoading: membIsLoading
  } = useGetUserMembershipQuery()

  let thisMembership = membData?.results.find(m => m.organization_id === organization_id) ?? {}

  const hasAdminPriviledges = isOwner

  // TODO: editing when the user is an admin currently throws a 404
  //const hasAdminPriviledges = isOwner || thisMembership?.admin

  // If we have no membership data for this org, and this user is the owner, sub in the owner membership. -JJ
  if (!Object.values(thisMembership)[0] && isOwner) {
    thisMembership = { ...ownerMembership }
  }

  const {
    data: orgMemberData = {},
    error: orgMemberError,
    isLoading: isOrgMemberDataLoading
  } = useGetOrganizationMembersQuery(organization_id)

  const {
    data: orgRolesData = {},
    error: rolesError,
    isLoading: rolesAreLoading
  } = useGetOrganizationRolesQuery(organization_id)

  const {
    name: orgName,
  } = thisOrg

  const pageIsLoading =
    membIsLoading
    || isOrgLoading
    || isOwnerLoading
    || rolesAreLoading
    || isOrgMemberDataLoading

  const pageErrors = [
    membError,
    orgError,
    ownerError,
    rolesError,
    orgMemberError
  ].filter(e => e)

  const user_ids = orgMemberData && orgMemberData.results ? orgMemberData.results.map(m => m?.user_id) : []
  const { 
    data: userData 
  } = useGetUserProfilesQuery(user_ids)

  let orgMemberList = [{
    image: thisOwner?.avatar,
    title: joinIfExists([thisOwner?.first_name, thisOwner?.last_name], " ") || thisOwner.user_id,
    descriptionRole: "Owner",
    accepted: "",
    locked: true,
    key: "owner"
  }]

  if (orgMemberData?.results?.length > 0){
    const activeOrgMembers = orgMemberData.results.filter(m => {
      // If this isn't an admin, don't show pending or declined members. -JJ
      return m.active && (hasAdminPriviledges ? true : m.accepted)
    })
    const activeMemberList = activeOrgMembers?.map(m => {
      const user = userData?.results?.find(u => u.user_id === m.user_id)
      const role = orgRolesData?.results?.find(r => r.id === m.role)
      let title = joinIfExists([user?.first_name, user?.last_name], " ")
      title = title || user?.email || `New user ${user?.user_id}`

      let acceptedString = m.accepted ? "" : "Declined"
      acceptedString = m.accepted === null ? "Pending" : acceptedString

      return {
        image: user?.avatar,
        title,
        created_on: m.created_on,
        descriptionTitle: m.title,
        descriptionRole: role?.name,
        membership_id: m.id,
        organizationRoles: orgRolesData,
        accepted: m.accepted,
        acceptedString,
        key: `${m.user_id}`
      }
    })
    
    orgMemberList = [
      ...orgMemberList,
      ...activeMemberList
    ]
  }

  const navigate = useNavigate()

  const onTabClick = (e, val) => {
    navigate(`/organizations/${organization_id}/${indexedTabs[val]}`)
  };

  return (
    <StandardPage title={orgName}>
      {pageIsLoading ? (
        <SpinnerContained />
      ) : pageErrors.length ? (
        <ErrorsContained errors={pageErrors} />
      ) : isDeleted ? (
        <Box sx={{
          display: "flex",
          flexGrow: 1,
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Typography textAlign="center" mb={3}>
            This organization has been deleted.
          </Typography>
        </Box>
      ) : (
        <>
          <Box mt={-3} mb={3} borderBottom={1} borderBottomColor={theme.palette.tertiary.main}>
            <Tabs
              value={tabFromPath}
              onChange={onTabClick}
              aria-label="basic tabs example"
              className="box-with-child-div"
            >
              <Tab label="Details" {...a11yProps(0)} />
              <Tab label="Members" {...a11yProps(1)} />
              <Tab 
                label={<Badge
                  badgeContent={subscriptionsOverLimitForOrg.length} 
                  color="primary" 
                  invisible={subscriptionsOverLimitForOrg.length === 0}
                >Subscriptions&nbsp;&nbsp;&nbsp;&nbsp;</Badge>} {...a11yProps(2)}
              />
              <LinkTab label="Your Organizations" href="/organizations" sx={{ ml: "auto" }} />
            </Tabs>
          </Box>
          <TabPanel value={tabFromPath} index={0} className="fullHeightTabPanel">
            <Details
              thisMembership={thisMembership}
              thisOrg={thisOrg}
              isOwner={isOwner}
              thisOwner={thisOwner}
              organization_id={organization_id}
            />
          </TabPanel>
          <TabPanel value={tabFromPath} index={1}>
            <Members
              members={orgMemberList}
              organization_id={organization_id}
              organizationRoles={orgRolesData}
              hasAdminPriviledges={hasAdminPriviledges}
            />
          </TabPanel>
          <TabPanel value={tabFromPath} index={2} className="fullHeightTabPanel">
            <Subscriptions thisOrg={thisOrg} />
          </TabPanel>
        </>
      )}
    </StandardPage>
  );
}

export default Organization;
