import React from "react"
import {
  Box,
  Link,
  Typography
} from "@mui/material"
import theme from "../../theme"
import SortableList from "../../components/SortableList"
import SortableListItem from "../../components/SortableListItem";

function TruncatedItemList(props) {
  const {
    title="",
    numToShow=1,
    list=[],
    overLimitURL="",
    nameOfItem="",
    nameOfItemPlural="",
    keyField="",
    emptyText = "",
    emptySubText = "",
  } = props

  const listCopy = [...list]
  const listToDisplay = listCopy
    .splice(0, numToShow)
    .map(l => ({...l, key: l[keyField]}))
  const listOverLimit = listCopy.length

  return (
    <Box p={4} sx={{ width: "100%", bgcolor: theme.palette.background.default }}>
      {title && (
        <Typography component="h2" variant="h3" mb={1}>
          {title}
        </Typography>
      )}
      <SortableList
        list={listToDisplay}
        component={SortableListItem}
        emptyText = {emptyText}
        emptySubText = {emptySubText}
      />
      {listOverLimit > 0 && overLimitURL && (
        <Box style={{ textAlign: "right" }}>
          <Link style={{ fontSize: "12px", fontWeight: "bold" }} href={overLimitURL}>
            {`View ${listOverLimit} more ${listOverLimit > 1 ? nameOfItemPlural : nameOfItem}`}
          </Link>
        </Box>
      )}
    </Box>
  );
}

export default TruncatedItemList;
