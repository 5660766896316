import React from "react"
import { useSelector } from "react-redux"
import {
  Badge,
  Button,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  useGetUserMembershipQuery,
} from "../../services/apiSlice"
import theme from "../../theme";

function Subscription(props) {
  const {
    title = "No Org Found",
    product = {},
    price = {},
    status = "",
    used = 0,
    daily_limit = 0,
    has_payment_method,
    organization,
    owner
  } = props

  const { name } = product
  const {
    recurring_interval,
    unit_amount = 0
  } = price

  const dollarPrice = unit_amount ? `$${unit_amount / 100 }/VIN` : "None"

  const user = useSelector(state => state.user)

  const {
    data: membData,
    // We can ignore fetching and errors, because this data should have already been fetched in the parent, and we're using the cache. -JJ
  } = useGetUserMembershipQuery()

  const thisMembership = membData?.results.find(m => m.organization_id === organization) ?? {}
  const canManage = (owner === user.id) || thisMembership?.permissions?.find(p => p.application === "revin" && p.billing)

  const manageLink = process.env.REACT_APP_STRIPE_MANAGEMENT_LINK;
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container sx={{marginBottom: isSmall ? 5 : 4}} rowSpacing={1}>
      <Grid item={true} xs={8} pr={1}>
        <Typography>
          {title && (<><b>{title}:</b> </>)}
          {name}
        </Typography>
      </Grid>
      <Grid item={true} xs={4} sx={{textAlign: "right"}}>
        {canManage && (
          <Button
            sx={{padding: "0px", textTransform: "none", fontWeight: "bold"}}
            href={manageLink}
          >
            Manage
          </Button>
        )}
      </Grid>
      <Grid item={true} xs={12}>
        <Divider />
      </Grid>
      <Grid item={true} xs={12}>
        <Grid container  rowSpacing={isSmall ? 1 : 0}>
          <Grid item={true} xs={12} md sx={{textAlign: "left"}}>
            <Typography variant="body2">
              <b>Price: </b>{dollarPrice}
            </Typography>
          </Grid>
          <Grid item={true} xs={12} md sx={{textAlign: "left"}}>
            <Typography variant="body2">
              {recurring_interval ? (
                <span>Billed per {recurring_interval}</span>
              ) : (
                <span>Not Billed</span>
              )}
            </Typography>
          </Grid>
          <Grid item={true} xs={12} md sx={{textAlign: "left"}}>
            <Typography
              variant="body2"
              sx={{ color: status === "canceled" ? theme.palette.warning.main : theme.palette.text.main }}
            >
              <b>Status: </b>{status}
            </Typography>
          </Grid>
          <Grid item={true} xs={12} md sx={{textAlign: "left"}}>
            <Typography
              variant="body2"
              sx={{ color: has_payment_method ? theme.palette.text.main : theme.palette.warning.main }}
            >
              {has_payment_method ? (
                <span>Has payment method</span>
              ) : (
                <span>Payment method is missing</span>
              )}
            </Typography>
          </Grid>
          <Grid
            item={true}
            xs={12}
            md
            sx={{textAlign: isSmall ? "left" : "right"}}
          >
            <Badge
              badgeContent={1} 
              color="primary" 
              invisible={used < daily_limit}
            >
              <Typography
                variant="body2"
              >
                <b>VINs run: </b>{used}/{daily_limit} per day
              </Typography>
            </Badge>
          </Grid>
        </Grid>
      </Grid>
      {status === "canceled" && (
        <Grid item={true} xs={12}>
          <Typography
            variant="note"
            textAlign="center"
            component="p"
            sx={{ color: theme.palette.warning.main }}
          >
            <i>
              To un-cancel this subscription, click the manage link above and click the &quot;Renew Plan&quot; button on the Stripe page that opens. You can only have one subscription per organization.
            </i>
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default Subscription;
