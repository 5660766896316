import React from "react"
import {
  Box,
  Typography
} from "@mui/material"
import theme from "../theme"

function SortableListEmptyPlaceholder(props) {
  const {
    text = "--",
    subText
  } = props

  return (
    <Box
      spacing={0}
      direction="row"
      alignItems="flex-start"
      pt={2}
      pb={2}
      style={{ borderTop: `1px solid ${props.index === 0 ? theme.palette.tertiary.main : theme.palette.background.light}` }}
    >
      <Typography variant="h5">
        {text}
      </Typography>
      <Typography>
        {subText}
      </Typography>
    </Box>
  )
}

export default SortableListEmptyPlaceholder