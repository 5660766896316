import React from "react"
import {
  Box,
  Avatar,
  Button,
  Modal,
  Stack,
  Divider,
  IconButton,
  Typography,
  Badge
} from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import {
  AlternateEmail as ContactIcon,
  Business as BusinessIcon,
  Close as CloseIcon,
  Newspaper as NewsIcon,
  Notifications as NotificationsIcon,
  Person as PersonIcon,
  Menu as MenuIcon,
  AllInbox as AllInboxIcon
} from "@mui/icons-material"
import { styled } from "@mui/system"
import { logout } from "../slices/authSlice"
import theme from "../theme"
import { apiSlice, useGetFullUserQuery, useGetUserMembershipQuery } from "../services/apiSlice"

const SideBarNavButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  justifyContent: "flex-start",
  fontSize: "24px",
  fontWeight: 400,
  color: theme.palette.text.main,
  "& .MuiButton-startIcon svg": {
    fontSize: "24px"
  }
}));

function UserProfile(props) {
  const {
    subscriptionsOverLimit=[]
  } = props

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const token = useSelector(state => state.auth.token)

  const { data: userData = {} } = useGetFullUserQuery("", { skip: !token })
  const {
    first_name = "",
    last_name = "",
    email = "",
    avatar
  } = userData

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const {
    data: userMembData,
    isFetching: isUserMembDataLoading,
    isError: isUserMembDataError,
  } = useGetUserMembershipQuery("", { skip: !token })

  const notifications = 
    !isUserMembDataLoading && !isUserMembDataError && userMembData?.results.length > 0 
      ? userMembData.results.filter(i => i.accepted === null).length 
      : 0

  const totalNotifications = notifications + subscriptionsOverLimit.length

  return (
    <>
      {token ? (
        <>
          {isSmall ? (
            <Box display="flex" justifyContent="center" height="100%">
              <IconButton
                onClick={handleOpen}
                ml="auto"
              >
                <MenuIcon />
              </IconButton>
            </Box>
          ) : (
            <>
              <Box display="flex" alignItems="center">
                <Button
                  disableRipple={true}
                  onClick={handleOpen}
                  sx={{
                    color: "#000000", fontSize: "16px", textTransform: "none",
                    ml: 1,
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "transparent"
                    }
                  }}
                >
                  Hi, {`${first_name} ${last_name}`}
                </Button>
              </Box>
              <Box
                display="flex"
                justifyContent="right"
                onClick={handleOpen}
                pt="13px"
                pr="23px"
              >
                <Badge 
                  badgeContent={totalNotifications} 
                  color="primary" 
                  invisible={totalNotifications === 0}
                >
                  <Avatar
                    src={avatar}
                    sx={{
                      height: "49px",
                      width: "49px",
                      alignSelf: "right"
                    }}
                  />
                </Badge>
              </Box>
            </>
          )}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box 
              sx={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "auto",
                width: isSmall ? "75%" : "25%",
                height: "100%",
                bgcolor: "background.paper",
              }}
            >
              <Box
                display="flex"
                pl={2}
                pr={1}
                justifyContent="space-between"
                alignItems="center"
                style={{ height: "60px" }}
                backgroundColor={theme.palette.background.light}
              >
                <Typography variant="h4" component="h1" sx={{ fontWeight: 400 }}>
                  My Account
                </Typography> 
                <IconButton
                  onClick={handleClose}
                  ml="auto"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box display="flex" p={2}>
                <Avatar
                  src={avatar}
                  variant="rounded"
                  style={{ height: "64px", width: "64px", margin: "0px" }}
                />
                <Box display="flex" flexDirection="column" justifyContent="center" ml={2}>
                  <Typography>
                    {`${first_name} ${last_name}`}
                  </Typography>
                  <Typography variant="body2">
                    {email}
                  </Typography>
                </Box>
              </Box>
              <Divider  />
              <Stack spacing={1} pl={3} pt={2}>
                <SideBarNavButton
                  startIcon={<PersonIcon fontSize="inherit" />}
                  onClick={() => {
                    navigate("/profile")
                    handleClose()
                  }}
                >
              Edit Profile
                </SideBarNavButton>
                <SideBarNavButton
                  startIcon={<BusinessIcon />}
                  onClick={() => {
                    navigate("/organizations")
                    handleClose()
                  }}
                >
                  <Badge 
                    badgeContent={notifications} 
                    color="primary" 
                    invisible={notifications === 0}
                  >
                    <span style={{ paddingRight: "16px" }}>Organizations</span>
                  </Badge>
                </SideBarNavButton>
                <SideBarNavButton
                  startIcon={<AllInboxIcon />}
                  onClick={() => {
                    navigate("/subscriptions")
                    handleClose()
                  }}
                >
                  <Badge 
                    badgeContent={subscriptionsOverLimit?.length} 
                    color="primary" 
                    invisible={subscriptionsOverLimit?.length === 0}
                  >
                    <span style={{ paddingRight: "16px" }}>Subscriptions</span>
                  </Badge>
                </SideBarNavButton>
                <SideBarNavButton
                  startIcon={<NotificationsIcon />}
                  onClick={() => {
                    navigate("/notifications")
                    handleClose()
                  }}
                >
                  Notifications
                </SideBarNavButton>
                <SideBarNavButton
                  startIcon={<NewsIcon fontSize="inherit" />}
                  onClick={() => {
                    navigate("/news")
                    handleClose()
                  }}
                >
                  News
                </SideBarNavButton>
                <SideBarNavButton
                  startIcon={<ContactIcon fontSize="inherit" />}
                  onClick={() => {
                    navigate("/contact")
                    handleClose()
                  }}
                >
                  Contact Us
                </SideBarNavButton>
              </Stack>
              <Box
                mt="auto"
                p={3}
              >
                <Button
                  variant="outlined"
                  sx={{ width: "100%" }}
                  onClick={() => {
                    dispatch(logout())
                    dispatch(apiSlice.util.resetApiState())
                    handleClose()
                  }}
                >
                  Logout
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <Box 
          display="flex"
          alignItems="center"
          paddingRight={2}
        >
          <Button
            variant="outlined"
            onClick={() => navigate("/")}
          >
            Login
          </Button>
        </Box>
      )
      }
    </>
  )
}

export default UserProfile;
