import React from "react"
import {
  Box,
  Grid,
} from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery";
import logo from "../assets/Carfeine_logo_white.svg"
import theme from "../theme"

function Footer() {
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box height={75} bgcolor={theme.palette.background.dark} mt={isSmall ? 2 : 5}>
      <Grid
        container
        direction="row"
        height="75px"
      >
        <Grid item xs>
          <img
            alt="Carfeine Logo"
            src={logo}
            style={{ marginLeft: "23px", height: "75px", width: "234px", display: "block" }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Footer;
