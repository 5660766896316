import React  from "react";
import {
  Stack,
} from "@mui/material";
import AppCard from "./AppCard";

function CardArea(props) {
  const {
    revinPermissions,
    marketPulsePermissions,
    caraPermissions,
  } = props

  return (
    <Stack
      direction={{ xs: "column", md: "row"}}
      spacing={{ xs: 3, md: 6 }}
      sx={{ alignItems: "stretch" }}
    >
      <AppCard
        initials="rV"
        titleDescription="Vehicle Inventory Data"
        title="reVIN"
        description="Inaccurate data is costly. Keep your vehicle data clean and accurate."
        appLink="/revin"
        externalLink="https://www.carfeine.com/revin/"
        permissions={revinPermissions}
      />
      <AppCard
        initials="MP"
        titleDescription="Your Local Market"
        title="MarketPulse"
        description="Discover where your dealership stands compared to its competitors."
        appLink="/marketPulse"
        externalLink="https://www.carfeine.com/"
        permissions={marketPulsePermissions}
      />
      <AppCard
        initials="C"
        titleDescription="Digital Marketing AI Platform"
        title="Cara"
        description="Automate your digital marketing. Identify & target car buyers with personalized ads."
        appLink="/cara"
        externalLink="https://www.carfeine.com/meet-cara/"
        permissions={caraPermissions}
      />
    </Stack>
  )
}

export default CardArea;
