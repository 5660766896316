import React from "react";
import {
  Box,
  Button,
  Typography
} from "@mui/material"
import { useNavigate } from "react-router-dom"
import StandardPage from "../components/StandardPage";
import CarCrashIcon from "@mui/icons-material/CarCrash"

function NotFound() {
  const url = document.location.href
  const navigate = useNavigate()

  return (
    <StandardPage title="Looks Like You Took a Wrong Turn">
      <Box my={4} style={{ textAlign: "center" }} >
        <CarCrashIcon
          color="warning"
          sx={{ fontSize: "240px" }} 
        />
        <Typography
          variant="h3"
          component="p"
          mb={2}
        >
          We didn&apos;t find a page at {url}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Button
          variant="contained"
          size="large"
          onClick={() => navigate("/")}
        >
          Get Back on the Road
        </Button>
      </Box>
    </StandardPage>
  );
}

export default NotFound;
