import { createSlice } from "@reduxjs/toolkit"
import { apiSlice } from "../services/apiSlice"
import { logout } from "./authSlice"

const initialState = {
  first_name: "",
  last_name: "",
  email: "",
  avatar: ""
}

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    set: (state, action) => {
      state = {...state, ...action.payload}
    },
    clear: state => {
      state = {...initialState}
    }
  },
  extraReducers: builder => {
    builder
      .addCase(logout, () => initialState)
      .addMatcher(
        apiSlice.endpoints.getFullUser.matchFulfilled,
        (state, { payload }) => {
          return {
            ...state,
            ...payload
          }
        }
      )
  } 
})

export const { set, clear } = userSlice.actions

export default userSlice.reducer