import React, {useState} from "react";
import { useSelector } from "react-redux"
import {
  Badge,
  Box,
  Button,
  Typography
} from "@mui/material";
import StandardPage from "../../components/StandardPage";
import {
  useGetFullOrganizationsQuery,
  useGetFullOrgSubscriptionsQuery,
  useGetUserMembershipQuery,
  useUpdateUserMembershipMutation,
  useGetMultiOrganizationMembersQuery
} from "../../services/apiSlice"
import { useNavigate, useLocation } from "react-router-dom";
import SortableList from "../../components/SortableList";
import SpinnerContained from "../../components/SpinnerContained";
import ErrorsContained from "../../components/ErrorContained";
import SortableListItem from "../../components/SortableListItem";
import {
  capitalizeFirstLetters,
  joinIfExists,
  countString
} from "../../utils"
import { Edit, EmojiTransportation } from "@mui/icons-material";
import { ownerMembership } from "../../const";

const DetailButton = props => (
  <Button
    startIcon={props.edit ? <Edit /> : <></>}
    color={props.color ?? "secondary"}
    onClick={props.onClick}
  >
    <span style={{ flexShrink: 0 }}>
      {props.text}
    </span>
  </Button>
)

const ActionButton = props => (
  <Button
    color={props.color ?? "primary"}
    variant={props.variant ?? "outlined"}
    size="small"
    disabled={props.disabled}
    onClick={props.onClick}
  >
    <span style={{ flexShrink: 0 }}>
      {props.text}
    </span>
  </Button>
)
function Organizations(props) {
  const {
    subscriptionsOverLimit
  } = props

  const user = useSelector(state => state.user)

  const {
    data: userMembData,
    isFetching: isUserMembFetching,
    error: userMembError,
    isError: isUserMembError
  } = useGetUserMembershipQuery()
  const member_org_ids = userMembData?.results ? userMembData.results.map(m => m.organization_id) : []

  const {
    data: orgFullData = {},
    error: orgFullError,
    isError: isOrgFullError,
    isFetching: orgFullIsFetching
  } = useGetFullOrganizationsQuery(
    {
      org_ids: member_org_ids,
      alsoGetOwned: true
    }
  )

  const all_org_ids = [...new Set(Object.values(orgFullData).map(o => o.organization_id))]

  const {
    data: orgMembData = {},
    error: orgMembError,
    isError: isOrgMembError,
    isFetching: orgMembIsFetching
  } = useGetMultiOrganizationMembersQuery(
    {
      org_ids: all_org_ids
    }
  )

  const {
    data: subscriptionData = [],
    isFetching: subscriptionDataFetching,
    isError: isSubscriptionDataError,
    error: subscriptionDataError
  } = useGetFullOrgSubscriptionsQuery()

  const [updateMembership, {
    isFetching: isUpdateMembFetching,
    error: updateMembError,
    isError: isUpdateMembError
  }] = useUpdateUserMembershipMutation()

  const pageIsFetching = 
    orgFullIsFetching
    || isUserMembFetching
    || isUpdateMembFetching
    || orgMembIsFetching
    || subscriptionDataFetching

  const pageErrors = [
    orgFullError,
    userMembError,
    updateMembError,
    orgMembError,
    subscriptionDataError
  ].filter(e => e)

  const pageHasErrors = 
    isOrgFullError
    || isUserMembError
    || isUpdateMembError
    || isOrgMembError
    || isSubscriptionDataError

  const [showDeclined, setShowDeclined] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  const onOrgClick = organization_id => {
    navigate(`/organizations/${organization_id}`)
  }
  
  const membershipsToDisplay = Object.values(orgFullData)
    .map(org => {
    // Get the membership data for this organization, if none set it as owned by this user.
      const mbs = userMembData?.results.find(md => md.organization_id === org.organization_id)
    ?? {
      ...org,
      ...ownerMembership
    }
      const orgSubscriptionsOverLimit = subscriptionsOverLimit?.filter(s => s.organization === org.organization_id)
      const org_type = org.org_type ? capitalizeFirstLetters(org.org_type) : ""
      const isOwner = org.owner_id === user.id

      const childOrgs = Object.values(orgFullData).filter(o => o.parent_id === org.organization_id)

      const detailButtons = []

      const activeMembers = (orgMembData[org.organization_id]?.members ?? []).filter(m => m.active && m.accepted)

      detailButtons.push(
        <DetailButton
          edit={isOwner}
          key={`${org.organization_id}-membersBtn`}
          text={`${activeMembers.length + 1} Member${activeMembers.length > 0 ? "s" : ""}`}
          onClick={() => navigate(`/organizations/${org.organization_id}/members`)}
        />
      )

      const subscriptionsForThisOrg = org.organization_id
        ? subscriptionData.filter(s => s.organization === org.organization_id)
        : []
      
      const subscriptionsNum = subscriptionsForThisOrg.length

      if (subscriptionsNum) {
        detailButtons.push(
          <DetailButton
            edit={isOwner}
            key={`${org.organization_id}-subscriptionBtn`}
            text={`${subscriptionsNum} Subscription${subscriptionsNum > 1 ? "s" : ""}`}
            onClick={() => navigate(`/organizations/${org.organization_id}/subscriptions`)}
          />
        )
      }

      let actionButtons = []
      if (!isOwner) {
        actionButtons = [
          <ActionButton
            key={`${org.organization_id}-leaveBtn`}
            variant="text"
            text="Leave"
            onClick={() => {updateMembership({id: mbs.id, accepted: false})}}
          />
        ]
      }
    
      return {
        title: org.name ?? "--",
        description: joinIfExists([org_type, mbs.title, mbs.role]),
        org_type,
        role: mbs.role,
        image: org.logo,
        accepted: mbs.accepted,
        parent_id: org.parent_id,
        childOrgs,
        informationRight: childOrgs.length ? countString(childOrgs, "child organization") : "",
        timestamp: mbs.accepted ? "" : mbs.created_on,
        key: org.organization_id,
        detailButtons,
        actionButtons,
        actionState: "",
        actionStateColor: "",
        notifications: orgSubscriptionsOverLimit?.length ?? 0,
        mainOnClick: () => onOrgClick(org.organization_id),
        fallbackIcon: <EmojiTransportation />
      }
    })
    .filter(m => m.accepted && !m.parent_id)

  const sortOnOrgs = [
    {
      text: "Name",
      field: "title"
    },
    {
      text: "Type",
      field: "org_type"
    },
    {
      text: "Role",
      field: "role"
    }
  ]

  const createOrg = () => {
    navigate("/editorganization", {
      state: { backgroundLocation: location }
    })
  }

  const sortOnInv = [
    {
      text: "Name",
      field: "title"
    },
    {
      text: "Date",
      field: "timestamp"
    },
    {
      text: "Type",
      field: "org_type"
    }
  ]

  const toggleDeclinedInv = () => {
    setShowDeclined(!showDeclined)
  }

  const allInvitations = (userMembData?.results?.filter(m => m.active) ?? [])
    .map(i => {
      const org = Object.values(orgFullData).find(o => o.organization_id === i.organization_id)
      const org_type = org && org.org_type ? capitalizeFirstLetters(org.org_type) : ""

      const actionButtons = [
        <ActionButton
          key={`${i.organization_id}-declineBtn`}
          text={i.accepted === false ? "Declined" : "Decline"}
          variant="text"
          color="secondary"
          disabled={i.accepted === false}
          onClick={() => {updateMembership({id: i.id, accepted: false})}}
        />,
        <ActionButton
          key={`${i.organization_id}-acceptBtn`}
          text="Accept"
          onClick={() => {updateMembership({id: i.id, accepted: true})}}
        />
      ]

      return {
        title: org ? org.name : "No Organization Name Found",
        description: joinIfExists([org_type, i.title, i.role]),
        updated_on: i.updated_on,
        actionButtons: actionButtons,
        accepted: i.accepted,
        key: i.id,
        fallbackIcon: <EmojiTransportation />
      }
    })

  let invitations = allInvitations?.filter(m => m.accepted === null)
  const unAcceptedInvitations = [...invitations]
  const declinedInvitationCount = allInvitations?.filter(i => i.accepted === false).length
  if (showDeclined) invitations = allInvitations?.filter(i => [null, false].includes(i.accepted))

  return (
    <StandardPage title="Organizations" subtitle="Manage Your Organizations and Dealerships">
      {pageIsFetching ? (
        <SpinnerContained />
      ) : pageHasErrors? (
        <ErrorsContained errors={pageErrors} />
      ) : (
        <>
          <Typography variant="h3" mb={1}>Organizations</Typography>
          <Box mb={4}>
            <SortableList
              sortOn={sortOnOrgs}
              action={createOrg}
              actionText="+ Create New Organization"
              list={membershipsToDisplay}
              component={SortableListItem}
              emptyText={"Join or create an organization."}
            />
          </Box>
          <Badge 
            badgeContent={unAcceptedInvitations.length} 
            color="primary" 
            invisible={unAcceptedInvitations.length === 0}
            sx={{width: "11.5em"}}
          >
            <Typography variant="h3" mb={1}>Invitations</Typography>
          </Badge>
          <Box mb={2}>
            <SortableList
              sortOn={sortOnInv}
              action={declinedInvitationCount > 0 ? toggleDeclinedInv : null}
              actionText={showDeclined ? "Hide Declined Invitations" : `Show Declined (${declinedInvitationCount})`}
              list={invitations}
              component={SortableListItem}
              emptyText={"You don't have any invitations yet."}
            />
          </Box>
        </>
      )}
    </StandardPage>
  );
}

export default Organizations;
