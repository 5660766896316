import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Tabs,
  Tab,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  useSearchParams,
  useNavigate
} from "react-router-dom";
import theme from "../../theme"
import Dashboard from "./Dashboard"
import AllVINs from "./AllVINs"
import AddVIN from "./AddVIN"
import Coverage from "./Coverage"

function TabPanel(props) {
  const {
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`ReVIN-TabPanel-${index}`}
      aria-labelledby={`ReVIN-Tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          {children}
        </>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `ReVIN-Tab-${index}`,
    "aria-controls": `ReVIN-TabPanel-${index}`,
  };
}

const rooftopName = r => {
  const statusFragment = r.status !== "active" ? `-${r.status}` : ""
  const payFragment = !r.has_payment_method ? "-no payment method" : ""
  return `${r.name}${statusFragment}${payFragment}`
}

function ReVINInner(props) {
  const {
    default_rooftop_id,
    rooftopsList,
    currentTabIndex,
    indexedTabs
  } = props

  const [searchParams, setSearchParams] = useSearchParams()
  const rooftop_id = searchParams.get("r")

  const active_rooftop_id = rooftop_id ?? default_rooftop_id
  const active_rooftop_status = rooftopsList.find(r => r.rooftop_id === active_rooftop_id)?.status

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  // If we arrive at this page with no rooftop in the URL, set the default rooftop ID as the r parameter.
  useEffect(() => {
    if (!rooftop_id && default_rooftop_id) setSearchParams({ r: default_rooftop_id })
  }, [default_rooftop_id, rooftop_id, setSearchParams])

  const navigate = useNavigate()

  const onTabClick = (e, val) => {
    navigate(`/revin/${indexedTabs[val]}?r=${rooftop_id}`)
  };

  const onRooftopSelect = (e) => {
    setSearchParams({ r: e.target.value })
  }

  return (
    <>
      <Box mt={isSmall ? 0 : -3} mb={3} borderBottom={1} borderBottomColor={theme.palette.tertiary.main}>
        <Grid container>
          <Grid item xs={12} sm={12} md={4} order={{ md: 2 }}>
            <Box sx={{mb: {sm: 1, md:0}}}>
              <FormControl fullWidth size="small" variant={ isSmall ? "filled" : "standard" }>
                <InputLabel id="revin-rooftop-select-label">Select Rooftop</InputLabel>
                <Select
                  labelId="revin-rooftop-select-label"
                  id="revin-rooftop-select"
                  value={active_rooftop_id}
                  label="Select Rooftop"
                  onChange={onRooftopSelect}
                  disableUnderline
                >
                  {rooftopsList.map(rooftop =>(
                    <MenuItem
                      value={rooftop.rooftop_id}
                      key={rooftop.rooftop_id}
                      disabled={rooftop.status === "canceled" || !rooftop.has_payment_method}
                    >
                      {rooftopName(rooftop)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Tabs
              value={currentTabIndex}
              onChange={onTabClick}
              aria-label="reVIN Navigation"
              orientation={isSmall ? "vertical" : "horizontal"}
              sx={{ marginTop: isSmall ? 2 : 0 }}
            >
              <Tab label="Dashboard" {...a11yProps(0)} />
              <Tab label="All VINs" {...a11yProps(1)} />
              <Tab label="Add VIN" {...a11yProps(2)} />
              <Tab label="Coverage" {...a11yProps(3)} />
            </Tabs>
          </Grid>
        </Grid>
      </Box>
      <Box position="relative" flexGrow={1}>
        <TabPanel value={currentTabIndex} index={0} className="fullHeightTabPanel">
          <Dashboard rooftop_id={active_rooftop_id} />
        </TabPanel>
        <TabPanel value={currentTabIndex} index={1}>
          <AllVINs rooftop_id={active_rooftop_id} key={active_rooftop_id} />
        </TabPanel>
        <TabPanel value={currentTabIndex} index={2} className="fullHeightTabPanel">
          <AddVIN rooftop_id={active_rooftop_id} rooftop_status={active_rooftop_status} />
        </TabPanel>
        <TabPanel value={currentTabIndex} index={3} className="fullHeightTabPanel">
          <Coverage rooftop_id={active_rooftop_id} />
        </TabPanel>
      </Box>
    </>
  );
}

export default ReVINInner;