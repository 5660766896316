import React from "react";
import { useGetNotificationsQuery } from "../../services/apiSlice";
import StandardPage from "../../components/StandardPage";
import SortableList from "../../components/SortableList";
import SortableListItem from "../../components/SortableListItem";

function Notifications() {  
  const {
    data: notificationsData = {}
  } = useGetNotificationsQuery()
  const keyedNotifications = notificationsData?.results?.map(n => ({...n, key: `${n.title}-${n.created_on}`}))

  const sortNotifications = [
    {
      text: "Date",
      field: "created_on"
    }
  ]

  return (
    <StandardPage title="Notifications">
      <SortableList
        sortOn={sortNotifications}
        list={keyedNotifications}
        component={SortableListItem}
      />
    </StandardPage>
  );
}

export default Notifications;
