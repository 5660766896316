import React from "react";
import {  useSelector } from "react-redux"
import {
  useGetUserProfilesQuery
} from "../../services/apiSlice"
import StandardPage from "../../components/StandardPage"
import SpinnerContained from "../../components/SpinnerContained"
import ErrorsContained from "../../components/ErrorContained"
import EditProfile from "./EditProfile"

function Profile() {
  const user = useSelector(state => state.user)

  const {
    data = {},
    isFetching: isLoading,
    error,
    isError
  } = useGetUserProfilesQuery([user.id])

  const profile = data?.results?.[0] ?? {}

  return (
    <StandardPage
      maxWidth="md"
      title="Edit Profile"
      subtitle="Update Your Account Information"
    >
      {(isLoading) ? (
        <SpinnerContained />
      ) : isError ? (
        <ErrorsContained errors={error} />
      ) : (
        <EditProfile profile={profile} />      
      )}
    </StandardPage>
  );
}

export default Profile;
