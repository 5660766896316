import React from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  List,
  ListItem
} from "@mui/material";
import { usdFormat } from "../../utils";

function VinPackages(props) {
  const { packages } = props

  const hasPackages = Array.isArray(packages) && packages.length

  return (
    <>
      {hasPackages &&
        <TableContainer>
          <Table sx={{ "& .MuiTableRow-root:last-child td": {borderBottom: "none"} }}>
            <colgroup>
              <col style={{ width:"20%" }}></col>
              <col style={{ width:"20%" }}></col>
              <col style={{ width:"110px" }}></col>
              <col style={{ width:"110px" }}></col>
              <col style={{ width: "auto" }}></col>
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell size="small">Package</TableCell>
                <TableCell size="small">Type</TableCell>
                <TableCell size="small">MSRP</TableCell>
                <TableCell size="small">Invoice</TableCell>
                <TableCell size="small">Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {packages.map((row) => (
                <TableRow hover key={row.package_name} sx={{ verticalAlign: "top" }}>
                  <TableCell>{row.package_name}</TableCell>
                  <TableCell>{row.type}</TableCell>
                  <TableCell >
                    { row.msrp != null ? usdFormat(row.msrp) : "" }
                  </TableCell>
                  <TableCell>
                    { row.invoice_price != null ? usdFormat(row.invoice_price) : "" }
                  </TableCell>
                  <TableCell>
                    {row.description.length > 0 ? (
                      <List sx={{ listStyleType: "disc", pl: "12px", pt: 0 }}>
                        {row.description.map((desc) => (
                          <ListItem key={desc} sx={{ display: "list-item", pt: 0 }}>
                            {desc}
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <>-</>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </>
  )
}
export default VinPackages;