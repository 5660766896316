import React from "react";
import {
  Typography
} from "@mui/material"

const Created = () => {
  return (
    <>
      <Typography variant="h4" component="h2" textAlign="center" pb={2}>
        Check Your Email
      </Typography>
      <Typography variant="subtitle1" component="h3" textAlign="center" pb={5}>
        Your account is being created.
      </Typography>
      <p>
        We are sending an account activation message to your email account, with a link to set your new password.
      </p>
      <p>
        You cannot access your account until you receive that email message and click the link to set your password.
      </p>
      <p>
        If you don&apos;t receive the email within 24 hours, you can <a href="mailto&#58;&#37;7&#51;u%70po%7&#50;t&#64;&#37;63&#97;r%&#54;6ein%6&#53;&#46;&#99;o&#109;">contact us to request assistance.</a>
      </p>
    </>
  )
}

export default Created