import React from "react"
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  Box,
  Menu,
  MenuItem,
  Divider,
  Typography
} from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "../theme"

const MenuText = props => (
  <Box mb={1}>
    <Typography fontWeight={700}>
      {props.title}
    </Typography>
    <Typography variant="subtitle2">
      {props.subtitle}
    </Typography>
  </Box>
)

function CarfeineButton(props) {
  const loc = useLocation();
  const {
    revinPermissions,
    marketPulsePermissions,
    caraPermissions,
  } = props

  const isLarge = useMediaQuery(theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function iconRotation() {
    if (open) return 180
    else return 0
  }

  return (
    <>
      <Button
        startIcon={<DashboardIcon />}
        endIcon={<ExpandMoreIcon style={{ transform: `rotate(${iconRotation()}deg)`, transition: "0.5s ease-in-out" }} />}
        id="carfeine-button"
        aria-controls={open ? "carfeine-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        disableRipple={true}
        sx={{
          color: "#000000", fontSize: "16px", textTransform: "none",
          ml: 1,
          "&.MuiButtonBase-root:hover": {
            bgcolor: "transparent"
          }
        }}
      >
        { isLarge ? "Carfeine" : ""}
      </Button>
      <Menu
        id="carfeine-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        MenuListProps={{
          "aria-labelledby": "carfeine-button",
        }}
      >
        <MenuItem component={Link} to="/" onClick={handleClose} sx={{ width: "300px" }} selected={ loc.pathname === "/" }>
          <Box py={1}>
            <Typography>Home</Typography>
          </Box>
        </MenuItem>
        <Divider sx={{ m: 0.5 }} />
        {revinPermissions?.length > 0 && 
        (<MenuItem component={Link} to="/revin/" onClick={handleClose} selected={ loc.pathname.startsWith("/revin/") }>
          <MenuText title="reVIN" subtitle="Clean your vehicle data" />
        </MenuItem>)
        }
        {marketPulsePermissions?.length > 0 && 
        (<MenuItem onClick={handleClose} selected={ loc.pathname.startsWith("/marketpulse/") }>
          <MenuText title="MarketPulse" subtitle="Competitive view of your market" />
        </MenuItem>)
        }
        {caraPermissions?.length > 0 && 
        (<MenuItem onClick={handleClose} selected={ loc.pathname.startsWith("/cara/") }>
          <MenuText title="Cara" subtitle="Automate your digital marketing" />
        </MenuItem>)
        }
      </Menu>
    </>
  )
}

export default CarfeineButton;
