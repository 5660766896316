import React, { useEffect, useState } from "react";
import { useNavigate  } from "react-router-dom";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useGetReVINVehiclesQuery } from "../../services/apiSlice";
import { usdFormat, outputDateFormat } from "../../utils";
import theme from "../../theme"
import SpinnerContained from "../../components/SpinnerContained";
import ErrorsContained from "../../components/ErrorContained";
import SortableTableHead from "../../components/SortableTableHead";
import VinsTablePaginationActions from "../../components/VinsTablePaginationActions";

const allVinsHeadCells = [
  {
    id: "vin",
    disabledPadding: true,
    label: "VIN",
    sortable: false
  },
  {
    id: "vehicle",
    disabledPadding: true,
    label: "Vehicle",
    sortable: false
  },
  {
    id: "msrp",
    disabledPadding: true,
    label: "MSRP",
    sortable: true
  },
  {
    id: "last_update_datetime",
    disabledPadding: true,
    label: "Timestamp",
    sortable: true
  }
]

function AllVINs(props) {
  const { rooftop_id } = props
  const navigate = useNavigate()
  const [sortBy, setSortBy] = useState("last_update_datetime")
  const [sortDirection, setSortDirection] = useState("asc")
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [searchText, setSearchText] = useState("")
  const [searchTerm, setSearchTerm] = useState("")

  // default page 1, and 10 per page
  const [page, setPage] = useState(1)
  const [vinsPerPage, setVinsPerPage] = useState(10)

  const {
    data: vehiclesData = [],
    isFetching: isVehiclesLoading,
    error: vehiclesError,
    isError: isVehiclesError
  } = useGetReVINVehiclesQuery({
    rooftop_id,
    page_size: vinsPerPage,
    page: page,
    ordering: sortDirection === "asc" ? sortBy: "-" + sortBy,
    search: searchText
  })

  const handleRequestSort = (event, property) => {
    const isAsc = sortBy === property && sortDirection === "asc";
    const newDirection = isAsc ? "desc" : "asc"
    setSortDirection(newDirection)
    setSortBy(property)
  }

  const handleChangePage = (newPage, paginateType = null) => {
    // Can just setPage to new page and make request based on that page.
    // or utilize the next/previous values in our last request in cases where
    // the next/prev link is used

    if (paginateType){
      setPage(newPage)
    } else {
      setPage(newPage)
    }
  }

  const handleChangeVinsPerPage = (event) => {
    setVinsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const searchVins = (event) => {
    event.preventDefault()
    setSearchText(event.target.search.value)
  }

  const onSearchChange = (event) => {
    setSearchTerm(event.target.value)
  }

  //this is required to create a debounce effect and not search instantly as the user types one letter - L.H.
  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchText(searchTerm), 500);
    return () => clearTimeout(timeOutId);
  }, [searchTerm]);

  return (
    <>
      {isVehiclesLoading ? (
        <SpinnerContained />
      ) : isVehiclesError ? (
        <ErrorsContained errors={vehiclesError} />
      ) : (
        <>
          <Grid container pb={1}>
            <Grid item xs={12} sm={12} md={8}>
              <Typography variant="h4">
                All VINs
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} mt={{xs:0, md:-2}} mb={{ xs:"6px",md:0 }}>
              <form onSubmit={searchVins} style={{ width:"100%" }}>
                <TextField
                  onChange={onSearchChange}
                  value={searchTerm}
                  id="vins-search"
                  label="Search"
                  type="search"
                  name="search"
                  variant="standard"
                  size="small"
                  fullWidth
                  inputProps={{
                    style:{
                      paddingBottom:"14px"
                    }
                  }}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end" sx={{ mt:"-14px" }}>
                        <IconButton
                          aria-label="Search VINs"
                          type="submit"
                        >
                          <SearchIcon/>
                        </IconButton>
                      </InputAdornment>
                    ),
                    style:{
                      marginBottom:"-14px"
                    }
                  }}
                />
              </form>
            </Grid>
          </Grid>

          <TableContainer sx={{ borderTop:1, borderTopColor:"rgba(224,224,224,1)", marginTop:"-1px", overflowX: isSmall ? "auto" : "initial" }}>
            <Table stickyHeader>
              <colgroup>
                <col style={{ width:200 }}></col>
                <col></col>
                <col style={{ width:180 }}></col>
                <col style={{ width:200 }}></col>
              </colgroup>
              <SortableTableHead
                direction={sortDirection}
                sortBy={sortBy}
                headCells={allVinsHeadCells}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {vehiclesData.results.map((row) => (
                  <TableRow key={row.vin} sx={{ verticalAlign:"top", cursor: "pointer" }} onClick={() => navigate(`/revin/${row.vin}?r=${rooftop_id}`)}>
                    <TableCell>{row.vin}</TableCell>
                    <TableCell>{[row.year, row.make, row.model].filter(Boolean).join(" ")}</TableCell>
                    <TableCell>
                      { row.msrp != null ? usdFormat(row.msrp) : "" }
                    </TableCell>
                    <TableCell>{outputDateFormat(row.last_update_datetime, "MM/DD/YYYY, h:mm A")}</TableCell>
                  </TableRow>
                ))}
                {vehiclesData.results.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography textAlign="center">
                        {`No vehicles were found${searchTerm ? ` that match ${searchTerm}` : ""}.`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {vehiclesData.total_pages > 1 && (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50]}
                      colSpan={4}
                      count={vehiclesData.count}
                      rowsPerPage={vinsPerPage || 0}
                      page={page || 0}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "VINs per page"
                        },
                        native: true
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeVinsPerPage}
                      ActionsComponent={VinsTablePaginationActions}
                      sx={{borderBottom:"none"}}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}

export default AllVINs
