import React, { useState } from "react"
import {
  Alert,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import CloseIcon from "@mui/icons-material/Close"
import { Elements } from "@stripe/react-stripe-js"
import SetupForm from "../stripe/SetupForm"
import { loadStripe } from "@stripe/stripe-js"
import SpinnerContained from "../../components/SpinnerContained"
import { parseErrors } from "../../utils"

const publishable_key = process.env.REACT_APP_PUBLISHABLE_KEY
const stripePromise = loadStripe(publishable_key)

function AddEditSubscriptionPageTwo(props) {
  const {
    handleClose = null,
    client_secret = null,
    productData = {},
    subscriptionMsg,
    onSubmit
  } = props

  const options = { clientSecret: client_secret }

  const [stripeError, setStripeError] = useState()

  stripePromise
    .catch(error => setStripeError(error))

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "2.5%",
        marginBottom: "0",
        width: "40%",
        bgcolor: "background.paper",
        padding: "1em"
      }}
    >
      <Grid container spacing={2}>
        <Grid item sm={11}>
          <Typography
            variant="h4"
            component="h1"
            sx={{ marginBottom: "1em" }}
          >
            New reVIN Subscription
          </Typography>
        </Grid>
        <Grid item sm={1}>
          <Button
            onClick={handleClose}
            color="inherit"
            startIcon={<CloseIcon />}
            size="large"
            sx={{ padding: "0px" }}
          >
          </Button>              
        </Grid>
        <Grid item sm={12}>
          <Typography variant="h4">Enter your card details.</Typography>
        </Grid>
        <Grid item sm={12}>
          <Typography variant="h4">Your subscription will start now.</Typography>
        </Grid>
        <Grid item sm={12}>
          {productData && (
            <Typography sx={{ fontSize: "1.1rem" }}>
              <ArrowForwardIcon fontSize="0.7rem" color="#304156" sx={{ paddingTop: "0.2em" }} 
              /> Subscribing to <b>{productData.name}</b>
            </Typography>
          )}
        </Grid>
        <Grid item sm={12} sx={{ position: "relative", minHeight: "250px" }}>
          {stripePromise && client_secret ?
            <Elements stripe={stripePromise} options={options}>
              <SetupForm onSubmit={onSubmit} />
            </Elements> 
            : subscriptionMsg ?
              <Typography variant="h4">{subscriptionMsg}</Typography>
              :
              <SpinnerContained />
          }
        </Grid>
      </Grid>
      {stripeError && (
        <Box mt={1}>
          <Alert severity="warning">
            {parseErrors(stripeError)}
          </Alert>
        </Box>
      )}
    </Box>
  )
}

export default AddEditSubscriptionPageTwo
