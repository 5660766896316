import React from "react";
import {
  Button,
  Box,
  Container,
  Grid,
  Paper,
  Stack,
} from "@mui/material"
import Typography from "@mui/material/Typography";
import theme from "../theme";

function StyleGuide() {  
  return (
    <Container maxWidth="lg" sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Paper sx={{ padding: theme.spacing(3) }}>
        <Stack sx={{ width: "100%"}} spacing={2} mb={3}>
          <Typography variant="h1" gutterBottom>
        h1. Heading
          </Typography>
          <Typography variant="h2" gutterBottom>
        h2. Heading
          </Typography>
          <Typography variant="h3" gutterBottom>
        h3. Heading
          </Typography>
          <Typography variant="h4" gutterBottom>
        h4. Heading
          </Typography>
          <Typography variant="h5" gutterBottom>
        h5. Heading
          </Typography>
          <Typography variant="h6" gutterBottom>
        h6. Heading
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
        subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
        blanditiis tenetur
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
        subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
        blanditiis tenetur
          </Typography>
          <Typography variant="body1" gutterBottom>
        body1. Use as the default for text, especially multi-line text. This is text that needs to be read, not just glanced at. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
        blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
        neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
        quasi quidem quibusdam.
          </Typography>
          <p>Body1 is the same as using a plain paragraph tag.</p>
          <Typography variant="body2" gutterBottom>
        body2. Use when multi-line text needs to be smaller. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos
        blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur,
        neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum
        quasi quidem quibusdam.
          </Typography>
          <Typography variant="button" display="block" gutterBottom>
        Text styled as button text (not actually a button)
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
        caption text, use under UI controls for additional details
          </Typography>
          <Typography variant="overline" display="block" gutterBottom>
        overline text
          </Typography>
          <Box px={5} pb={5} border={1}>
            <h4>Buttons</h4>
            <Stack spacing={2}>
              <Button>
            Base Button
              </Button>
              <Button color='emphasis'>
                <b>Emphasis Button</b>
              </Button>
              <p>Don&apos;t use the emphasis color as a background, it&apos;s too light for white text to be easily readable on top of it. Only use it as a foreground element, and make sure the text is large and/or bold.</p>
              <Box>
                <h5>Contained Buttons</h5>
                <p>Use contained buttons for the most important actions on a page or within a component.</p>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                    >
                  Primary
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='secondary'
                    >
                  Secondary
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='info'
                    >
                  Info
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='success'
                    >
                  Success
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='warning'
                    >
                  Warning
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='error'
                    >
                  Error
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <h5>Outlined Buttons</h5>
                <p>Use outlined buttons for secondary actions.</p>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant='outlined'
                      color='primary'
                    >
                  Primary
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='outlined'
                      color='secondary'
                    >
                  Secondary
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='outlined'
                      color='info'
                    >
                  Info
                    </Button>
                  </Grid>
              
                  <Grid item>
                    <Button
                      variant='outlined'
                      color='success'
                    >
                  Success
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='outlined'
                      color='warning'
                    >
                  Warning
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='outlined'
                      color='error'
                    >
                  Error
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <h5>Light and Dark</h5>
                <p>Light and dark should be used very rarely, only when two buttons of the same base color need to be near each other but be visually distinct.</p>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant='contained'
                      sx={{ backgroundColor: "primary.light" }}
                    >
                  Primary Light
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                    >
                  Primary
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      sx={{ backgroundColor: "primary.dark" }}
                    >
                  Primary Dark
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Stack>
          </Box>
          <Box px={5} pb={5} border={1}>
            <h4>Containers</h4>
            <p>
              This page has a large container. The content of a page should almost always be inside a large container, with a Paper element inside that.
            </p>
            <Container maxWidth="md">
              <Box border={1} padding={2} mb={3}>
              Medium Container, 900px wide
              </Box>
            </Container>
            <Container maxWidth="sm">
              <Box border={1} padding={2} mb={3}>
              Small Container, 600px wide
              </Box>
            </Container>
            <Container maxWidth="xs">
              <Box border={1} padding={2} mb={3}>
              Extra Small Container, 444px wide
              </Box>
            </Container>
          </Box>
        </Stack>
      </Paper>
    </Container>
  );
}

export default StyleGuide;
