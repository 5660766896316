import React from "react";
import {
  Button,
  Box,
  Container,
  Paper,
} from "@mui/material"
import Logo from "../../assets/Carfeine_logo_color.svg"
import theme from "../../theme";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const Auth = () => {
  const location = useLocation()
  let subPath = location.pathname.split("/").pop()
  // an auth path with no next path segment will default to the login route, but this will not be in the path -JJ
  if (subPath === "auth") subPath = "login"

  const navigate = useNavigate()

  return (
    <Box sx={{
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: theme.palette.background.dark,
      overflow: "auto",
      zIndex: "1"
    }}>
      <Container
        disableGutters={true}
        maxWidth="false"
        sx={{
          maxWidth: "460px",
          height: "100%",
          maxHeight: "none"
        }}
      >
        <Box
          p={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100%"
        >
          <Paper sx={{ padding: 4, width: "100%" }}>
            <Box
              display="flex"
              justifyContent="center"
              pb={3}
            >
              <Box
                component="img"
                src={Logo}
                alt="Carfeine Logo"
                sx={{
                  maxWidth: "340px"
                }}
              />
            </Box>
            <Outlet />
            <Box pt={3} display="flex">
              {subPath === "login" && (
                <Button
                  size="small"
                  sx={{ marginLeft: "-5px" }}
                  onClick={() => navigate("signup")}
                >
                  Create a new account
                </Button>
              )}
              {subPath !== "login" && (
                <Button
                  size="small"
                  sx={{ marginLeft: "-5px" }}
                  onClick={() => navigate("login")}
                >
                  Back to login
                </Button>
              )}
              {subPath !== "forgot" && (
                <Button
                  size="small"
                  sx={{ marginLeft: "auto", marginRight: "-5px" }}
                  onClick={() => navigate("forgot")}
                >
                  Reset Password
                </Button>
              )}
            </Box>
          </Paper>
        </Box>
      </Container>
    </Box>
  )
}

export default Auth