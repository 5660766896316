import React from "react";
import StandardPage from "../../components/StandardPage";
import Subscriptions from "./Subscriptions";

function SubscriptionsIndex() {
  return(
    <StandardPage title="Subscriptions">
      <Subscriptions/>
    </StandardPage>
  )
}

export default SubscriptionsIndex