import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { logout } from "../slices/authSlice"
import {
  toQueryString,
  mergeToKeyedById,
  toKeyedByID,
  isObjectWithValues
} from "../utils"

const API_URL = process.env.REACT_APP_API_URL

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  prepareHeaders: (headers, { endpoint, getState }) => {
    const token = getState().auth.token
    if (token) {
      headers.set("authorization", `Token ${token}`)
    }
    return headers
  }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  if (result.error && (
    [401].includes(result.error.status)
    || result.error.errorKey === "TOKEN_EXPIRED"
    || result.error?.data?.errorKey === "TOKEN_EXPIRED"
    || result.error === "Invalid or Inactive Token"
    || result.is_authenticated === false
  )) {
    // remove the invalid token
    api.dispatch(logout())
  }
  return result
}

const Tags = [
  "Auth",
  "Profile",
  "Users",
  "Orgs",
  "Memberships",
  "Notifications",
  "Relationships",
  "Rooftops",
  "Stripe",
  "Subscriptions",
  "News",
  "VINs"
]

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    ...Tags
  ], //TODO: use advanced tags with IDs for orgs per https://redux-toolkit.js.org/rtk-query/usage/automated-refetching
  endpoints: builder => ({
    login: builder.mutation({
      query: params => ({
        url: "/api/v3/user/login",
        method: "POST",
        body: params
      }),
      invalidatesTags: [...Tags]
    }),
    signup: builder.mutation({
      query: params => ({
        url: "/api/v3/user",
        method: "POST",
        body: params
      }),
      invalidatesTags: [...Tags]
    }),
    forgot: builder.mutation({
      query: params => ({
        url: "/api/v3/user/resetpassword",
        method: "POST",
        body: params
      })
      // Don't invalidate the tags here so we can see the results of trying to reset the password. -JJ
    }),
    newPassword: builder.mutation({
      query: params => ({
        url: "/api/v3/user/updatepassword",
        method: "POST",
        body: params
      }),
      invalidatesTags: ["Auth"]
    }),
    getUser: builder.query({
      query: () => ({
        url: "/api/v3/user"
      }),
      providesTags: ["Auth"]
    }),
    setUser: builder.mutation({
      query: params => ({
        url: "/api/v3/user",
        method: "POST",
        body: params
      })
    }),
    updateUser: builder.mutation({
      query: params => ({
        url: "/api/v3/user",
        method: "PATCH",
        body: params
      })
    }),
    getUserProfiles: builder.query({
      query: user_ids => ({
        url: `/api/v3/user/profile${user_ids ? "?" : ""}${toQueryString({ user_id: user_ids})}`
      }),
      providesTags: ["Profile"]
    }),
    /**
     * This will get the combined user and profile data for the currently logged in user.
     */
    getFullUser: builder.query({
      async queryFn(args, baseQ, extraOptions, fetchWBQ) {
        // Get the user first
        const userResult = await fetchWBQ("/api/v3/user")
        if (userResult.error) return { error: userResult.error }

        const user_id = userResult.data.id
        const profileResult = await fetchWBQ(`/api/v3/user/profile?user_id=${user_id}`)
        if (profileResult.error) return { error: profileResult.error }

        return {
          data: {
            ...userResult?.data ?? {},
            ...profileResult?.data?.results?.[0] ?? {}
          }
        }
      },
      providesTags: ["Profile"]
    }),
    setUserProfile: builder.mutation({
      query: params => ({
        url: "/api/v3/user/profile",
        method: "POST",
        body: params
      }),
      invalidatesTags: ["Profile"]
    }),
    updateUserProfile: builder.mutation({
      query: params => ({
        url: "/api/v3/user/profile",
        method: "PATCH",
        body: params
      }),
      invalidatesTags: ["Profile"]
    }),
    getNotifications: builder.query({
      // This is just a faked data call, replace it with a regular query when the endpoint is ready. -JJ
      query: uptodatetime => ({
        url: `/api/v3/customers/notifications${uptodatetime ? "?" : ""}${toQueryString({ uptodatetime: uptodatetime})}`
      }),
      providesTags: ["Notifications"]
    }),
    getNews: builder.query({
      query: uptodatetime => ({
        url: `/api/v3/customers/news${uptodatetime ? "?" : ""}${toQueryString({ uptodatetime: uptodatetime})}`
      }),
      providesTags: ["News"]
    }),
    getOrganizations: builder.query({
      query: org_ids => ({
        url: `/api/v3/organization${org_ids ? "?" : ""}${toQueryString({ organization_id: org_ids})}`
      }),
      providesTags: ["Orgs"]
    }),
    getOrganizationProfiles: builder.query({
      query: org_ids => ({
        url: `/api/v3/organization/profile${org_ids ? "?" : ""}${toQueryString({ organization_id: org_ids})}`
      }),
      providesTags: ["Orgs"]
    }),
    /**
     * Returns an object keyed by organization_id, with the combined data from the organization and organization/profile endpoints.
     * If no org_ids array is sent, returns the organizations the user owns, if any.
     * To get both an array of org_ids and the organizations the user owns, send an array of org_ids and true as the second parameter (alsoGetOwned)
     * @param {Object} args - The arguments for the hook
     * @param {string[]} args.org_ids - An array of organization_id values
     * @param {boolean} args.alsoGetOwned - Whether to also get owned.
     * @returns {Object} - An object keyed by organization_id
     */
    getFullOrganizations: builder.query({
      async queryFn(args = {}, baseQ, extraOptions, fetchWBQ) {
        const { org_ids, alsoGetOwned } = args
        const isValidIds = Array.isArray(org_ids) && org_ids.filter(i => i).length > 0

        const queryString = `${isValidIds ? `?${toQueryString({ organization_id: org_ids})}` : ""}`

        // Get the organizations owned by the user if requested and there are org_ids
        let ownedResults = []
        if (isValidIds && alsoGetOwned) {
          ownedResults = await fetchWBQ("/api/v3/organization")
        }
        if (ownedResults.error) return { error: ownedResults.error }

        // Get specified organizations.
        // Note: if no organizations are passed in, this will get the owned organizations.
        const orgResult = await fetchWBQ(`/api/v3/organization${queryString}`)
        if (orgResult.error) return { error: orgResult.error }
        const orgData = [
          ...(ownedResults.data ?? []),
          ...orgResult.data,
        ]
        let orgProfileData = []

        const fetched_ids = orgData.map(o => o.organization_id)
        if (fetched_ids.length) {
          const profileString = toQueryString({ organization_id: fetched_ids})
          const orgProfileResult = await fetchWBQ(`/api/v3/organization/profile?${profileString}`)
          if (orgProfileResult.error) return { error: orgProfileResult.error }
          orgProfileData = Array.isArray(orgProfileResult.data.results) ? orgProfileResult.data.results : []
        }
        return { data: mergeToKeyedById(orgData, orgProfileData, "organization_id") }
      },
      providesTags: ["Orgs"]
    }),
    setOrganization: builder.mutation({
      query: params => ({
        url: "/api/v3/organization",
        method: "POST",
        body: params
      }),
      invalidatesTags: ["Orgs"]
    }),
    setOrganizationProfile: builder.mutation({
      query: params => ({
        url: "/api/v3/organization/profile",
        method: "POST",
        body: params
      }),
      invalidatesTags: ["Orgs"]
    }),
    /**
     * This will set the organization, it's profile, and create a rooftop.
     * @param {Object} args - The arguments for the hook, should inlcude org, may include orgProfile and rooftop.
     */
    setFullOrganization: builder.mutation({
      async queryFn(args, baseQ, extraOptions, fetchWBQ) {
        const { org, orgProfile, rooftop, parent_id } = args
        // The args come in as formData. Rooftop may be null or false. - JJ
        let organization_id = org.get("organization_id")
        if (parent_id) org.set("parent_id", parent_id)

        // Post to the organization endpoint first to create the org if one doesn't exist
        const orgResult = await fetchWBQ(
          {
            url: "/api/v3/organization",
            method: organization_id ? "PATCH" : "POST",
            body: org
          }
        )
        if (orgResult.error) return { error: orgResult.error }

        organization_id = organization_id ?? orgResult.data.organization_id
        orgProfile.set("organization_id", organization_id)
        const organization_profile_id = orgProfile.get("organization_profile_id")
        const orgProfileResult = await fetchWBQ(
          {
            url: "/api/v3/organization/profile",
            method: organization_profile_id ? "PATCH" : "POST",
            body: orgProfile,
          }
        )
        if (orgProfileResult.error) return {
          error: {
            ...orgProfileResult.error,
            partialSuccess: orgResult.data
          }
        }

        let rooftopResult

        if (rooftop && rooftop.get("inventory_website")) {
          const isPatch = rooftop.get("isPatch")
          if (isPatch) rooftop.delete("isPatch")
          rooftop.set("organization_id", organization_id)

          rooftopResult = await fetchWBQ(
            {
              url: "/api/v3/organization/rooftop",
              method: isPatch ? "PATCH" : "POST",
              body: rooftop,
            }
          )
          if (rooftopResult.error) return {
            error: {
              ...rooftopResult.error,
              partialSuccess: {
                ...orgResult.data,
                ...orgProfileResult.data,
              }
            }
          }
        }

        return {
          data: {
            ...orgResult.data,
            ...orgProfileResult.data,
            rooftop: rooftopResult?.data
          }
        }
      },
      invalidatesTags: ["Orgs", "Rooftops", "Relationships"]
    }),
    updateOrganization: builder.mutation({
      query: params => ({
        url: "/api/v3/organization",
        method: "PATCH",
        body: params
      }),
      invalidatesTags: ["Orgs"]
    }),
    updateOrganizationProfile: builder.mutation({
      query: params => ({
        url: "/api/v3/organization/profile",
        method: "PATCH",
        body: params
      }),
      invalidatesTags: ["Orgs"]
    }),
    deleteOrganization: builder.mutation({
      query: params => ({
        url: "/api/v3/organization",
        method: "DELETE",
        body: params
      }),
      invalidatesTags: ["Orgs", "Memberships"]
    }),
    getOrganizationRelationshipsByOrg: builder.query({
      query: org_ids => ({
        url: `/api/v3/organization/relationship${org_ids ? "?" : ""}${toQueryString({ organization_id: org_ids})}`
      }),
      providesTags: ["Relationships"]
    }),
    getOrganizationRelationshipsByRel: builder.query({
      query: rel_ids => ({
        url: `/api/v3/organization/relationship${rel_ids ? "?" : ""}${toQueryString({ relationship_id: rel_ids})}`
      }),
      providesTags: ["Relationships"]
    }),
    getSubscriptions: builder.query ({
      query: subscription_ids => ({
        url: `/api/v3/stripe/subscriptions${subscription_ids ? "?" : ""}${toQueryString({ subscription_ids: subscription_ids })}`
      }),
      providesTags: ["Subscriptions"]
    }),
    getFullSubscriptions: builder.query({
      async queryFn(args = {}, baseQ, extraOptions, fetchWBQ) {
        const { subscription_ids } = args
        const subscriptionResults = await fetchWBQ(`/api/v3/stripe/subscriptions${subscription_ids ? "?" : ""}${toQueryString({ subscription_ids: subscription_ids })}`)
        if (subscriptionResults.error) return subscriptionResults

        // It's possible for subscriptions to have null values for the product or price IDs. -JJ
        const product_ids = [...new Set(subscriptionResults.data.map(s => s.stripe_product_id).filter(s => s))]
        const price_ids = [...new Set(subscriptionResults.data.map(s => s.stripe_price_id).filter(s => s))]

        const productPromise = product_ids.map(async product_id => {
          const productResult = await fetchWBQ(`/api/v3/stripe/products?${toQueryString({ product_id })}`)
          return productResult 
        })
        const productResults = await Promise.all(productPromise)
        const productError = productResults.find(r => r.error)
        if (productError) return productError

        const pricePromise = price_ids.map(async price_id => {
          const priceResult = await fetchWBQ(`/api/v3/stripe/prices?${toQueryString({ price_id })}`)
          return priceResult 
        })
        const priceResults = await Promise.all(pricePromise)
        const priceError = priceResults.find(r => r.error)
        if (priceError) return priceError

        const subData = subscriptionResults.data.map(s => ({
          ...s,
          product: productResults.find(p => p.data.id === s.stripe_product_id)?.data,
          price: priceResults.find(p => p.data.id === s.stripe_price_id)?.data,
        }))

        return { data: subData }
      },
      providesTags: ["Subscriptions, Stripe"]
    }),
    getOrganizationSubscriptions: builder.query ({
      query: () => ({
        url: "/api/v3/organization/subscription"
      }),
      providesTags: ["Subscriptions"]
    }),
    getFullOrgSubscriptions: builder.query({
      async queryFn(args, baseQ, extraOptions, fetchWBQ) {
        const subscriptionResults = await fetchWBQ("/api/v3/organization/subscription")
        if (subscriptionResults.error) return subscriptionResults

        // It's possible for subscriptions to have null values for the product or price IDs. -JJ
        const product_ids = [...new Set(subscriptionResults.data.map(s => s.stripe_product_id).filter(s => s))]
        const price_ids = [...new Set(subscriptionResults.data.map(s => s.stripe_price_id).filter(s => s))]

        const productPromise = product_ids.map(async product_id => {
          const productResult = await fetchWBQ(`/api/v3/stripe/products?${toQueryString({ product_id })}`)
          return productResult 
        })
        const productResults = await Promise.all(productPromise)
        const productError = productResults.find(r => r.error)
        if (productError) return productError

        const pricePromise = price_ids.map(async price_id => {
          const priceResult = await fetchWBQ(`/api/v3/stripe/prices?${toQueryString({ price_id })}`)
          return priceResult 
        })
        const priceResults = await Promise.all(pricePromise)
        const priceError = priceResults.find(r => r.error)
        if (priceError) return priceError

        const subData = subscriptionResults.data.map(s => ({
          ...s,
          product: productResults.find(p => p.data.id === s.stripe_product_id)?.data,
          price: priceResults.find(p => p.data.id === s.stripe_price_id)?.data,
        }))

        return { data: subData }
      },
      providesTags: ["Subscriptions, Stripe"]
    }),
    createSubscription: builder.mutation({
      query: params => ({
        url: "/api/v3/stripe/subscriptions",
        method: "POST",
        body: params
      }),
      transformResponse: (response) => response,
      invalidatesTags: ["Subscriptions"]
    }),
    deleteSubscription: builder.mutation ({
      query: params => ({
        url: "/api/v3/stripe/subscriptions",
        method : "DELETE",
        body: params
      }),
      invalidatesTags: ["Subscriptions"]
    }),
    getProduct: builder.query({
      query: product_id => ({
        url: `/api/v3/stripe/products${product_id ? "?" : ""}${toQueryString({ product_id: product_id })}`
      }),
      providesTags: ["Stripe"]
    }),
    getPrice: builder.query({
      query: price_id => ({
        url: `/api/v3/stripe/prices${price_id ? "?" : ""}${toQueryString({ price_id: price_id })}`
      }),
      providesTags: ["Stripe"]
    }),
    getOrganizationMembers: builder.query({
      query: organization_id => ({
        url: `/api/v3/organization/membership?${toQueryString({ organization_id: organization_id })}`
      }),
      providesTags: ["Memberships"]
    }),
    getMultiOrganizationMembers: builder.query({
      async queryFn(args, baseQ, extraOptions, fetchWBQ) {
        const { org_ids = [] } = args
        const memPromise = org_ids.map(async orgId => {
          const memResult = await fetchWBQ(`/api/v3/organization/membership?organization_id=${orgId}`)
          if (memResult.error) return { error: memResult.error }
          return {
            organization_id: orgId,
            members: memResult?.data?.results ?? []
          }
        })
        const results = await Promise.all(memPromise)
        return { data: toKeyedByID(results, "organization_id") }
      }
    }),
    getUserMembership: builder.query({
      query: user_id => ({
        url: `/api/v3/user/membership${user_id ? "?" : ""}${ toQueryString({ user_id })}`
      }),
      providesTags: ["Memberships"]
    }),
    updateUserMembership: builder.mutation({
      query: params => ({
        url: "/api/v3/user/membership",
        method: "PATCH",
        body: params
      }),
      invalidatesTags: ["Memberships"]
    }),
    deleteMembership: builder.mutation({
      query: params => ({
        url: "/api/v3/organization/membership",
        method: "DELETE",
        body: params
      }),
      invalidatesTags: ["Memberships"]
    }),
    updateAcceptMembership: builder.query({
      // It's weird that this is a GET, but that's how the backend is. -JJ
      query: membership_id => ({
        url: `/api/v3/accept/membership?id=${membership_id}`
      }),
    }),
    getOrganizationRoles: builder.query({
      query: organization_id => ({
        url: `/api/v3/organization/roles${organization_id ? "?" : ""}${toQueryString({ organization_id: organization_id })}`
      }),
      providesTags: ["Orgs"]
    }),
    setOrganizationMember: builder.mutation({
      query: params => ({
        url: "/api/v3/organization/membership",
        method: "POST",
        body: params
      }),
      invalidatesTags: ["Memberships"]
    }),
    updateOrganizationMember: builder.mutation({
      query: params => ({
        url: "/api/v3/organization/membership",
        method: "PATCH",
        body: params
      }),
      invalidatesTags: ["Memberships"]
    }),
    getRooftops: builder.query({
      query: params => ({
        url: `/api/v3/organization/rooftop${isObjectWithValues(params) ? "?" : ""}${toQueryString(params)}`
      }),
      providesTags: ["Rooftops"]
    }),
    /**
     * Gets a list of rooftops a user has access to, by owning an org or by memberships.
     * I don't think we need this, but I'm not sure? I'm leaving it in until we confirm calling
     * /rooftops with no parameters does the same thing as the code below. -JJ
     */
    getUserRooftops: builder.query({
      async queryFn(args = {}, baseQ, extraOptions, fetchWBQ) {
        // Get the organizations owned by the user if requested and there are org_ids
        const ownedResults = await fetchWBQ("/api/v3/organization")
        if (ownedResults.error) return { error: ownedResults.error }

        let org_ids = ownedResults?.data.map(o => o.organization_id)

        // Get the user's memberships
        const membResults = await fetchWBQ("/api/v3/user/membership")
        if (membResults.error) return { error: membResults.error}

        const mem_ids = membResults?.data?.results
          .filter(r => r.active && r?.permissions.find(p => p.application === "revin" && p.view))
          .map(m => m.organization_id)

        org_ids = [...org_ids, ...mem_ids]
        let rooftops = []

        await Promise.all(org_ids.map(async org_id => {
          const result = await fetchWBQ(`/api/v3/organization/rooftop?organization_id=${org_id}`)
          if (result.error) {
            return { error: result.error }
          } else if (Array.isArray(result.data)) {
            rooftops = [...rooftops, ...result.data]
          }
        }))

        return rooftops
      },
      providesTags: ["Rooftops"]
    }),
    getReVINVehicles: builder.query({
      query: params => ({
        url: `/revin/vehicles/?${toQueryString(params)}`
      }),
      providesTags: ["VINs"]
    }),
    getReVINCompare: builder.query({
      query: params => ({
        url: `/revin/v3/compare?${toQueryString(params)}`
      }),
      providesTags: ["VINs"]
    }),
    getReVINDetails: builder.query({
      query: params => ({
        url: `/revin/v3/detail?${toQueryString(params)}`
      }),
      providesTags: ["VINs"]
    }),
    getReVINCoverage: builder.query({
      // Expects a rooftop_id parameter with an array of IDs. -JJ
      query: params => ({
        url: `/revin/coverage?${toQueryString(params)}`
      }),
      providesTags: ["VINs"]
    }),
    /**
     * Expects an array of vehicle objects and a rooftop ID. The minimum data is VIN for each one.
     * @param {string} rooftop_id - the ID of the rooftop the vehicles are being added to.
     * @param {Object[]} vehicles - an array of vehicles
     * @param {string} vehicles[].vin - the only required value in each vehicle
     * @param {string} vehicles[].body_style
     * @param {number} vehicles[]cylinders
     * @param {string} vehicles[].description
     * @param {number} vehicles[].displacement
     * @param {number} vehicles[].doors
     * @param {string} vehicles[].drivetrain
     * @param {string} vehicles[].engine
     * @param {string} vehicles[].exterior_color
     * @param {string} vehicles[].fuel_economy_city
     * @param {string} vehicles[].fuel_economy_highway
     * @param {string} vehicles[].fuel_type
     * @param {string} vehicles[].grouped_exterior_color
     * @param {string} vehicles[].grouped_interior_color
     * @param {string} vehicles[].interior_color
     * @param {string} vehicles[].make
     * @param {number} vehicles[].mileage
     * @param {string} vehicles[].model
     * @param {number} vehicles[].msrp
     * @param {number} vehicles[].sale_price
     * @param {string} vehicles[].short_description
     * @param {string} vehicles[].short_title
     * @param {string} vehicles[].state_of_vehicle
     * @param {string} vehicles[].title
     * @param {string} vehicles[].transmission_type
     * @param {string} vehicles[].trim
     * @param {number} vehicles[].year
     */
    createREVIN: builder.mutation({
      query: params => ({
        url: "/revin/v3/describe",
        method: "POST",
        body: params
      }),
      invalidatesTags: ["VINs"]
    }),
    getVehicleCertification: builder.query({
      query: vehicle_id => ({
        url: `/revin/certification/${vehicle_id}`
      })
    })
  })
})

export const {
  useLoginMutation,
  useSignupMutation,
  useForgotMutation,
  useNewPasswordMutation,
  useGetUserQuery,
  useSetUserMutation,
  useUpdateUserMutation,
  useGetUserProfilesQuery,
  useSetUserProfileMutation,
  useGetFullUserQuery,
  useUpdateUserProfileMutation,
  useGetNotificationsQuery,
  useGetNewsQuery,
  useGetOrganizationsQuery,
  useGetOrganizationMembersQuery,
  useGetMultiOrganizationMembersQuery,
  useGetSubscriptionsQuery,
  useGetFullSubscriptionsQuery,
  useGetOrganizationSubscriptionsQuery,
  useGetFullOrgSubscriptionsQuery,
  useGetOrganizationProfilesQuery,
  useGetFullOrganizationsQuery,
  useSetOrganizationMutation,
  useUpdateOrganizationMutation,
  useSetOrganizationProfileMutation,
  useUpdateOrganizationProfileMutation,
  useSetFullOrganizationMutation,
  useDeleteOrganizationMutation,
  useDeleteMembershipMutation,
  useGetOrganizationRelationshipsByOrgQuery,
  useGetOrganizationRelationshipsByRelQuery,
  useGetUserMembershipQuery,
  useUpdateUserMembershipMutation,
  useUpdateAcceptMembershipQuery,
  useGetOrganizationRolesQuery,
  useCreateSubscriptionMutation,
  useGetProductQuery,
  useSetOrganizationMemberMutation,
  useUpdateOrganizationMemberMutation,
  useGetPriceQuery,
  useGetRooftopsQuery,
  useGetUserRooftopsQuery,
  useGetReVINVehiclesQuery,
  useGetReVINCompareQuery,
  useGetReVINDetailsQuery,
  useGetReVINCoverageQuery,
  useCreateREVINMutation,
  useGetVehicleCertificationQuery
} = apiSlice
