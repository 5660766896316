import React from "react";
import {
  Route,
  useLocation
} from "react-router-dom";
import { useSelector } from "react-redux"
import "./css/Base.scss"
import ProtectedRoute from "./components/ProtectedRoute";
import Home from "./pages/home/Home";
import Auth from "./pages/auth/Auth"
import StyleGuide from "./pages/Styleguide"
import Login from "./pages/auth/Login"
import Signup from "./pages/auth/Signup"
import ForgotPassword from "./pages/auth/ForgotPassword"
import Created from "./pages/auth/Created"
import ResetPassword from "./pages/auth/ResetPassword"
import Notifications from "./pages/notifications/Notifications"
import Organizations from "./pages/organizations/Organizations"
import Organization from "./pages/organizations/Organization"
import Profile from "./pages/profile/ProfileLoader"
import News from "./pages/news/News"
import Revin from "./pages/revin/Index"
import Certification from "./pages/revin/Certification"
import NotFound from "./pages/NotFound"
import Header from "./components/header"
import Footer from "./components/Footer"
import {
  useGetFullUserQuery,
  useGetSubscriptionsQuery,
  useGetUserMembershipQuery
} from "./services/apiSlice"
import ErrorsContained from "./components/ErrorContained"
//import { parseErrors } from "./utils"
import SubscriptionsIndex from "./pages/subscriptions/SubscriptionsIndex";
import UpdateEmail from "./modals/UpdateEmail"
import UpdatePassword from "./modals/UpdatePassword"
import EditOrganization from "./modals/editOrganization/EditOrganizationWrapper"
import TermsOfService from "./pages/TermsOfService"
import Contact from "./pages/Contact"

function App(props) {
  const { SentryRoutes } = props

  // If we have a token, run the GET user here so it's saved to the state and available to the rest of the app. -JJ
  const token = useSelector(state => state.auth.token)
  const { error: fullUserError, isError: isFullUserError } = useGetFullUserQuery("", { skip: !token })
  // Turn off error checking for now, we still have some race conditions -JJ
  const disableErrorChecking = true

  const location = useLocation()
  // backgroundLocation is manually inserted when a modal is navigated to. -JJ
  const backgroundLocation = location?.state?.backgroundLocation

  const {
    data: userMembData,
    isError: isUserMembDataError,
    error: userMembError
  } = useGetUserMembershipQuery("", { skip: !token })

  const {
    data: subscriptionData,
    isError: isSubscriptionDataError,
    error: subscriptionDataError
  } = useGetSubscriptionsQuery("", { skip: !token })

  const isError = isFullUserError || isUserMembDataError || isSubscriptionDataError

  const error = [fullUserError, userMembError, subscriptionDataError]

  const currentUserPermissions = userMembData?.results?.filter(s => s.accepted && s.active)
  const revinPermissions = currentUserPermissions
    ?.filter(s => s.permissions.find(p => p.application === "revin" && (p.view || p.edit || p.billing)))
    ?? []
  const marketPulsePermissions = currentUserPermissions?.filter(s => s.permissions.find(p => p.application === "marketpulse" && (p.view || p.edit || p.billing))) ?? []
  const caraPermissions = currentUserPermissions?.filter(s => s.permissions.find(p => p.application === "cara" && (p.view || p.edit || p.billing))) ?? []

  if (subscriptionData) {
    subscriptionData.forEach(s => {
      if (s.application === "revin" && s.status === "active") revinPermissions.push(s)
      if (s.application === "marketpulse" && s.status === "active") marketPulsePermissions.push(s)
      if (s.application === "cara" && s.status === "active") caraPermissions.push(s)
    })
  }

  const subscriptionsOverLimit = subscriptionData?.filter(s => s.used >= s.daily_limit)

  return (
    <>
      <header>
        <Header 
          revinPermissions={revinPermissions} 
          marketPulsePermissions={marketPulsePermissions} 
          caraPermissions={caraPermissions}
          subscriptionsOverLimit={subscriptionsOverLimit}
        />
      </header>
      <main>
        {isError && !disableErrorChecking ? (
          <ErrorsContained errors={error}/>
        ) : isError && error.status === "403" ? (
          <ErrorsContained errors={error}/>
        ) : (
          <SentryRoutes location={backgroundLocation ?? location}>
            <Route path="auth" element={<Auth />}>
              <Route path="signup" element={<Signup />} />
              <Route path="forgot" element={<ForgotPassword />} />
              <Route path="created" element={<Created />} />
              <Route path="reset" element={<ResetPassword />} />
              <Route path="login" element={<Login />} />
              <Route index element={<Login />} />
            </Route>
            <Route path="terms-of-service" element={<TermsOfService />} />
            <Route path="contact" element={<Contact />} />
            <Route path="styleguide" element={<StyleGuide />} />
            <Route path="revin/certification/:vehicle" element={<Certification />} />
            <Route path="/" element={<ProtectedRoute />}>
              <Route
                path="organizations"
                element={<Organizations subscriptionsOverLimit={subscriptionsOverLimit}/>}
              />
              <Route
                path="organizations/:organization_id"
                element={<Organization subscriptionsOverLimit={subscriptionsOverLimit}/>}
              />
              <Route
                path="organizations/:organization_id/:tab"
                element={<Organization subscriptionsOverLimit={subscriptionsOverLimit}/>}
              />
              <Route path="notifications" element={<Notifications />} />
              <Route path="subscriptions" element={<SubscriptionsIndex/>} />
              <Route path="news" element={<News />} />
              <Route path="profile/" element={<Profile />} />
              <Route path="revin" element={<Revin />} />
              <Route path="revin/:tabOrVIN" element={<Revin />} />
              <Route index element={<Home 
                revinPermissions={revinPermissions} 
                marketPulsePermissions={marketPulsePermissions} 
                caraPermissions={caraPermissions}
              />} />
            </Route>
            <Route index path="*" element={<NotFound />} />
          </SentryRoutes>
        )}
        {!isError && backgroundLocation && (
          <SentryRoutes>
            <Route path="updateemail" element={<UpdateEmail />} />
            <Route path="updatepassword" element={<UpdatePassword />} />
            <Route path="editorganization" element={<EditOrganization />} />
            <Route path="editorganization/:organization_id" element={<EditOrganization />} />
          </SentryRoutes>
        )}
      </main>
      <footer>
        <Footer />
      </footer>
    </>
  );
}

export default App;
