import React from "react";
import { useNavigate  } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@mui/material";
import { useGetReVINVehiclesQuery } from "../../services/apiSlice";
import { usdFormat, outputDateFormat } from "../../utils";
import SpinnerContained from "../../components/SpinnerContained";
import ErrorsContained from "../../components/ErrorContained";

function Dashboard(props) {
  const { rooftop_id } = props
  const navigate = useNavigate()

  const {
    data: vehiclesData = [],
    isFetching: isVehiclesLoading,
    error: vehiclesError,
    isError: isVehiclesError
  } = useGetReVINVehiclesQuery({
    rooftop_id,
    limit: 20,
    ordering: "-last_update_datetime"
  })

  return (
    <>
      {isVehiclesLoading ? (
        <SpinnerContained />
      ) : isVehiclesError ? (
        <ErrorsContained errors={vehiclesError} />
      ) : (
        <>
          <Box display="flex" flex-grow={1} pb={1}>
            <Typography variant="h4">
              Most recent VINs
            </Typography>
            <Button
              variant="text"
              size="small"
              onClick={ () => navigate(`/revin/all?r=${rooftop_id}`)}
              sx={{ml: "auto"}}
            >
              Show All
            </Button>
          </Box>

          <TableContainer sx={{borderTop:1, borderTopColor:"rgba(224,224,224,1)"}}>
            <Table>
              <colgroup>
                <col style={{width:200}}></col>
                <col></col>
                <col style={{width:180}}></col>
                <col style={{width:200}}></col>
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>VIN</TableCell>
                  <TableCell>Vehicle</TableCell>
                  <TableCell>MSRP</TableCell>
                  <TableCell>Timestamp</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {vehiclesData.results.map((row) => (
                  <TableRow key={row.vin} sx={{ verticalAlign:"top", cursor: "pointer" }} onClick={() => navigate(`/revin/${row.vin}?r=${rooftop_id}`)}>
                    <TableCell>{row.vin}</TableCell>
                    <TableCell>{[row.year, row.make, row.model].filter(Boolean).join(" ")}</TableCell>
                    <TableCell>
                      { row.msrp != null ? usdFormat(row.msrp) : "" }
                    </TableCell>
                    <TableCell>{outputDateFormat(row.last_update_datetime, "MM/DD/YYYY, h:mm A")}</TableCell>
                  </TableRow>
                ))}
                {vehiclesData.results.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography textAlign="center">
                        No vehicles have been enhanced by reVIN yet at this rooftop.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  )
}

export default Dashboard