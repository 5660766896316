import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from "@mui/material"
import { useNavigate, useLocation } from "react-router-dom"
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  useSetUserProfileMutation,
  useUpdateUserProfileMutation
} from "../../services/apiSlice"
import ImageUploader from "../../components/ImageUploader";
import { parseErrors, isObjectWithValues } from "../../utils"
import { validate } from "../../validate";
import theme from "../../theme"

function EditProfile(props) {
  const navigate = useNavigate()
  const location = useLocation()

  const { profile } = props ?? {}

  const postOrPatchProfile = Object.values(profile).length ? useUpdateUserProfileMutation : useSetUserProfileMutation

  const [postProfile, {
    isFetching: isLoading,
    isSuccess,
    isError,
    error
  }] = postOrPatchProfile()

  const [first_name, setFirstName] = useState(profile?.first_name ?? "")
  const [last_name, setLastName] = useState(profile?.last_name ?? "")
  const [timezone, setTimezone] = useState(profile?.timezone ?? "America/Chicago")
  const [imageURL, setImageURL] = useState(profile.avatar ?? "")
  const [imageFile, setImageFile] = useState()
  const [imageDeleted, setImageDeleted] = useState()
  const [errors, setErrors] = useState({})

  const onSelectTimezone = e => {
    setTimezone(e.target.value)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const errs = validate([
      ["first_name", "name", first_name],
      ["last_name", "name", last_name],
    ])
    setErrors({...errs})

    if (!isObjectWithValues(errs)) {
      const formData = new FormData()
      formData.append("first_name", first_name)
      formData.append("last_name", last_name)
      formData.append("timezone", timezone)
      // When we load the image from the server, it will have a URL but not a file, don't upload anything. - JJ
      if (imageFile) {
        formData.append("avatar", imageFile, imageFile.name)
      // Send an empty string if the user has deleted the image. - JJ  
      } else if (imageDeleted) {
        formData.append("avatar", "")
      }
      postProfile(formData)
    }
  }

  const isXSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid
            item xs={12}
            sm="auto"
            md={4}
            display="flex"
            justifyContent={isXSmall ? "center" : "flex-start"}
          >
            <ImageUploader
              imageURL={imageURL}
              setImageURL={setImageURL}
              setImageFile={setImageFile}
              clearImageFile={() => setImageDeleted(true)}
              altTag="Your Profile Image"
            />
          </Grid>
          <Grid item xs={12} sm md={8}>
            <Stack spacing={2}>
              <TextField
                width="100%"
                label="First Name"
                value={first_name}
                onChange={e => setFirstName(e.target.value)}
                error={!!errors.first_name}
                helperText={errors.first_name}
              />
              <TextField
                width="100%"
                label="Last Name"
                value={last_name}
                onChange={e => setLastName(e.target.value)}
                error={!!errors.last_name}
                helperText={errors.last_name}
              />
              <FormControl fullWidth>
                <InputLabel id="TimezoneSelectLabel">Timezone</InputLabel>
                <Select
                  labelId="TimezoneSelectLabel"
                  id="TimezoneSelect"
                  value={timezone}
                  label="Timezone"
                  onChange={onSelectTimezone}
                >
                  <MenuItem value="America/Adak">America/Adak</MenuItem>
                  <MenuItem value="America/Anchorage">America/Anchorage</MenuItem>
                  <MenuItem value="America/Cancun">America/Cancun</MenuItem>
                  <MenuItem value="America/Chicago">America/Chicago</MenuItem>
                  <MenuItem value="America/Chihuahua">America/Chihuahua</MenuItem>
                  <MenuItem value="America/Denver">America/Denver</MenuItem>
                  <MenuItem value="America/Edmonton">America/Edmonton</MenuItem>
                  <MenuItem value="America/Halifax">America/Halifax</MenuItem>
                  <MenuItem value="America/Los_Angeles">America/Los_Angeles</MenuItem>
                  <MenuItem value="America/Mazatlan">America/Mazatlan</MenuItem>
                  <MenuItem value="America/Merida">America/Merida</MenuItem>
                  <MenuItem value="America/Mexico_City">America/Mexico_City</MenuItem>
                  <MenuItem value="America/New_York">America/New_York</MenuItem>
                  <MenuItem value="America/Phoenix">America/Phoenix</MenuItem>
                  <MenuItem value="America/Regina">America/Regina</MenuItem>
                  <MenuItem value="America/St_Johns">America/St_Johns</MenuItem>
                  <MenuItem value="America/Tijuana">America/Tijuana</MenuItem>
                  <MenuItem value="America/Toronto">America/Toronto</MenuItem>
                  <MenuItem value="Pacific/Honolulu">Pacific/Honolulu</MenuItem>
                  <MenuItem value="America/Vancouver">America/Vancouver</MenuItem>
                  <MenuItem value="America/Winnipeg">America/Winnipeg</MenuItem>
                </Select>
              </FormControl>
              <Box>
                <Typography variant="note">
                      Email Address
                </Typography>
                <Typography>
                  {profile.email}
                </Typography>        
                <Box display="flex" mt={1}>
                  <Button
                    variant="outlined"
                    disabled={isLoading}
                    onClick={() => {
                      navigate("/updateemail", {
                        state: { backgroundLocation: location }
                      })
                    }}
                    sx={{ ml: "auto" }}
                  >
                        Change Email
                  </Button>
                </Box>
              </Box>
              <Box>
                <Typography variant="note">
                      Password
                </Typography>
                <Typography>
                      (hidden)
                </Typography>        
                <Box display="flex" mt={1}>
                  <Button
                    variant="outlined"
                    disabled={isLoading}
                    onClick={() => {
                      navigate("/updatepassword", {
                        state: { backgroundLocation: location }
                      })
                    }}
                    sx={{ ml: "auto" }}
                  >
                        Reset Password
                  </Button>
                </Box>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {isError && (
              <Alert severity="error">
                {parseErrors(error)}
              </Alert>
            )}
            {isSuccess && (
              <Alert severity="success">
                    Saved
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" mt={1}>
              <Button
                color="primary" 
                type="submit"
                variant="contained"
                disabled={isLoading}
                sx={{ ml: "auto" }}
              >
                {isLoading ? "Saving..." : "Save"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </>        
  );
}

export default EditProfile;
