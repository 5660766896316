import React, { createRef } from "react";
import {
  Avatar,
  Box,
  Button
} from "@mui/material"
import {
  CloudUpload,
  Clear,
} from "@mui/icons-material";
import theme from "../theme"

function ImageUploader(props) {
  const {
    imageURL, // current state of the image, should be from useState
    setImageURL, // must be a useState set function, or other way to set the current image value
    setImageFile, // must be a useState set function, or other way to set the current image value
    clearImageFile, // optional function to use when the image is cleared
    altTag = "",
    fallbackIcon = null,
    width="240px",
  } = props

  const imageInputRef = createRef(null)

  const clearAndSetImageURL = newImageURL => {
    if (imageURL) {
      URL.revokeObjectURL(imageURL)
      imageInputRef.current.value = null
    }
    setImageURL(newImageURL)
  }

  const handleImageChange = e => {
    const newImage = e.target?.files?.[0];
    setImageFile(newImage ?? null)
    clearAndSetImageURL(newImage ? URL.createObjectURL(newImage) : null);
  };

  const onClickRemoveImage = e => {
    e.preventDefault()
    setImageFile(null)
    clearAndSetImageURL(null)
    if (clearImageFile) clearImageFile()
  }

  return (
    <Box
      width={width}
      height={width}
      mb={4}
    >
      <Avatar
        alt={altTag}
        src={imageURL}
        variant="rounded"
        sx={{ width: width, height: width, border: `1px solid ${theme.palette.background.light}`}}
        imgProps={{ sx: { objectFit: "contain" } }}
      >
        {fallbackIcon}
      </Avatar>
      <input
        ref={imageInputRef}
        accept="image/*"
        hidden
        id="avatar-image-upload"
        type="file"
        onChange={handleImageChange}
      />
      <Box sx={{
        position: "relative",
        top: "-32px",
        right: "-16px",
        display: "flex",
      }}>
        <label
          htmlFor="avatar-image-upload"
          style={{ marginLeft: "auto" }}
        >
          <Button
            variant="contained"
            color="primary"
            component="span"
            sx={{
              borderRadius: "50%",
              width: "48x",
              minWidth: "48px",
              height: "48px",
              padding: 0
            }}
          >
            <CloudUpload fontSize="medium"/>
          </Button>
        </label>
        {imageURL && (
          <Button
            variant="contained"
            color="warning"
            onClick={onClickRemoveImage}
            sx={{
              borderRadius: "50%",
              width: "48x",
              minWidth: "48px",
              height: "48px",
              padding: 0,
              marginLeft: 1
            }}
          >
            <Clear />
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default ImageUploader;
