import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from "@mui/material"
import React, { useState, useMemo } from "react"
import CloseIcon from "@mui/icons-material/Close"
import { useEffect } from "react"
import { parseErrors, isObjectWithValues } from "../../utils"
import { validate } from "../../validate"

function EditMembership (props) {
  const {
    member,
    handleClose,
    organizationRoles,
    getPermissionsForApplication,
    onSave,
    open = false,
    pageHasErrors,
    pageErrors,
  } = props

  const [email, setEmail] = useState("")
  const [title, setTitle] = useState("")
  const [role, setRole] = useState()
  const [errors, setErrors] = useState({})

  const roleId = useMemo(() => {
    const roleFound = organizationRoles.results.find(r => r.name === role)
    return roleFound ? roleFound.id : undefined
  }, [role, organizationRoles.results])

  useEffect(() => {
    if (member) { 
      setTitle(member.descriptionTitle)
      setRole(member.descriptionRole)
    }
    else {
      setTitle("")
      setRole("")
    }
  }, [member])

  const clearState = () => {
    setEmail("")
    setTitle("")
    setRole("")
    setErrors({})
  }

  const onClickSave = () => {
    let errs = validate([
      ...(!member ? [["email", "email", email]] : []),
      ["title", "name", title, "You must provide a title between 2 and 255 characters."]
    ])
    if (!roleId) errs = {...errs, role: "You must assign a role."}
    setErrors({...errs})
    if (!isObjectWithValues(errs)) {
      onSave(email, roleId, title, clearState)
    }
  }

  const onClickClose = () => {
    clearState()
    handleClose()
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      PaperProps={{ sx: { padding: "8px 0", minHeight: "300px" } }}
    >
      <DialogTitle>
        {member ? "Edit a member" : "Invite a New Member"}
        <IconButton
          aria-label="close"
          onClick={onClickClose}
          sx={{
            position: "absolute",
            right: "16px",
            top: "16px"
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <Stack sx={{ padding: "20px", flexGrow: 1 }} spacing={3}>
          {!member && 
            <TextField
              label="Email Address"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              error={!!errors.email}
              helperText={errors.email}
            />
          }
          <TextField
            label="Title"
            type="text"
            value={title}
            onChange={e => setTitle(e.target.value)}
            error={!!errors.title}
            helperText={errors.title}
          />
          {organizationRoles &&
            <Grid container justifyContent="space-between">
              <Grid item sm={6} style={{ textAlign: "right" }}>
                <Typography fontWeight="bold">Organization</Typography>
              </Grid>
              <Grid item xs style={{ textAlign: "center" }}>
                <Typography fontWeight="bold">reVIN</Typography>
              </Grid>
              <Grid item xs style={{ textAlign: "center" }}>
                <Typography fontWeight="bold">MarketPulse</Typography>
              </Grid>
              <Grid item xs mr={1} style={{ textAlign: "center" }}>
                <Typography fontWeight="bold">Cara</Typography>
              </Grid>
            </Grid>
          }
          <hr />
          {organizationRoles && 
            <Grid container justifyContent="space-between" style={{ marginTop: "0.5em" }}>
              <Grid item sm={5}>
                <FormControl
                  error={!!errors.role}
                >
                  <RadioGroup 
                    onChange={e => setRole(e.target.value)}
                    value={role || ""}
                  >
                    {organizationRoles?.results?.map(or =>
                      (<FormControlLabel key={or.id} value={or.name} control={<Radio />} label={or.name} />)
                    )}
                  </RadioGroup>
                  <FormHelperText>{errors.role}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={7} >
                {organizationRoles?.results.map(or =>
                  <Grid container mt={1.5} key={or.id}>
                    <Grid item xs>
                      <Typography >{getPermissionsForApplication(or, "organization")}</Typography>
                    </Grid>
                    <Grid item xs mr={5} style={{ textAlign: "center" }}>
                      <Typography >{getPermissionsForApplication(or, "revin")}</Typography>
                    </Grid>
                    <Grid item xs style={{ textAlign: "center" }}>
                      <Typography >{getPermissionsForApplication(or, "marketpulse")}</Typography>
                    </Grid>
                    <Grid item xs mr={1} style={{ textAlign: "right" }}>
                      <Typography >{getPermissionsForApplication(or, "cara")}</Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          }
          {organizationRoles && 
            <Typography align="right">*can change payment details</Typography>
          }
          <Box flexGrow={1} display="flex" flexDirection="column">
            <Box sx={{marginTop: "auto"}}>
              {pageHasErrors && (
                <Alert severity="warning" sx={{ marginBottom: "16px" }}>
                  {parseErrors(pageErrors)}
                </Alert>
              )}
              <Grid container 
                direction="row"
                justifyContent="flex-end"
                columnSpacing={1}
              >
                <Grid item>
                  <Button variant="outlined" onClick={onClickClose}>cancel</Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" onClick={onClickSave}>save</Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

export default EditMembership