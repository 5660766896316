import React from "react";
import { useGetReVINCoverageQuery } from "../../services/apiSlice";
import SpinnerContained from "../../components/SpinnerContained";
import ErrorsContained from "../../components/ErrorContained";
import CoverageChart from "./CoverageChart";

function Coverage(props) {
  const { rooftop_id } = props

  const {
    data: coverageData = [],
    isFetching: isCoverageFetching,
    error: coverageError,
    isError: isCoverageError
  } = useGetReVINCoverageQuery({
    rooftop_id
  })

  return (
    <>
      {isCoverageFetching ? (
        <SpinnerContained />
      ) : isCoverageError ? (
        <ErrorsContained errors={coverageError} />
      ) : (
        <CoverageChart
          coverageData={coverageData}
          rooftop_id={rooftop_id}
        />
      )}
    </>
  )
}

export default Coverage