import React, { useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography
} from "@mui/material";
import ReactEChartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import {
  BarChart,
} from "echarts/charts";
import {
  GridComponent,
  GraphicComponent,
  TooltipComponent,
  TitleComponent,
} from "echarts/components";
import {
  CanvasRenderer,
} from "echarts/renderers";
import useMediaQuery from "@mui/material/useMediaQuery";
import { prettifyDataString } from "../../utils"
import theme from "../../theme"

echarts.use([
  TitleComponent,
  TooltipComponent,
  GraphicComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);

const drillCategories = [
  {
    label: "State",
    key: "state_of_vehicle",
    title:"All VINs"
  },
  {
    label: "Make",
    key: "make",
    title: "By Make"
  },
  {
    label: "Year",
    key: "year",
    title: "By Year"
  }
]

const seriesOptions = {
  type: "bar",
  barGap: "-100%",
  emphasis: {
    disabled: true
  },
  universalTransition: {
    enabled: true,
    divideShape: "clone"
  }
}

const createOptions = params => {
  const {
    drillCategory,
    filterKeys = [],
    data
  } = params

  const { key, title } = drillCategory

  let filteredData = [...data]

  // If filterKeys were passed in, filter each level of the data, to drill down to the correct subset. -JJ
  if (filterKeys.length) {
    filteredData = filterKeys.reduce((acc, cv) => {
      return acc.filter(d => d[cv.key] === cv.value)
    }, [...data])
  }

  // We can have duplicate keys in the data, remove them to create the columns. -JJ
  const xData = [...new Set(filteredData.map(d => d[key]))].sort()

  // We have one object in the data for every combination of categories (state/year/make), merge the values at the current level. -JJ
  const seriesData = xData.map(x => {
    return filteredData.filter(d => d[key] === x)
      .reduce((acc, cv) => {
        return {
          precise: acc.precise + cv.precise,
          basic: acc.basic + cv.basic,
          no_coverage: acc.no_coverage + cv.no_coverage
        }
      }, {
        precise: 0,
        basic: 0,
        no_coverage: 0
      })
  })

  return {
    xAxis: {
      data: xData,
      axisLabel: {
        show: true,
        interval: 0,
        rotate: xData.length > 6 ? 70 : 0,
      },
      axisTick: {
        show: xData.length > 6
      }
    },
    yAxis: {
      type: "value"
    },
    title: {
      show: true,
      text: "VIN Coverage",
      left: "center",
      subtext: `${title}${filterKeys.length ? ` | ${filterKeys.map(f => prettifyDataString({str: f.value, splitChar: " "})).join(" | ")}` : ""}`,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params) => {
        let total = params[0]?.value ?? 0
        let basic = params[1]?.value ?? 0
        let precise = params[2]?.value ?? 0

        const covered = precise + basic
        const perc_covered = covered === 0 ? 0 : Number.parseFloat(covered / total * 100).toFixed(1)

        return `<strong>${params[0].axisValueLabel} Coverage: ${perc_covered}%</strong><br>
                <hr/>
                Total: ${total}<br>
                Precise: ${precise}<br>
                Basic: ${basic}<br>
                Uncovered: ${total - precise - basic}`
      }
    },
    series: [
      {
        ...seriesOptions,
        name: "Total",
        color: ["#A8B7D0"],
        data: seriesData.map((s, i) => ({
          value: s.precise + s.basic + s.no_coverage,
          groupId: xData[i]
        }))
      },
      {
        ...seriesOptions,
        name: "Basic",
        stack: "covered",
        color: ["#75A2EB"],
        label: {
          show: seriesData.filter(s => s.basic).length > 0 ? true : false,
          position: "inside"
        },
        data: seriesData.map((s, i) => ({
          value: s.basic,
          groupId: xData[i]
        }))
      },
      {
        ...seriesOptions,
        name: "Precise",
        stack: "covered",
        color: ["#005BEA"],
        label: {
          show: seriesData.filter(s => s.precise).length > 0 ? true : false,
          position: "inside"
        },
        data: seriesData.map((s, i) => ({
          value: s.precise,
          groupId: xData[i]
        }))
      }
    ]
  }
}

const CoverageChart = (props) => {
  const { coverageData, rooftop_id } = props
  const {
    precise,
    basic,
    no_coverage,
    results = []
  } = coverageData

  const [drillLevel, setDrillLevel] = useState(0)
  const [filterKeys, setFilterKeys] = useState([])

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  const onChartClick = params => {
    if (params?.componentType === "series") {
      if (drillLevel < drillCategories.length - 1) {
        const categoryKey = drillCategories[drillLevel].key
        const categoryValue = params.data.groupId
        setFilterKeys([...filterKeys, { key: categoryKey, value: categoryValue }])
        setDrillLevel(drillLevel + 1)
      }
    }
  }

  const onClickBack = () => {
    if (drillLevel > 0) {
      setDrillLevel(drillLevel - 1)
      setFilterKeys(filterKeys.slice(0, -1))
    }
  }

  const onChartEvents = {
    click: onChartClick
  }

  const chartStyle = { height: isSmall ? "300px" : "500px" }

  return (
    <>
      {results.length > 0 ? (
        <>
          <Box>
            <Box
              display="flex"
              flexDirection={isSmall ? "column" : "row"}
              alignItems={isSmall ? "flex-start" : "center"}
            >
              <Button
                onClick={onClickBack}
                disabled={drillLevel === 0}
                sx={{
                  order: isSmall ? 2 : 1,
                  marginTop: isSmall ? 2 : 0
                }}
              >
              Back
              </Button>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                ml={isSmall ? 0 : "auto"}
                sx={{ order: isSmall ? 1 : 2}}
              >
                <Typography variant="note">
                  <b>Precise:</b> {precise}
                </Typography>
                <Typography variant="note">
                  <b>Basic:</b> {basic}
                </Typography>
                <Typography variant="note">
                  <b>No Coverage:</b> {no_coverage}
                </Typography>
                <Typography variant="note">
                  <b>Total:</b> {precise + basic + no_coverage}
                </Typography>
              </Stack>
            </Box>
          </Box>
          <ReactEChartsCore
            echarts={echarts}
            option={createOptions({
              drillCategory: drillCategories[drillLevel],
              filterKeys,
              data: results
            })}
            notMerge={false}
            lazyUpdate={false}
            style={chartStyle}
            className="eChart"
            onEvents={onChartEvents}
          //opts={} // opts of the init https://echarts.apache.org/api.html#echarts.init almost never needed    
          />
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={chartStyle}
        >
          <Typography variant="h4">
            This rooftop doesn&apos;t have any vehicles saved yet. <a href={`/revin/add?r=${rooftop_id}`}>Add a VIN</a> to get started.
          </Typography>
        </Box>
      )}
    </>
  )
}

export default CoverageChart

