import { createSlice } from "@reduxjs/toolkit"
import { apiSlice } from "../services/apiSlice"
import { logout } from "./authSlice"
import { joinIfExists } from "../utils"

const joinUserNames = userObject => joinIfExists([userObject?.first_name, userObject.last_name], " ")

export const uuidsSlice = createSlice({
  name: "user",
  initialState: {},
  reducers: {
    set: (state, action) => {
      state = {...state, ...action.payload}
    },
    clear: state => {
      state = {}
    }
  },
  extraReducers: builder => {
    builder
      .addCase(logout, () => {})
      .addMatcher(
        apiSlice.endpoints.getFullUser.matchFulfilled,
        (state, { payload }) => {
          return {
            ...state,
            ...(payload?.user_id ? {[payload.user_id]: joinUserNames(payload)} : {})
          }
        }
      )
      .addMatcher(
        apiSlice.endpoints.getUserProfiles.matchFulfilled,
        (state, { payload }) => {
          const idNamesArray = payload?.results.map(r => ([r.user_id, joinUserNames(r)]))
          const keyedNames = Object.fromEntries(idNamesArray)
          return {
            ...state,
            ...keyedNames
          }
        }
      )
      .addMatcher(
        apiSlice.endpoints.getOrganizationProfiles.matchFulfilled,
        (state, { payload }) => {
          const idNamesArray = payload?.results?.map(p => ([p.organization_id, p.name])) ?? []
          const keyedNames = Object.fromEntries(idNamesArray)
          return {
            ...state,
            ...keyedNames
          }
        }
      )
      .addMatcher(
        apiSlice.endpoints.getFullOrganizations.matchFulfilled,
        (state, { payload = {} }) => {
          const idNamePairs = Object.keys(payload).map(k => ([k, payload[k]?.name]))
          const keyedNames = Object.fromEntries(idNamePairs)
          return {
            ...state,
            ...keyedNames
          }
        }
      )
  }
})

export const { set, clear } = uuidsSlice.actions

export default uuidsSlice.reducer