import React, { useState } from "react"
import SortableList from "../../components/SortableList"
import MemberDescription from "./MemberDescription"
import { useSetOrganizationMemberMutation, useDeleteMembershipMutation, useUpdateOrganizationMemberMutation } from "../../services/apiSlice"
import Member from "./Member"
import EditMembership from "./EditMembership"
import SpinnerContained from "../../components/SpinnerContained"

function Members (props) {
  const {
    members=[],
    organization_id,
    organizationRoles,
    hasAdminPriviledges
  } = props

  const [open, setOpen] = useState()
  const [hideErrors, setHideErrors] = useState()
  const [currentMember, setCurrentMember] = useState()
  const handleClose = () => {
    setOpen(false)
    setHideErrors(true)
  }
  const handleOpen = member => {
    setCurrentMember(member)
    setOpen(true)
  }

  const [createMember, {
    isFetching: isCreateMemberLoading,
    error: createMemberError,
    isError: isCreateMemberError
  }] = useSetOrganizationMemberMutation()

  const [editMember, {
    isFetching: isEditMemberLoading,
    error: editMemberError,
    isError: isEditMemberError
  }] = useUpdateOrganizationMemberMutation()

  const [deleteMember, {
    isFetching: isDeleteMemberLoading,
    error: deleteMemberError,
    isError: isDeleteMemberError
  }] = useDeleteMembershipMutation()

  const pageIsLoading =
    isCreateMemberLoading
    || isEditMemberLoading
    || isDeleteMemberLoading

  const pageHasErrors =
    !hideErrors && (
      isCreateMemberError
      || isEditMemberError
      || isDeleteMemberError
    )

  const pageErrors = [
    createMemberError,
    editMemberError,
    deleteMemberError,
  ].filter(e => e)

  const onSave = async (email, roleId, title, onSuccess) => {
    let result
    try {
      if(currentMember)
        result = await editMember({ email, id: currentMember.membership_id, role: roleId, title: title, active: true }).unwrap()
      else 
        result = await createMember({email, organization_id, role: roleId, title}).unwrap()

      if(result) {
        if (onSuccess) onSuccess()
        handleClose()
      }
    } catch(error) {
      setHideErrors(false)
      console.log(error)
    }
  }

  const onRemove = (membership_id) => {
    deleteMember({ id: membership_id })
  }

  const memberList = members.map(m => ({
    ...m,
    onEdit: !m.locked && hasAdminPriviledges && m.accepted !== false ? () => handleOpen(m) : null,
    onRemove: !m.locked && hasAdminPriviledges ? () => onRemove(m.membership_id) : null
  }))

  const sortOnOrgs = [
    {
      text: "Name",
      field: "name"
    },
    {
      text: "Date",
      field: "created_on"
    },
    {
      text: "Role",
      field: "role"
    },
    {
      text: "Accepted",
      field: "accepted"
    }
  ]

  const inviteMember = () => {
    handleOpen()
  }

  const getPermissionsForApplication = (role, app) => {
    if (!organizationRoles) return "-"

    const permission = role?.permissions?.find(p => p.application === app)

    return permission?.view && permission?.edit && permission?.billing ? "Edit*" :
      permission?.view && permission?.edit ? "Edit" : 
        permission?.view && permission?.billing ? "View*" :
          permission?.view ? "View" : "-"
  }

  return (
    <>
      <SortableList
        sortOn={sortOnOrgs}
        actionText={hasAdminPriviledges ? "+ Invite New Member" : ""}
        action={hasAdminPriviledges ? inviteMember : null}
        list={memberList}
        component={Member}
        descriptionComponent={MemberDescription}
      />
      {pageIsLoading && <SpinnerContained />}
      <EditMembership
        member={currentMember}
        getPermissionsForApplication={getPermissionsForApplication}
        onSave={onSave}
        handleClose={handleClose}
        open={open}
        organizationRoles={organizationRoles}
        pageHasErrors={pageHasErrors}
        pageErrors={pageErrors}
      />
    </>
  )
}

export default Members
