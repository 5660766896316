import React from "react"
import {
  Avatar,
  Badge,
  Box,
  Grid,
  Stack,
  Typography
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment"
import theme from "../theme";

const ClickableAvatar = props => {
  const {
    image,
    mainOnClick = null,
    fallbackIcon = null
  } = props

  return ( 
    <Avatar
      sx={{
        width: "48px",
        height: "48px",
        marginRight: "16px",
        bgcolor: theme.palette.tertiary.main,
        borderColor: theme.palette.tertiary.main,
        borderStyle: "solid",
        borderWidth: "1px",
        ...(mainOnClick ? {cursor: "pointer"} : {})
      }}
      variant="rounded"
      src={image}
      onClick={mainOnClick}
      {...(mainOnClick ? {tabIndex: 0} : {})}
    >
      {fallbackIcon}
    </Avatar>
  )
}

const ClickableText = props => {
  const {
    title,
    TitleBadge = null,
    description,
    descriptionComponent: DescriptionComponent=null,
    notifications
  } = props

  return (
    <>
      <Box display="flex" mb={0.5}>
        <Badge
          badgeContent={notifications} 
          color="primary" 
          invisible={notifications === 0}
          sx={{ maxWidth: "100%" }}
        >
          <Typography variant="h5" mr={2}>
            {title}
          </Typography>
        </Badge>
        {TitleBadge && (<TitleBadge />)}
      </Box>
      {DescriptionComponent ? (
        <Grid item>
          <DescriptionComponent title={description[0] ?? ""} role={description[1] ?? ""} />
        </Grid>
      ) : description ? (
        <Typography>
          {description}
        </Typography>
      ) : null 
      }
    </>
  )
}

function SortableListItem(props) {
  const {
    created_on,
    updated_on,
    detailButtons = [],
    actionButtons = [],
    actionState,
    actionStateColor,
    mainOnClick = null,
    url,
    informationMiddle,
    informationRight,
  } = props

  const timestamp = updated_on ?? created_on

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      alignItems="flex-start"
      pt={2}
      pb={2}
      style={{ borderTop: `1px solid ${props.index === 0 ? theme.palette.tertiary.main : theme.palette.background.light}` }}
    >
      <Grid item xs="auto">
        {url ? (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <ClickableAvatar {...props} />
          </a>
        ) : (
          <ClickableAvatar {...props} />
        )}
      </Grid>
      <Grid
        item
        xs
        onClick={mainOnClick}
        {...(mainOnClick ? {tabIndex: 0} : {})}
        sx={{...(mainOnClick ? {cursor: "pointer"} : {})}}
      >
        {url ? (
          <a href={url} target="_blank" rel="noopener noreferrer" className="noUnderline">
            <ClickableText {...props} />
          </a>
        ) : (
          <ClickableText {...props} />
        )}
      </Grid>
      {informationMiddle || informationRight ? (
        <Grid item xs md={4}>
          <Stack direction="row" spacing={7}>
            {informationMiddle && (
              <Typography textAlign="right">
                {informationMiddle}
              </Typography>
            )}
            {informationRight && (
              <Typography textAlign="right">
                {informationRight}
              </Typography>
            )}
          </Stack>
        </Grid>
      ) : <></>}
      <Grid item xs={12} md={3} pt={isSmall ? 2 : 0}>
        {detailButtons.length > 0 && (
          <Box display="flex" mb={1} justifyContent="flex-end">
            <Stack spacing={1} direction="row">
              {detailButtons.map(d => (d))}
            </Stack>
          </Box>
        )}
        {timestamp && (
          <Typography textAlign="right">
            {moment(timestamp).format("LT L")}
          </Typography>
        )}
        {actionState && (
          <Typography color={actionStateColor ?? theme.palette.text} textAlign="right">
            {actionState}
          </Typography>
        )}
        {actionButtons.length > 0 && (
          <Box display="flex" justifyContent="flex-end" mt={1}>
            <Stack spacing={1} direction="row">
              {actionButtons.map(a => (a))}
            </Stack>
          </Box>
        )}
      </Grid>
    </Grid>
  )
}

export default SortableListItem