import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react"

function TitledList(props) {
  const {
    tableTitle = "",
    actionText = "",
    action = null,
    list = [],
    component: Component = null,
    emptyText = "",
    emptySubText = "",
  } = props

  return(
    <>
      <Box display="flex" alignItems="center" sx={{borderBottom: "1px solid rgb(168, 183, 208)", marginBottom: "20px" }}>
        <Typography variant="h3" component="h1">{tableTitle}</Typography>
        {actionText && action && (
          <Box ml="auto">
            <Button
              sx={{textTransform: "none"}}
              onClick={action}
            >
              {actionText}
            </Button>
          </Box>
        )}
      </Box>
      {list && Component && (
        <Stack>
          {list.map((l, i) => <Component key={l.key} index={i} {...l} />)}
        </Stack>
      )}
      {list.length === 0 && (
        <Box
          spacing={0}
          direction="row"
          alignItems="flex-start"
          pb={2}
        >
          <Typography variant="h5">
            {emptyText}
          </Typography>
          <Typography>
            {emptySubText}
          </Typography>
        </Box>
      )}
    </>
  )
}

export default TitledList;