import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";

function SortableTableHead(props) {
  const {
    direction,
    sortBy,
    onRequestSort,
    headCells
  } = props

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) =>(
          <TableCell
            key={headCell.id}
            sortDirection={sortBy === headCell.id ? direction : false}>
            {headCell.sortable ? (
              <TableSortLabel
                active={sortBy === headCell.id}
                direction={sortBy === headCell.id ? direction : "asc"}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {sortBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {direction === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

SortableTableHead.propTypes = {
  direction: PropTypes.oneOf(["asc", "desc"]).isRequired,
  sortBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  headCells: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default SortableTableHead