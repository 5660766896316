import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { 
  Alert,
  Snackbar,
  Typography, 
} from "@mui/material"
import {
  useGetRooftopsQuery,
  useGetOrganizationSubscriptionsQuery,
  useGetFullOrganizationsQuery
} from "../../services/apiSlice";
import StandardPage from "../../components/StandardPage"
import SpinnerContained from "../../components/SpinnerContained";
import ErrorsContained from "../../components/ErrorContained";
import ReVINInner from "./IndexInner";
import SingleVin from "./Vin";
import { getQueryState, joinIfExists } from "../../utils"

const indexedTabs = ["dashboard", "all", "add", "coverage"]

const warningText = w => {
  const statusFragment1 = w.status !== "active" ? `has a status of ${w.status}` : ""
  const statusFragment2 = w.status !== "active" ? "it's status returns to active" : ""
  const payFragment1 = !w.has_payment_method ? "is missing a payment method" : ""
  const payFragment2 = !w.has_payment_method ? "it has a payment method" : ""
  const warning = joinIfExists([statusFragment1, payFragment1], " and it ")
  const condition = joinIfExists([statusFragment2, payFragment2], " and ")
  return `The subscription for ${w.name}, owned by ${w.orgName}, ${warning}. New VINS can't be added to it until ${condition}.`
}

function ReVIN() {
  const { tabOrVIN = "dashboard" } = useParams()
  const tabFromPath = indexedTabs.indexOf(tabOrVIN)
  const [hideWarning, setHideWarning] = useState()

  const getRooftops = useGetRooftopsQuery()
  const { data: rooftopsData = [] } = getRooftops
  const rooftopOrgIDs = rooftopsData.map(r => r?.owner?.organization_id)

  const getOrgs = useGetFullOrganizationsQuery({ org_ids: rooftopOrgIDs })
  const { data: orgData = {} } = getOrgs

  const getSubscriptions = useGetOrganizationSubscriptionsQuery()
  const { data: subscriptionsData = [] } = getSubscriptions

  const rooftopsList = rooftopsData.map(r => {
    const matchedSubscription = subscriptionsData.find(s => s.organization === r.organization_id) ?? {}
    return {
      ...r,
      ...matchedSubscription,
      orgName: orgData[r.organization_id]?.name ?? "Not found"
    }
  })

  const default_rooftop_id = rooftopsList.filter(r => r.status === "active")[0]?.rooftop_id

  const onWarningClose = () => {
    setHideWarning(true)
  }

  const { isFetching, isError, errors } = getQueryState([
    getRooftops,
    getSubscriptions,
    getOrgs
  ])

  const rooftopsWarnings = !isFetching ? rooftopsList
    .filter(r => r.status !== "active" || !r.has_payment_method)
    : []

  const hasRooftops = !!default_rooftop_id

  return (
    <StandardPage title="reVIN">
      {isFetching ? (
        <SpinnerContained />
      ) : isError ? (
        <ErrorsContained errors={errors} />
      ) : tabFromPath > -1 && hasRooftops ? (
        <ReVINInner
          currentTabIndex={tabFromPath}
          indexedTabs={indexedTabs}
          default_rooftop_id={default_rooftop_id}
          rooftopsList={rooftopsList}
        />
      ) : hasRooftops ? (
        <SingleVin vehicle_vin={tabOrVIN} />
      ) : (
        <ErrorsContained errors={"You don't have any active rooftops."} />
      )}
      {rooftopsWarnings.length > 0 && (
        <Snackbar
          open={!hideWarning}
          autoHideDuration={5000}
          anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        >
          <Alert
            severity="warning"
            elevation={1}
            onClose={onWarningClose}
          >
            {rooftopsWarnings.map((w, i) => (
              <Typography key={`${w.name}-${w.orgName}-${w.status}`} mb={i + 1 < rooftopsWarnings.length ? 2 : 0}>
                {warningText(w)}
              </Typography>
            ))}
          </Alert>

        </Snackbar>
      )}
    </StandardPage>
  );
}

export default ReVIN