import React, { useState } from "react"
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { Alert, Button } from "@mui/material";

const SetupForm = props => {
  const {
    onSubmit
  } = props
  
  const stripe = useStripe()
  const elements = useElements()

  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      console.log("return")
      return;
    }

    setIsLoading(true)

    console.log(`${window.location.protocol}//${window.location.host}/subscriptions`)

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/subscriptions`,
      },
    });

    if (error) {
      console.log(error.message)
      setMessage(error.message)
    }

    setIsLoading(false)
    onSubmit()
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement id="payment-element"/>
      <Button 
        disabled={isLoading || !stripe || !elements}
        sx={{ width: "100%", marginTop: "24px"}} 
        variant="contained"
        type="submit"
      >
        Submit
      </Button>
      {message && (
        <Alert severity='error'>
          {message}
        </Alert>
      )}
    </form>
  );
}

export default SetupForm