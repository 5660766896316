import React from "react";
import {
  Breadcrumbs,
  Link,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux"


const NavCrumbs = () => {
  const currentOrigin = window.location.origin
  const currentPath = window.location.pathname
  let pathParts = currentPath
    .split("/")
  // Remove the first blank path before the / - JJ
  pathParts.shift()

  const uuids = useSelector(state => state.uuids)
  const token = useSelector(state => state.auth.token)

  const crumbs = []
  for (let i = pathParts.length - 1; i >= 0; i--) {
    const path = pathParts.join("/")
    const lastPart = pathParts.pop()
    const text = uuids[lastPart] ?? lastPart.replace(/[-_]/g, " ")
    crumbs.push({
      path: `${currentOrigin}/${path}`,
      text
    })
  }
  crumbs.reverse()

  return (
    <>
      {token && (
        <Breadcrumbs
          aria-label="breadcrumb"
          maxItems={3}
          sx={{ fontSize: "12px"}}
        >
          <Link
            key="home"
            color="secondary"
            variant="linkSmallLight"
            href={`${currentOrigin}/`}
            underline="hover"
          >
            home
          </Link>
          {crumbs.map((c,i) => {
            if(i === crumbs.length - 1) {
              return (
                <Typography
                  key={c.path}
                  color="secondary"
                  variant="linkSmallLight"
                >
                  {c.text}
                </Typography>
              )
            }
            return (
              <Link
                key={c.path}
                color="secondary"
                variant="linkSmallLight"
                href={c.path}
                underline="hover"
              >
                {c.text}
              </Link>
            )
          })}
        </Breadcrumbs>
      )}
    </>
  )
}

export default NavCrumbs