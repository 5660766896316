import moment from "moment";

/**
 * 
 * @param {object} error a single key with an array of messages. 
 */
const parseFieldError = errorToParse => {
  const { data, status, error } = errorToParse
  let key = "Error"
  let messages = ""
  if (data) {
    key = Object.keys(data)[0]
    messages = Array.isArray(data[key]) ? data[key].join(", ") : data[key]
  }
  if (error) {
    messages = `${messages ? `${messages} ` : ""}${error}`
  }
  return `${key}: Status: ${status} - ${messages}`
}

/** Takes an error returned from a REST API and converts it into a readable error message.
 *  Always returns an array. We do this so we don't have to detect if the message is a string or array
 *  in the render code. - JJ
*/
export const parseErrors = (error) => {
  if (!error) return ["No Error Provided"]
  if (typeof error === "string") return [error]
  if (typeof error !== "object") return ["Unknown Error - No Error Object"]
  const {response, data } = error
  let { message } = error
  const innerError = data?.error
  const messageArray = Array.isArray(error) && error.map(e => parseFieldError(e)).join(", ")
  message = message ?? data?.message ?? data?.detail ?? messageArray
  const status = `${error?.status ? `${error.status}: ` : ""}`
  if (response) {
    if (response?.data?.detail) return [response.data.detail]
    if (Array.isArray(response?.data?.errors)) return response.data.errors
    return [`${response.status}: ${response.statusText}`]
  } else if (innerError) {
    return [`${error.originalStatus} ${status}${innerError}`]
  } else if (message) {
    return [`${status}${message}`]
  } else if (Array.isArray(error)) {
    return error.map(e => parseErrors(e)).join(", ")
  }
  return [`${status}${JSON.stringify(error)}`]
}

/**
 *
 * @param {Object} obj - an object with key value pairs
 * @returns {boolean}
 */
export const isObjectWithValues = obj => {
  return obj && typeof obj === "object" && !!Object.values(obj).filter(v => v).length
}

/**
 * @param {String} str - The string to capitalize the first letter of
 * @returns {String}
 */
export const capitalizeFirstLetter = (str) => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

/**
 * @param {String} str - The string to capitalize the first letters of
 * @returns {String}
 */
export const capitalizeFirstLetters = (str) => {
  return str
    .split(" ")
    .map(w => capitalizeFirstLetter(w))
    .join(" ")
}

/**
 * Makes data strings nicer looking.
 * @param {*} opts 
 * @params {String} opts.str - The string to prettify.
 * @params {String} opts.splitChar - The character to split the string on.
 * @params {String} opts.joinChar - The character to re-join the string with.
 * @params {Boolean} opts.capitalize - Whether to Capitalize each fragment or not.
 * @returns {String}
 */
export const prettifyDataString = opts => {
  const {
    str = "",
    splitChar = "_",
    joinChar = " ",
    capitalize = true
  } = opts

  if (!str) return ""
  let words = str.split(splitChar)
  if (capitalize) words = words.map(w => capitalizeFirstLetter(w.toLowerCase()))
  return words.join(joinChar)
}

/**
 * Checks a URL for a protocol, and adds one if it's missing.
 * @param {String} url - The URL to check.
 * @returns {String}
 */
export const addURLProtocol = url => {
  let testURL
  if (!url) return ""

  try {
    testURL = new URL(url)
  } catch(error) {
    return `https://${url}`
  }
  return testURL
}

/**
 * 
 * @param {Array} list - The array to measure.
 * @param {String} text - The text to use if it's single
 * @param {String} pluralText - The text to use if it's plural, if not provided an s is added
 * @returns {String}
 */
export const countString = (list = [], text = "", pluralText) => {
  if (!Array.isArray(list)) return text
  const parsedPlural = pluralText ? pluralText : `${text}s`
  const parsedText = list.length === 1 ? text : parsedPlural
  return `${list.length} ${parsedText}`
}

const formatKeyValue = (key, val) => (val ? `${key}=${encodeURIComponent(val)}` : "");

/**
 *
 * @param {Object} obj - A parameter object to change into a URL query string. Can contain arrays, such as "foo: [1, 2, 3]"
 * @returns {string} - Formatted as a query string.
 */
export const toQueryString = obj => {
  if (!isObjectWithValues(obj)) return ""
  return []
    .concat(
      ...Object.entries(obj)
        .map(([key, val]) => (Array.isArray(val) ? val.map(arr => toQueryString({[key]: arr})) : formatKeyValue(key, val)))
    )
    .filter(x => x)
    .join("&")
}

/**
 *
 * @param {Array} objectArray - An array of objects.
 * @param {string} key - The object key to use, defaults to "id."
 * @returns {Object.<string, object>} - An object keyed by the id.
 */
export function toKeyedByID(objectArray, key="id") {
  if (!objectArray.length) return {}
  return Object.fromEntries(objectArray.map(obj => [obj[key], {...obj}]));
}

/**
 * When given a keyed array and an array of objects with matching keys, merges the array into the object.
 * @param {Array} parentObject - A keyed object
 * @param {Array} objectArray - An array of objects
 * @param {string} key - the key to match with
 * @param {boolean} addIfMissing - if the parentObject has no matching key, should one be created?
 * @returns {Object.<string, object>} - An object keyed by the passed in key.
 */
export function mergeIntoKeyedById(
  parentObject,
  objectArray,
  key="id",
  addIfMissing="true"
) {
  objectArray.forEach(o => {
    const matchOn = o[key]
    if (parentObject[matchOn] || addIfMissing) {
      parentObject[matchOn] = {
        ...o,
        ...(parentObject[matchOn] ?? {})
      }
    }
  })
  return parentObject
}

/**
 * When given two arrays of objects with the same key, merges them by key and returnes a keyed object
 * @param {Array} objectArray1 - An array of objects
 * @param {Array} objectArray2 - An array of objects
 * @param {string} key - the key to match with
 * @returns {Object.<string, object>} - An object keyed by the passed in key.
 */
export function mergeToKeyedById(objectArray1, objectArray2, key) {
  const parentObject = toKeyedByID(objectArray1, key)
  return mergeIntoKeyedById(parentObject, objectArray2, key)
}

/** Removes undefined and false values from an array, and returns the rest as a joined string.
 *
 * @param {Array} stringArray - An array of strings, some of which might be falsey.
 * @param {string} joinString - The string to use when joining the strings.
 * @param {boolean} lastJoinAnd - Should the last two be joined with ", and"?
 * @returns {string} - A joined string.
 */
export function joinIfExists(stringArray, joinString=", ", lastJoinAnd) {
  if (!Array.isArray(stringArray)) return ""
  const toJoin = stringArray.filter(s => s)
  if (!lastJoinAnd) return toJoin.join(joinString)
  const lastString = toJoin.pop()
  const withoutLastString = toJoin.join(joinString)
  return [withoutLastString, lastString].join(`${joinString}and `)
}

/** Groups an array of objects by key, returns object with arrays of the original objects grouped.
 *
 * @param {Array} objectArray - An array of objects.
 * @param {string} key - the key to group by.
 * @returns {Object.<string, object>} - An object keyed by the passed in key.
 */
export function groupToKey(objectArray, key) {
  if (!Array.isArray(objectArray)) return {}
  const grouped = objectArray.reduce(function(o, a){
    o[a[key]] = o[a[key]] || []
    o[a[key]].push(a)
    return o
  }, {})
  return grouped
}

/** Sorts object alphabetically by keys
 *
 * @param {Object} obj - An object.
 * @returns {Object.<string, object>} - An object sorted alphabetically by key.
 */
export function sortObjAlpha(obj) {
  let alpha = Object.keys(obj).sort().reduce((v, key) => {
    v[key] = obj[key]
    return v
  }, {})
  return alpha
}

/** USD number format
 */
export function usdFormat(val){
  const usd = new Intl.NumberFormat("en-US", {style: "currency", currency: "USD"})
  return usd.format(val)
}

/** Format Date for display
 *
 * @param {string} date - A date.
 * @param {string} format - Date output template, optional
 * @returns {string} - The formatted date for display. if date is invalid, original date returned
 */
export function outputDateFormat(date, formatter = "MM/DD/YYYY, h:mm A"){
  const md = moment(date)
  if (!md.isValid()) return date
  const formatted = md.format(formatter)
  return formatted
}

/** Extract state from a list of RTK Query hook results
 * @param {Array} results - An array of query results
 * @returns {Object} - An object with isFetching, isSuccess, isError, and error
 */
export function getQueryState(results) {
  // If any are fetching, return true
  const isFetching = results.filter(r => r.isFetching).length > 0
  // Only return true if all are successful
  const isSuccess = results.filter(r => !r.isSuccess).length > 0
  // Only return errors where isError is also true so we skip cached errors
  const errors = results.filter(r => r.isError).map(r => r.error)
  const isError = errors.length > 0

  return {
    isFetching,
    isSuccess,
    isError,
    errors
  }
}